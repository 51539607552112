import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { LayoutModule } from './views/layout/layout.module';
import { AuthGuard } from './core/guard/auth.guard';
import { AppComponent } from './app.component';
import { ErrorPageComponent } from './views/error-page/error-page.component';
import { HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { ContactService } from 'src/services/contact.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxMaskModule } from 'ngx-mask';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShowContactComponent } from '../app/views/CRM/contact/show-contact/show-contact.component';
import { AddEditContactComponent } from '../app/views/CRM/contact/add-edit-contact/add-edit-contact.component';
import { NgbDatepickerModule, NgbDropdownModule, NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { ProfileComponent } from './views/CRM/profile/profile.component';
import { LoginComponent } from './views/auth/login/login.component';
import lottie from 'lottie-web';
import { defineElement } from 'lord-icon-element';
import { DealsComponent } from './views/CRM/sales/deals/deals.component';
import { SuperAdminComponent } from './views/admin/super-admin/super-admin.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CompanyAdminComponent } from './views/admin/company-admin/company-admin.component';
import { AuthInterceptor } from './views/auth/auth.interceptor';
import { ContactComponent } from './views/CRM/contact/contact.component';
import { ContactModule } from './views/CRM/contact/contact.module';
import { RouterModule, Routes } from '@angular/router';
import { FullCalendarModule } from '@fullcalendar/angular';
import interactionPlugin from '@fullcalendar/interaction';
import daygridPlugin from '@fullcalendar/daygrid';
import { ColorPickerModule } from 'ngx-color-picker';
import { GeneralService } from 'src/services/general.service';
import { EditProfileComponent } from './views/edit-profile/edit-profile.component';

import { AuthGuardService } from 'src/services/auth-guard.service';
import { CompanySettingComponent } from './views/MOVD/admin/company-setting/company-setting';
import { ForgotPasswordComponent } from './views/auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './views/auth/reset-password/reset-password.component';
import { RolePermissionsComponent } from './views/MOVD/admin/role-permissions/role-permissions.component';
import { TaskComponent } from './views/CRM/profile/task/task.component';
import { TaskBoardComponent } from './views/CRM/profile/task-board/task-board.component';
import { FeatherIconModule } from './core/feather-icon/feather-icon.module';
import { NgApexchartsModule } from 'ng-apexcharts';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { FormatUrlPipe } from './pipes/formatUrl';
FullCalendarModule.registerPlugins([interactionPlugin, daygridPlugin]);
const routes: Routes = [
  {
    path: '',
    component: DashboardComponent
  }
]
@NgModule({
  declarations: [
    AppComponent,
    ErrorPageComponent,
    ContactComponent,
    ShowContactComponent,
    AddEditContactComponent,
    ProfileComponent,
    DealsComponent,
    LoginComponent,
    SuperAdminComponent,
    CompanyAdminComponent,
    CompanySettingComponent,
    EditProfileComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    RolePermissionsComponent,
    TaskComponent,
    TaskBoardComponent,
    DashboardComponent,
    FormatUrlPipe
  ],
  exports: [AddEditContactComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    BrowserAnimationsModule,
    LayoutModule,
    ContactModule,
    CommonModule,
    NgSelectModule,
    NgxMaskModule.forRoot(),
    NgxDatatableModule,
    RouterModule.forChild(routes),
    NgbNavModule,
    FullCalendarModule,
    ColorPickerModule,
    FeatherIconModule,
    NgbDropdownModule,
    NgbDatepickerModule,
    NgApexchartsModule
  ],
  providers: [
    ContactService,
    AuthGuardService,
    GeneralService,
    {
      provide: HIGHLIGHT_OPTIONS, // https://www.npmjs.com/package/ngx-highlightjs
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          xml: () => import('highlight.js/lib/languages/xml'),
          typescript: () => import('highlight.js/lib/languages/typescript'),
          scss: () => import('highlight.js/lib/languages/scss'),
        },
      },
    },
    [
      {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptor,
        multi: true,
      },
    ],
    DecimalPipe,
    DatePipe,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
  constructor() {
    defineElement(lottie.loadAnimation);
  }
}
