export class RolePermission {
  roleId: number;
  permissionId: number;
  isSelected: boolean;

  constructor() {
    this.roleId = 0;
    this.permissionId = 0;
    this.isSelected = false;
  }
}
