export class UserModel {
  id: number;
  username: string | null;
  email: string;
  password: string;
  roleId: number;
  customerId: number | null;
  phone: string | null;
  companyName: string | null;
  industryId: number | null;
  companyId: number | null;
  city: string | null;
  country: string | null;
  addressId: number | null;
  profileImage: string | null;
  streetAddress: string | null;

  constructor() {
    this.id = 0;
    this.username = null;
    this.email = '';
    this.password = '';
    this.roleId = 0;
    this.customerId = null;
    this.phone = null;
    this.companyName = null;
    this.industryId = null;
    this.companyId = null;
    this.city = null;
    this.country = null;
    this.addressId = null;
    this.profileImage = null;
    this.streetAddress = null;
  }
}
// export class UserModel {
//   id: number;
//   username: string;
//   email: string;
//   password: string;
//   roleId: number;
//   companyName: string;
//   constructor() {
//     this.id = 0;
//     this.username = '';
//     this.email = '';
//     this.password = '';
//     this.roleId = 0;
//     this.companyName = '';
//   }
// }
export class UserDTO {
  id: number;
  username: string;
  email: string;
  roleId: number;
  role: string;
  companyId: string;
  customerId: Number;

  constructor() {
    this.id = 0;
    this.username = '';
    this.email = '';
    this.roleId = 0;
    this.role = '';
    this.companyId = '';
    this.customerId = 0;
  }
}
