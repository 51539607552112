import { Component, OnInit, ViewChild, ElementRef, Inject, Renderer2, Input, TemplateRef, EventEmitter, Output } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { ContactService } from "src/services/contact.service";
import { GeneralService } from '../../../../services/general.service';
import { DealDetailsDTO, TasksModel } from '../../CRM/profile/profile.models';
import { AuthService } from 'src/services/auth.service';
import { NgbCalendar, NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
// import { NotificationsService } from '../../../../services/notifications.service';
// import { NotificationModel } from '../../../../models/notification.model';
import { log } from 'console';
import { NotificationsService } from 'src/services/notifications.service';
import { NotificationModel } from '../../MOVD/models/notification.model';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  @Input() customerId: any;
  // @Input() isDeal: any;
  showAllTasks = false;
  showAlldoneTasks: boolean = false;
  selectedDate!: string;
  selectedTime!: string;
  _companyUsers: any[] = [];
  _taskUpdate: boolean = false;
  @Output() taskAdded: EventEmitter<TasksModel> = new EventEmitter<TasksModel>();
  _saveTaskTitle: string = 'Create';
  defaultNavActiveId: number = 1;
  @Input() tasks: TasksModel[] = [];
  // profilePictureURL: string | null = null;
  isTaskListOpen: boolean = false;
  @Input() isDeal: any = false;
  _task = new TasksModel();
  _tasksList: TasksModel[] = [];
  _dealDetailDTO = new DealDetailsDTO();
  _companyId: number = 0;
  _currentUserId: number = 0;
  companyId: number = 0;
  fromDate: NgbDate | null = null;
  toDate: NgbDate | null = null;
  _imageToDisplay: string | null = null;
  _selectedUserId: number | null = 0;
  _selectedCustomerId: number | null = 0;
  todoTasks: TasksModel[] = [];
  doneTasks: TasksModel[] = [];

  getNameFromLocalStorage(): string | null {
    if (localStorage.getItem('role') == 'SuperAdmin') {
      return "Techflo";
    }
    return window.localStorage.getItem('name');
  }

  getEmailFromLocalStorage(): string | null {
    return window.localStorage.getItem('email');
  }

  _overviewList: any[] = [];
  _customerId: number = 0;
  // _imageToDisplay!: string | null;

  notifications: NotificationModel[] = [];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private _router: Router,
    private _appComponent: AppComponent,
    private _contactService: ContactService,
    private _sharedService: ContactService,
    private _authService: AuthService,
    private _modalService: NgbModal,
    private calendar: NgbCalendar,
    private generalService: GeneralService,
    private notificationsService: NotificationsService,
  ) {
    this._customerId = Number(localStorage.getItem('customerId'));
    this._currentUserId = Number(localStorage.getItem('currentUserId'));
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
  }

  ngOnInit(): void {
    this._imageToDisplay = this.generalService.getProfilePicture();
    this.fetchData('Task');
    this.getCompanyUsers();
    this.onTaskListClick();
    this.showAlldoneTasks = false;
    setTimeout(() => {
      this.getUserById();
    }, 0);
    this.generalService.profilePictureUpdated.subscribe((updatedImage: string | null) => {
      // Update the profile picture URL when the event is received
      this._imageToDisplay = updatedImage;
    });

    this._sharedService.overviewList$.subscribe((res: any) => {
      this._overviewList = res;
      //console.log("nav bar overviewList",this._overviewList);
    });
    this.loadNotifications();
  }

  async fetchData(type: string): Promise<void> {

    try {
      // console.log('fetching tasks');
      let data: any;
      switch (type) {
        case 'Task':
        case 'TaskModel':
          // console.log(`current cust id ${this._customerId} && isDeal${this.isDeal}`);

          data = await this._contactService.getTasks(this._currentUserId, this.isDeal).toPromise();
          // console.log("tasks list", data);

          this._tasksList = data;

          this.todoTasks = this._tasksList.filter(task => !task.done).slice(0, 3);
          this.doneTasks = this._tasksList.filter(task => task.done).slice(0, 3);

          break;
      }
      if (data && data.length > 0) {
        // console.log("tasks list", data);
        this._tasksList = data.filter((task: { assignedTo: any; }) => task.assignedTo === this._currentUserId);

        const listName = `_${type.toLowerCase()}sList`; // Derive the list name dynamically
        (this as any)[listName] = data;

        (this as any)[listName].sort((a: { activityDate: Date }, b: { activityDate: Date }) =>
          new Date(b.activityDate).getTime() - new Date(a.activityDate).getTime()
        );
        const mostRecentData = data.length === 1 ? data[0] : data[data.length - 1];

        if (mostRecentData) {
          mostRecentData.timeAgo = this.calculateActivityTime(mostRecentData.activityDate);
        }

        // Get company users
        let companyUsers = await this._authService.getUsersOfCompany().toPromise();

        // Add assignedToUser to each task
        for (let task of this._tasksList) {
          if (companyUsers && companyUsers.length > 0) {
            const assignedUser = companyUsers.filter(user => user.id === task.assignedto)[0];
            // console.log("single assigned user", assignedUser);

            if (assignedUser) {
              task.assignedToName = assignedUser.customerName;
            } else {
              task.assignedToName = null;
            }
          } else {
            task.assignedToName = null;
          }

        }

      }
      // console.log('fetching tasks lissts', this._tasksList);
    } catch (error) {
      console.error(`Error fetching customer ${type}s:`, error);
    }
  }

  seeAllTasks(tabType: string) {
    this.showAllTasks = true;
    this._router.navigate(['/task-board']);
  }

  onTaskListClick() {
    this.fetchData('Task');
  }

  getCompanyUsers() {

    this._authService.getUsersOfCompany().subscribe(
      (data: any) => {
        // console.log("get contacts response", data);
        if (data && data.length > 0) {
          this._companyUsers = data;
        }
        // console.log("this._contacts", this._contacts);
      },
      error => {
        console.error("error getting contacts", error);
      }
    );
  }


  openTasksModal(content: TemplateRef<any>, id = 0) {
    // console.log("openTasksModal", id);
    this._task = new TasksModel();

    // console.log("task ", this._task);

    this.selectedDate = this.formatDate(new Date());
    this.selectedTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });

    this.openModal(content, "Tasks", id);
  }

  openModal(content: TemplateRef<any>, title: string, id: number) {

    // console.log("openModal", id);

    if (id === 0) {
      title = "Add " + title;
      this._taskUpdate = false;
      this._saveTaskTitle = "Create";
    } else {
      title = "Update " + title;
      this._taskUpdate = true;
      this._saveTaskTitle = "Update";
    }

    this._modalService.open(content, {}).result.then((result) => {
      // Handle the result if needed
    }).catch((res) => { });
  }
  private formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = this.padZero(date.getMonth() + 1);
    const day = this.padZero(date.getDate());
    return `${year}-${month}-${day}`;
  }

  private formatTime(date: Date): string {
    let hours = date.getHours();
    const minutes = this.padZero(date.getMinutes());
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    const formattedTime = `${this.padZero(hours)}:${minutes}`;
    return `${formattedTime} ${ampm}`;
  }
  private padZero(value: number): string {
    return value.toString().padStart(2, '0');
  }
  saveTask() {
    if (this._task.title.trim().length === 0) {
      return;
    }

    this.saveData(this._task, 'Task');
  }

  saveData(entity: any, entityType: string) {
    const isAdding = entity.id === 0;

    entity.leadDetailId = 0;
    if (this._task.assignedto != null) {
      entity.assignedto = this._selectedUserId;
      entity.customerId = this._selectedCustomerId;
    }
    else {
      entity.assignedto = this._currentUserId;
      entity.customerId = null;
    }
    entity.createdBy = this._currentUserId;
    const activityDate = new Date(`${this.selectedDate}T${this.selectedTime}:00.000Z`);
    entity.activityDate = activityDate.toISOString();

    console.log('saving task data', entity);

    let observable: Observable<any> | null = null;;

    if (entityType === 'Task') {
      observable = isAdding ? this._contactService.addTask(entity) :
        this._contactService.updateTask(entity);
    }
    //console.log("observable", observable);
    if (observable) {
      observable.subscribe(
        (response: any) => {
          //console.log("response", response);
          const entityName = entityType.slice(0, -5);

          if (response && response.status !== 200) {
            this._appComponent.showErrorSwal(isAdding ? 'saving' : 'updating', entityName);
            this.taskAdded.emit(entity); // Emit the newly saved task data
          } else {
            this.fetchData(entityType);
            this._modalService.dismissAll('by: calling closeModal()');
            this._appComponent.showSuccessSwal(isAdding ? 'saved' : 'updated', entityName);
          }
          // console.log('task data saviing', this._task.dueDate)
        },
        (error: any) => {
          console.error("error saving data", error);
          // Handle errors as needed
        }
      );
    }
  }

  loadNotifications() {
    this.notificationsService.getNotifications().subscribe((data: NotificationModel[]) => {
      this.notifications = data;
    });
  }

  toggleTaskDone(task: TasksModel) {
    task.done = !task.done;
    this._task = task;

    const id = task.id;
    const status = !task.done;
    if (!this._task.done) {
      this._task.done = false;
    }
    else {
      this._task.done = true;
    }
    this._contactService.updateTaskStatus(id, status).subscribe(
      () => {
        task.done = status;
        // console.log('updating task status to done', task.done)
      },
      (error) => {
        console.error('Error updating task status:', error);
      }
    );
  }


  hasUnreadNotifications(): boolean {
    if (!this.notifications || this.notifications.length === 0) {
      return false;
    }
    // Check if any notification is unread
    return this.notifications.some(notification => !notification.isRead);
  }

  markAsRead(notificationId: number, notificationType: string) {
    // console.log("notification id", notificationId);

    this.notificationsService.updateNotification(notificationId).subscribe({
      next: () => {
        // console.log('NotificationType', notificationType);
        if (notificationType === 'Task') {
          this._router.navigate(['/task-board']);
        }
        this.loadNotifications();
      },
      error: err => {
        console.error('An error occurred while updating the notification:', err);
      }
    });


    // this.notificationsService.updateNotification(notificationId).subscribe(() => {
    //   // Reload notifications after marking as read
    //   console.log('NotificationType', notificationType);
    //   if (notificationType === 'Task') {
    //     this._router.navigate(['/task-board']);
    //   }
    //   this.loadNotifications();
    // });
  }

  todoTab(): boolean {
    return this._tasksList.length === 0 || this._tasksList.every(task => task.done);
  }

  doneTab(): boolean {
    return this._tasksList.length === 0 || this._tasksList.every(task => !task.done);
  }

  isOverdue(task: TasksModel): boolean {
    const currentDateTime = new Date().getTime();
    const taskDueDateTime = new Date(task.dueDate).getTime();

    return currentDateTime > taskDueDateTime;
  }

  calculateActivityTime(activityDate: string): string {
    const currentTime = new Date(); // Get the current time
    const activityTime = new Date(activityDate); // Convert the activityDate to a Date object
    const timeDiff = currentTime.getTime() - activityTime.getTime(); // Calculate the time difference in milliseconds

    // Convert the time difference to minutes ago, hours ago, or days ago
    if (timeDiff < 60000) { // Less than 1 minute
      return 'Just Now';
    }
    else if (timeDiff < 3600000) { // Less than 1 hour
      return Math.floor(timeDiff / 60000) + ' minutes ago';
    }
    else if (timeDiff < 86400000) { // Less than 1 day
      const hours = Math.floor(timeDiff / 3600000);
      return hours === 1 ? '1 hour ago' : hours + ' hours ago';
    }
    else { // More than 1 day
      const days = Math.floor(timeDiff / 86400000);
      return days === 1 ? '1 day ago' : days + ' days ago';
    }
  }


  /**
   * Sidebar toggle on hamburger button click
   */
  toggleSidebar(e: Event) {
    e.preventDefault();
    this.document.body.classList.toggle('sidebar-open');
  }

  /**
   * Logout
   */
  onLogout(e: Event) {
    e.preventDefault();
    //localStorage.removeItem('isLoggedin');

    if (!localStorage.getItem('isLoggedin')) {
      this._router.navigate(['/auth/login']);
    }
  }

  getUserById() {
    this._contactService.getUserProfile().subscribe({
      next: (response: any) => {
        if (response) {
          this._imageToDisplay = response.profileImage;
        }
        // console.log("user profile", response);
      },
      error: error => {
        console.error("get user profile error", error);
      }
    });
  }

  onProfilePictureUpdated(updatedImage: string | null) {
    this._imageToDisplay = updatedImage;
  }
  onTabChange(tab: string) {
    if (tab === 'todo') {
      this.todoTasks = this._tasksList.filter(task => !task.done).slice(0, 3);
    } else if (tab === 'done') {
      this.doneTasks = this._tasksList.filter(task => task.done).slice(0, 3);
    }
    this.fetchData('Task');
  }
  onUserSelect(event: any) {
    const selectedUserId = this._task.assignedto;
    const selectedUser = this._companyUsers.find(user => user.id === selectedUserId);
    if (selectedUser) {
      this._selectedUserId = selectedUser.id;
      this._selectedCustomerId = selectedUser.customerId;
    } else {
      this._selectedUserId = null;
      this._selectedCustomerId = null;
    }
    // console.log('selected user id ', this._selectedUserId);
    // console.log('selected customer id ', this._selectedCustomerId);
  }
}
