<ng-template #addPipeModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="#addPipeModalLabel">
      <i class="mdi mdi-account-plus"> </i> &nbsp;{{ _modalTitle }}
    </h5>
  </div>
  <div class="modal-body">
    <div class="row mb-3">
      <div class="col-md-12">
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-3">
        <label for="_pipe.pipeName" class="col-form-label">Title</label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control" [(ngModel)]="_pipe.pipeName" id="_pipe.pipeName"
          autocomplete="on" placeholder="Enter Lead Status Title" maxlength="50" required
          pattern="[A-Za-z\s]{1,15}"
          title="Pipe name should only contain letters and spaces, and be between 1-15 characters long." />
        <div *ngIf="!_pipe.pipeName|| (_pipe.pipeName && _pipe.pipeName.length === 0)"
          class="error-message error" style="color: red;">
          * required
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">

    <button type="button" class="btn btn-primary btn-sm" (click)="savePipe()">
      <i class="mdi mdi-content-save"> </i> &nbsp; {{ _saveTitle }}
    </button>
    <button type="button" class="btn btn-danger btn-sm" (click)="modal.close('by: close button')">
      <i class="mdi mdi-close"> </i>&nbsp;Close
    </button>
  </div>
</ng-template>

<h3>Company Settings</h3>
<ul ngbNav #defaultNav="ngbNav" class="nav-tabs mt-4" [(activeId)]="defaultNavActiveId">

  <li [ngbNavItem]="1">
    <a ngbNavLink (click)="onChangeTab(1)" class="container">
      <span class="text"> Company Settings </span>
    </a>

    <ng-template ngbNavContent>
      <div class="card rounded crm-light-bgcolor">
        <div class="card-header">
          <div class="row">
            <div class="col-md-3 col-sm-auto align-right">
              <!-- <input id="table-complete-search" type="text" class="form-control" placeholder="Search..."
                [(ngModel)]="_searchStatus" (keyup)="performStatusSearch()" /> -->
            </div>
            <div class="col-md-4 col-md-auto"></div>
            <div class="col-md-5 col-md-auto">
              <button class="btn btn-xs btn-primary btn-icon-text float-end"
                style="margin-left: 3px; width: 140px" (click)="updateCompanySettings()">

                <lord-icon src="https://cdn.lordicon.com/xzksbhzh.json" trigger="hover"
                  colors="primary:#000000,secondary:#ebe6ef" style="width:20px;height:20px">
                </lord-icon>
                &nbsp; Update Setting
              </button>
            </div>
          </div>
          &nbsp;
          <div class="row">
            <div class="col-md-12 stretch-card">
              <div class="card">
                <div class="card-body">

                  <div class="row">
                    <div class="col-md-6 column">

                      <input type="hidden" name="Iid" [(ngModel)]="_companySetting.id" />
                      <!-- Company Name -->
                      <div class="row mt-4">
                        <div class="col-md-10">
                          <label class="fw-bold">Company Name</label>
                          <input type="text" class="form-control"
                            [(ngModel)]="_companySetting.companyName" name="companyName" />
                        </div>
                      </div>

                      <!-- Email -->
                      <div class="row mt-4">
                        <div class="col-md-10">
                          <label class="fw-bold">Email</label>
                          <input type="text" class="form-control"
                            [(ngModel)]="_companySetting.email" name="email" />
                        </div>
                      </div>

                      <!-- Contact -->
                      <div class="row mt-4">
                        <div class="col-md-10">
                          <label class="fw-bold">Contact</label>
                          <input type="text" class="form-control"
                            [(ngModel)]="_companySetting.contact" name="contact" />
                        </div>


                      </div>

                      <!-- Street Address -->
                      <div class="row mt-4">
                        <div class="col-md-10">
                          <label class="fw-bold">Street Address</label>
                          <input type="text" class="form-control"
                            [(ngModel)]="_companySetting.streetAddress" name="streetAddress" />
                        </div>
                      </div>


                      <!-- City -->
                      <div class="row mt-4">
                        <div class="col-md-10">
                          <label class="fw-bold">City</label>
                          <input type="text" class="form-control" [(ngModel)]="_companySetting.city"
                            name="city" />
                        </div>
                      </div>

                      <!-- Country -->
                      <div class="row mt-4">
                        <div class="col-md-10">
                          <label class="fw-bold">Country</label>
                          <input type="text" class="form-control"
                            [(ngModel)]="_companySetting.country" name="country" />
                        </div>
                      </div>
                      <!-- 
                      <div class="row">
                        <div class="col-md-10">
                          <label class="fw-bold">Currency</label>
                          <ng-select id="currency" [(ngModel)]="_selectedCurrency"
                            [items]="_currency" bindLabel="value" bindValue="id" [searchable]="true"
                            placeholder="Please Choose a Currency">
                          </ng-select>
                        </div>
                      </div> -->
                      <!-- <div class="row mt-4">
                        <label class="fw-bold"> Measurement Units</label>
                        <div class="col-md-10">
                          <label> Weight Unit</label>
                          <ng-select id="measurementUnit" [(ngModel)]="_selectedWeightUnit"
                            [items]="_weightUnit" bindLabel="value" bindValue="id"
                            [searchable]="true" placeholder="Please Choose a Unit">
                          </ng-select>
                        </div>
                      </div>
                      <div class="row mt-4">
                        <div class="col-md-10">
                          <label> Volume Unit</label>
                          <ng-select id="measurementUnit" [(ngModel)]="_selectedVolumeUnit"
                            [items]="_volumeUnit" bindLabel="value" bindValue="id"
                            [searchable]="true" placeholder="Please Choose a Unit">
                          </ng-select>
                        </div>
                      </div> -->
                      <div class="row mt-4">
                        <div class="col-md-10">
                          <label for="theme-selector">Theme Color</label>
                          <input class="form-control wd-150" [style.background]="_selectedColor"
                            [(colorPicker)]="_selectedColor" />
                          <p class="text-muted mt-2">Value: {{ _selectedColor }}</p>
                        </div>
                      </div>
                      <div class="row mt-4">
                        <div class="col-md-10">
                          <label class="fw-bold">Select Logo</label>
                          <div class="file">
                            <input #fileInput type="file" (change)="onLogoFileSelected($event)"
                              accept=".png, .jpg, .jpeg" />
                            <button *ngIf="logoBase64String" type="button" class="btn btn-link"
                              (click)="clearLogoFile()">
                              Clear
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3 mt-3">
                        <img class="logo-image" *ngIf="_companySetting?.logo"
                          [src]="_companySetting.logo" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </li>

  <li [ngbNavItem]="3">
    <a ngbNavLink (click)="onChangeTab(3); refreshPipeTableData()" class="container">
      <!-- <lord-icon src="https://cdn.lordicon.com/jvucoldz.json" trigger="hover"
        colors="primary:#110a5c,secondary:#66a1ee" style="width:23px;height:23px">
      </lord-icon> -->
      <span class="text"> Sales Lead Status </span>
    </a>
    <ng-template ngbNavContent>

      <div class="card-header">
        <div class="row">
          <div class="col-md-3 col-sm-auto align-right">
            <input id="table-complete-search" type="text" class="form-control" name="searchRoles"
              placeholder="Search..." [(ngModel)]="searchPipe" (keyup)="performPipesSearch()" />
          </div>
          <div class="col-md-9 col-sm-auto text-end">
            <button class="btn btn-xs btn-primary btn-icon-text" (click)="openAddPipe(addPipeModal)"
              style="margin-left:3px;width: 130px;">
              <lord-icon src="https://cdn.lordicon.com/xzksbhzh.json" trigger="hover"
                colors="primary:#000000,secondary:#ebe6ef" style="width:20px;height:20px">
              </lord-icon>
              &nbsp; Add Status
            </button>
          </div>
        </div>
        &nbsp;
        <div class="row" *ngIf="pipeTableData.length === 0">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body text-center">
                <p>No Status, currently. Please Add a Sales Lead Status.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="pipeTableData.length > 0">
          <div class="col-md-12 stretch-card">
            <div class="card">
              <div class="card-body">
                <div class="table-responsive">
                  <ngx-datatable [rows]="pipeTableData" class="bootstrap" [loadingIndicator]="true"
                    [columnMode]="ColumnMode.force" [footerHeight]="40" [limit]="10"
                    [pagination]="true" [pageSize]="10" [rowHeight]="40">
                    <ngx-datatable-column *ngFor="let column of pipeTableColumns"
                      [name]="column.header">
                      <ng-template ngx-datatable-cell-template let-row="row" let-value="value">

                        <ng-container *ngIf="column.header === 'Title'">
                          <div class="d-flex align-items-center hover"
                            (mouseenter)="onRowMouseEnter(row)" (mouseleave)="onRowMouseLeave(row)">
                            {{ row.pipeName | slice:0:15 }}
                            <button class="btn btn-secondary button-wrapper btn-icon-text
                            btn-small-transparent btn-smaller" *ngIf="row.isHovered"
                              (click)="pipePreviewClick(row.id)">
                              Preview
                            </button>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="column.header !== 'Title'">
                          {{ value }}
                        </ng-container>
                      </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Action">
                      <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex"
                        let-value="value">
                        <button type="button" class="btn btn-xs datatable-action-cell"
                          [class.btn-success]="!row.isActive" [class.btn-danger]="row.isActive"
                          (click)="updatePipeStatus(row, row.isActive)">
                          {{ !row.isActive ? "Activate" : "Deactivate" }}
                        </button>
                        &nbsp;
                        <button type="button"
                          class="btn btn-xs btn-danger datatable-action-cell ml-2"
                          (click)="updatePipeStatus(row, row.isActive, true)">
                          Delete
                        </button>
                      </ng-template>
                    </ngx-datatable-column>
                  </ngx-datatable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </ng-template>
  </li>

  <li [ngbNavItem]="4">
    <a ngbNavLink (click)="onChangeTab(2)" class="container">
      <span class="text"> Letter Head </span>
    </a>
    <ng-template ngbNavContent>
      <div>
        <h3>Section Under development </h3>
        <p>This section is currently under development. Stay tuned for updates!</p>
      </div>
    </ng-template>

  </li>
</ul>
<div [ngbNavOutlet]="defaultNav" class="border border-top-0 p-3"></div>