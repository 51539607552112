import { NavigationExtras, Router } from '@angular/router';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  Renderer2,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SaleService } from "src/services/sale.service";
import { ContactService } from '../../../../../services/contact.service';
import { AppComponent } from '../../../../app.component';
import { _dealsColumns } from '../../contact/contact.models';
import { AttributeValueDTO, DealsModel } from './deals.models';
import { DatatableComponent, ColumnMode } from '@swimlane/ngx-datatable';
import { AuthService } from 'src/services/auth.service';

@Component({
  selector: 'app-deals',
  templateUrl: './deals.component.html',
  styleUrls: ['./deals.component.scss']
})
export class DealsComponent implements OnInit {
  //class variablescloseResult!: string;

  @ViewChild('addDealModal') addDealModal: any; /*TemplateRef<any>*/
  @ViewChild('ngx-datatable') table?: DatatableComponent;

  isRowHovered: boolean = false;
  _currentUserId: number = 0;
  rows = [];
  loadingIndicator = true;
  reorderable = true;
  columnMode: ColumnMode = ColumnMode.force;

  _companyId: number = 0
  _popupVisible = false;
  formSubmitted: boolean = false;
  _pipesList: any[] = [];
  _pipes: any;
  _pipeId: any = 'all';

  _dealsList: any[] = [];
  _modalTitle: any;

  //_deal = new InsertOrUpdateDealDTO();
  _deal = new DealsModel();
  _dealName!: string | null;
  _attributes = new AttributeValueDTO();

  _allContacts: any[] = [];
  _contacts: any[] = [];
  _dealClickCounter: number = 0;
  _previewClickCounter: number = 0;

  _displayedDeals: any[] = [];
  _tableHeaders: string[] = [];

  defaultNavActiveId: any;

  selectedDate!: string;
  selectedTime!: string;

  dynamicPipes: any = 'all';
  searchTerm!: string;
  filteredItems!: string[];

  tableData: any[] = []; // Array to hold the table data
  tableColumns: any[] = _dealsColumns; // Array to hold the table column headers

  _allColumns: string[] = _dealsColumns;
  _selectedColumns: { [key: string]: boolean } = {};
  closeResult: string = '';

  // role base permission
  _createPermission = false;
  _updatePermission = false;
  _readPermission = false;
  _deletePermission = false;
  _userRole: string | null = null;

  constructor(
    private modalService: NgbModal, private _modalService: NgbModal,
    private _saleService: SaleService, private _appComponent: AppComponent,
    private _contactService: ContactService, private elementRef: ElementRef,
    private renderer: Renderer2, private router: Router, private _authService: AuthService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    let companyId = Number(localStorage.getItem('companyId'));
    if (companyId) {
      this._companyId = companyId;
    }
  }

  async ngOnInit(): Promise<void> {
    const currentUserId = localStorage.getItem('currentUserId');
    this._currentUserId = Number(currentUserId);

    this._userRole = localStorage.getItem('role');
    let isLoggedin = localStorage.getItem('isLoggedin');

    // Retrieve permissions from local storage
    const permissions = this._authService.getPermissionsFromLocalStorage();

    // Set permission variables
    this._readPermission = permissions.includes('Sales.Read') || this._userRole === 'CompanyAdmin';
    this._createPermission = permissions.includes('Sales.Create') || this._userRole === 'CompanyAdmin';
    this._updatePermission = permissions.includes('Sales.Update') || this._userRole === 'CompanyAdmin';
    this._deletePermission = permissions.includes('Sales.Delete');

    if (isLoggedin == 'true') {

      if (!this._readPermission) {
        this.checkPermissions();
        // await this.router.navigate(['/dashboard']);
      }

      else {

        this._companyId = this._companyId;

        this.getPipesList(this._companyId);
        this.getDealsList();

        if (this._pipesList && this._pipesList.length > 0) {
          //console.log("this._pipesList[0].Id", this._pipesList[0].id)
          /*this.onPipeChange(this._pipesList[0].Id)*/

        }

        //this._modalTitle = "Deal";
        const now = new Date();
        this.selectedDate = this.formatDate(now);
        this.selectedTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });

      }
    } else {
      await this.router.navigate(['/login']);
    }

  }

  async checkPermissions() {
    await this._authService.showUnAuthorizedDialog('Unauthorized', 'You are not authorized to visit see the sales', 'read');
    // console.log("go to dashboard. no read permission");
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth <= 768) { // Adjust the breakpoint as needed
      this.columnMode = ColumnMode.flex; // Switch to flex mode for mobile/tablet
    } else {
      this.columnMode = ColumnMode.force; // Switch back to force mode for desktop
    }
  }

  /*private setupDealClickListeners(): void {
    const dealClickElements = this.elementRef.nativeElement.querySelectorAll('.dealClick');

    dealClickElements.forEach((dealClickElement: any) => {
      this.renderer.listen(dealClickElement, 'click', (event: any) => {
        const id = event.currentTarget.getAttribute('data-id');

        if (!dealClickElement.classList.contains('click-processed')) {
          dealClickElement.classList.add('click-processed');

          console.log("Deal clicked");
          this.dealClick(Number(id));
        }
      });
    });
  }*/


  onRowMouseEnter(row: any) {
    row.isHovered = true;
  }

  onRowMouseLeave(row: any) {
    row.isHovered = false;
  }

  ngDoCheck(): void {
    const dealClick = this.elementRef.nativeElement.querySelectorAll('.dealClick');
    dealClick.forEach((dealClick: any) => {
      this.renderer.listen(dealClick, 'click', (event: any) => {
        const id = event.currentTarget.getAttribute('data-Id');
        this._dealClickCounter++;
        if (this._dealClickCounter === 1) {
          this.dealClick(Number(id));
        }
      });
    });

    // Add event listener for edit click
    const previewClickSpan = this.elementRef.nativeElement.querySelectorAll('.previewClickSpan');
    previewClickSpan.forEach((previewClick: any) => {
      this.renderer.listen(previewClick, 'click', (event: any) => {
        const id = event.currentTarget.getAttribute('data-Id');
        this._previewClickCounter++;
        if (this._previewClickCounter === 1) {
          this.getSingleDealAndOpenModal(Number(id));
        }
      });
    });
  }
  performSearch() {
    
    if (this.searchTerm === '') {
     
      this._displayedDeals = this._dealsList;
      return;
    }
    const filteredData = this._dealsList.filter((row: any) => {
      return Object.values(row).some((value: any) => {
        return String(value).toLowerCase().includes(this.searchTerm.toLowerCase());
      });
    });

    this._displayedDeals = filteredData;
    if (this.table) {
      this.table.offset = 0;
    }

    this.table?.recalculate();
    this.table?.bodyComponent.updateOffsetY();
  }



  updateUserStatus(row: any, status: any) {
    const userId = Number(row.id);

    if (row.isDeleted) {
      status = false;
    } else {
      status = true;
    }
  }
  setDefaultSelectedColumns() {
    this._allColumns.forEach((column) => {
      this._selectedColumns[column] = true;
    });

    this._allColumns.forEach((column) => {
      this._selectedColumns[column] = true;
    });
  }

  previewClick(id: number) {
    // console.log("id", id);
    this.getSingleDealAndOpenModal(Number(id));
  }

  dealClick(id: any) {

    const text = 'deal';
    const queryParams = { text, id };
    const navigationExtras: NavigationExtras = {
      queryParams,
      skipLocationChange: false,
    };
    this.router.navigate(['/profile'], navigationExtras);
    this._dealClickCounter = 0;

  }

  getSingleDealAndOpenModal(id: number): void {

    const data = this._dealsList.filter(item => item.id === id)[0];
    this._deal = data;

    this._deal.lastContact = this.formatDateForPreview(data.lastContact);
    this._dealName = this._deal.name;
    //console.log("single deal", this._deal);

    this.openEditDealModal();
    this._previewClickCounter = 0;
  }
  //class functions

  openAddDealModal(template: TemplateRef<any>) {
    this._modalTitle = "Add Deal";
    //this._deal = new InsertOrUpdateDealDTO();
    //this._deal.salesRepId = 0;
    //this._deal.dealOwnerId = 0;
    this._deal = new DealsModel();

    this.modalService.open(template, { size: 'md' }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
        this._dealName = null;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  //template: TemplateRef<any>
  openEditDealModal() {
    this._modalTitle = "Update Deal";

    this._modalService.open(this.addDealModal, { size: 'md' }).result.then(
      (result) => {
        //this.basicModalCloseResult = "Modal closed" + result

      }).catch((res) => { });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  addPipe() {
    // console.log("add pipe clicked")
  }

  getPipesList(companyId: number) {
    this._saleService.getPipes(companyId).subscribe(
      (data: any) => {
        if (data && data.length > 0) {
          this._pipesList = data.filter((item: { isDeleted: boolean; }) => item.isDeleted === false);
          this.getContacts();
        }
      },
      (error: any) => {
        console.error("error getting pipes list", error);
      }
    );
  }

  //onPipeChange(pipeId: number) {
  //  // Do something with the selected PipeId
  //    this._pipeId = pipeId;
  //  // You can perform any desired actions based on the selected PipeId
  //  this.showDealsByPipeId(pipeId);
  //}

  onPipeChange(pipeId: any) {
    if (pipeId === 'all') {
      // Handle logic for displaying data from all pipes
      // For example, you might want to retrieve and display data for all pipes here
      // You can call the appropriate functions to achieve this
      //this.showDealsForAllPipes();
      this._pipeId = pipeId;
      this._displayedDeals = [...this._dealsList];
      this.tableColumns.splice(1, 0, { header: 'Type' });

    } else {
      // Handle logic for displaying data for a specific pipe
      // For example, you might want to call functions related to the selected pipe
      this._pipeId = pipeId;
      this.tableColumns = this.tableColumns.filter(column => column.header !== 'Type');
      this.showDealsByPipeId(this._pipeId);
    }
  }

  getCurrentDate(): string {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day = currentDate.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  saveDeal() {
    this.formSubmitted = true;
    // console.log("saving deal", this._deal);

    if (this._dealName === null) {
      return;
    }
    this._deal.name = this._dealName;
    if ((this._deal.name.trim().length === 0) || (this._deal.pipeId === 0)) {
      return;
    }

    if (this._deal.contactId === null) {
      this._deal.contactId = 0;
    }
    if (!this._deal.contactId) {
      // Show an alert or handle the absence of contact details as needed
      return;
    }
    this._deal.name = this._dealName;
    if ((this._deal.name.trim().length === 0) || (this._deal.pipeId === 0)) {
      return;
    }


    //service will be called

    const selectedDate = new Date(this.selectedDate);
    const selectedTime = this.selectedTime.split(':').map(Number);
    selectedDate.setHours(selectedTime[0]);
    selectedDate.setMinutes(selectedTime[1]);

    // this._deal.lastContact = selectedDate;
    this._deal.createdBy = Number(this._currentUserId);

    this.setAmount(this._deal.amount);
    // console.log('amount deall', this._deal.amount)
    this.setProbability(this._deal.probability);
    // console.log("creating or updating deal: ", this._deal);
    if (this._deal.id == 0) {
      this._deal.ownedBy = this._companyId;
      this._deal.createdBy = this._currentUserId;
      this._saleService.saveDeal(this._deal).subscribe(
        response => {
          this._modalService.dismissAll('by: calling closeModal()');
          this._appComponent.showSuccessSwal('saved', 'deal');
          this.closePopup();
          this.getDealsList();
        },
        error => {
          this._appComponent.showErrorSwal('saving', 'deal');
        }
      );
    }
    else {
      //this._deal.dealParameters.id = this._deal.dealParameters.dealId;
      this._saleService.updateDeal(this._deal).subscribe(
        response => {
          this._modalService.dismissAll('by: calling closeModal()');
          this._appComponent.showSuccessSwal('updated', 'deal');
          this.closePopup();
          this.getDealsList();
        },
        error => {
          this._appComponent.showErrorSwal('updating', 'deal');
        }
      )
    }
    this.formSubmitted = false;
    // this._deal = new DealsModel();
  }

  getDealsList() {
    this._saleService.getDeals().subscribe(
      (data: any) => {

        if (data && data.length > 0) {
          this._displayedDeals = data;
          this._dealsList = data;
          this._displayedDeals = data;
          if (this._pipesList && this._pipesList.length > 0) {
            //this.showDealsByPipeId(this._pipeId);
            // console.log("_pipeId", this._pipeId);
            // this.onPipeChange(this._pipeId);
            this._dealsList = this._dealsList.map(deal => {
              const matchingPipe = this._pipesList.find(pipe => pipe.id === deal.pipeId);
              deal.type = matchingPipe ? matchingPipe.pipeName : ' - ';
              return deal;
            });
            this._displayedDeals = [...this._dealsList];
          }
          // console.log("_dealsList", this._dealsList);
          // Get the keys of the first object in _dealsList
          const dealKeys = Object.keys(this._dealsList[0]);
          this.tableColumns = Object.keys(data[0])
            .filter((key) => key !== 'id'
              && key !== 'isDeleted'
              && key !== 'pipeId'
              && key !== 'contactId'
              && key !== 'creationDate'
              && key !== 'createdBy'
              && key !== 'modificationDate'
              && key !== 'modifiedBy'
              && key !== 'deletionDate'
              && key !== 'dealOwnerId'
              && key !== 'salesRepId'
              && key !== 'salesRep'
              && key !== 'pipeName'
            )
            .map((key) => {
              let header: string;
              switch (key) {
                case 'name':
                  header = 'Deal Name';
                  break;
                case 'contactName':
                  header = 'Contact Name';
                  break;
                case 'type':
                  header = 'Type';
                  break;
                case 'amount':
                  header = 'Amount';
                  break;
                case 'probability':
                  header = 'Probability';
                  break;
                case 'dealOwner':
                  header = 'Deal Owner';
                  break;
                case 'lastContact':
                  header = 'Last Contact';
                  break;
                //case 'salesRep':
                //  header = 'Sales Rep';
                //  break;
                default:
                  header = key; // Use the original key if no specific header is defined
                  break;
              }

              return { header };
            });
          const typeColumnIndex = this.tableColumns.findIndex(column => column.header === 'Type');
          if (typeColumnIndex > 1) {
            const typeColumn = this.tableColumns.splice(typeColumnIndex, 1)[0];
            this.tableColumns.splice(1, 0, typeColumn);
          }
          //Filter out the keys that have string values
          //this._tableHeaders = dealKeys.filter(key => typeof this._dealsList[0][key] === 'string');
          // console.log("table columns", this.tableColumns);
        }
      },
      (error: any) => {
        console.error("error getting deals list", error); // Optional: Handle or log the error
      }
    );
  }

  deleteDealClick(id: any) {
    //console.log("deleteClick", id)
    if (confirm('Are you sure you want to delete record?')) {
      this._saleService.deleteDeal(id).subscribe({
        next: response => {
          this._appComponent.showSuccessSwal('deleted', 'deal');
          this.router.navigate(['/sales']);
        },
        error: error => {
          console.error("error deleting deal", error);
          this._appComponent.showErrorSwal('deleting', 'deal');
        }
      });
    }
  }

  getContacts() {
    this._contactService.getContacts().subscribe(
      (data: any) => {
        // console.log("get contacts response", data);
        if (data && data.length > 0) {
          this._allContacts = data;
          // this._allContacts = data.filter((item: { contactType: number; companyId: number }) => item.contactType === 0 && item.companyId === this._companyId);
          this._contacts = data.filter((item: { contactType: number; companyId: number }) => item.contactType === 0 && item.companyId !== this._companyId);
        }
        // console.log("this._contacts", this._contacts);
      },
      error => {
        console.error("error getting contacts", error);
      }
    );
  }

  showDealsForAllPipes() {
    this._displayedDeals = this._dealsList;
  }

  showDealsByPipeId(pipeId: number) {
    // Filter the deals based on the selected PipeId
    const filteredDeals = this._dealsList.filter(deal => deal.pipeId === pipeId);
    this._displayedDeals = filteredDeals;
    // console.log("_displayedDeals: ", this._displayedDeals)
    //console.log("pipeId: ", pipeId)
  }

  search(): void {
  }

  closePopup() {
    this._modalService.dismissAll('by: calling closeModal()');
    this._popupVisible = false;

  }

  private formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = this.padZero(date.getMonth() + 1);
    const day = this.padZero(date.getDate());
    return `${year}-${month}-${day}`;
  }

  private formatTime(date: Date): string {
    let hours = date.getHours();
    const minutes = this.padZero(date.getMinutes());
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    const formattedTime = `${this.padZero(hours)}:${minutes}`;
    return `${formattedTime} ${ampm}`;
  }

  private padZero(value: number): string {
    return value.toString().padStart(2, '0');
  }

  setAmount(value: any) {
    if (typeof value === 'number') {
      this._deal.amount = value;
    } else if (typeof value === 'string') {
      const parsedValue = parseFloat(value);
      if (!isNaN(parsedValue)) {
        this._deal.amount = parsedValue;
      }
    }
  }

  setProbability(value: any) {
    if (typeof value === 'number') {
      this._deal.probability = value;
    } else if (typeof value === 'string') {
      const parsedValue = parseFloat(value);
      if (!isNaN(parsedValue)) {
        this._deal.probability = parsedValue;
      }
    }

  }

  validateNumericField(event: KeyboardEvent): void {
    const input = event.key;
    const value = (event.target as HTMLInputElement).value;
    const isNumericOrDecimal = /^[0-9.]$/.test(input);
    const hasDecimalPoint = value.includes('.');
    const newValue = value + input;
    const isValidNumber = /^[-+]?(?:\d+)?(?:\.\d*)?$/.test(newValue);

    if (!isNumericOrDecimal || (input === '.' && hasDecimalPoint) || !isValidNumber) {
      event.preventDefault();
    }
  }

  formatDateForPreview(dateString: any) {
    if (isNaN(Date.parse(dateString))) {
      return null;
    }
    return dateString.split('T')[0]; // Return only the date part 'yyyy-mm-dd'
  }

  formatDateForTable(date: string) {
    if (!date) {
      return "Select Date";
    }
    const startDateFormatted = new Date(date).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    });

    return startDateFormatted;
  }

}

//end component

//addCustomPipe(): void {
//  const modalRef = this.modalService.open(CustomPipeDialogComponent, {
//    size: 'md',
//    backdrop: 'static',
//    keyboard: false
//  });

//modalRef.result.then((result: string) => {
//  // Handle the result after the modal is closed
//  if (result === 'success') {
//    // Custom pipe added successfully, perform necessary actions
//    console.log('Custom pipe added successfully');
//  }
//}).catch((reason: any) => {
//  // Handle the dismiss or error scenario
//  console.log('Custom pipe addition canceled or encountered an error');
//});

//hasSelectedCompColumns(): boolean {
//  //console.log(JSON.stringify(this._selectedCompColumns));
//  return Object.values(this._selectedCompColumns).some((value) => value);
//}



//function CustomPipeDialogComponent(CustomPipeDialogComponent: any, arg1: { size: string; backdrop: string; keyboard: boolean; }) {
//    throw new Error('Function not implemented.');
//}

