<div class="loader-overlay" *ngIf="isLoading === true">
  <div id="loader">
    <div class="spinner"></div>
  </div>
</div>

<div class="login-container">

  <div class="forms-container">
    <div class="signin-signup">
      <form [formGroup]="loginForm" class="sign-in-form" (ngSubmit)="onLoggedin($event)">
        <a routerLink="/" class="sidebar-brand nobleui-logo" style="font-size: 50px;">
          CRM<span>Flo</span>
          <!-- <img src="assets/images/logo.png" width="200" /> -->
        </a>
        <a class="sidebar-brand nobleui-logo" style="font-size: 20px;">
          Sign<span>in</span>
        </a>

        <div class="input-field">
          <i class="fas fa-envelope"></i>
          <input type="email" class="form-control" id="txtemail" formControlName="email" placeholder="Email"
            autocomplete="on">
        </div>
        <div
          *ngIf="loginForm.get('email')?.invalid && (loginForm.get('email')?.dirty || loginForm.get('email')?.touched || submitted)">
          <div *ngIf="loginForm.get('email')?.errors?.['required']" class="error-message">
            *Email is required!</div>
        </div>


        <div class="input-field">
          <i class="fas fa-lock"></i>
          <input type="password" class="form-control" id="txtpass" formControlName="password" placeholder="Password">
        </div>
        <div
          *ngIf="loginForm.get('password')?.invalid && (loginForm.get('password')?.dirty || loginForm.get('password')?.touched || submitted)">
          <div *ngIf="loginForm.get('password')?.errors?.['required']" class="error-message">
            *Password is required!</div>
        </div>
        <br />
        <div class="remember-me-label" for="authCheck">
          <input type="checkbox" id="authCheck" formControlName="rememberMe">
          Remember me
        </div>

        <div>
          <input type="submit" value="Login" class="btn btn-primary" (click)="onLoggedin($event)" />
          <div *ngIf="errorMessage" class="error-message">{{ errorMessage }}</div>
        </div>

        <div class="mt-3">
          <a routerLink="/forgot-password" class="forgot-password-link">Forgot Password?</a>
        </div>

        <!-- <p class="social-text">Or Sign in with social platforms</p> -->
        <!-- Social Media Icons -->
        <!-- <div class="social-media">
          <a href="#" class="social-icon">
            <i class="fab fa-facebook-f"></i>
          </a>
          <a href="#" class="social-icon">
            <i class="fab fa-twitter"></i>
          </a>
          <a href="#" class="social-icon">
            <i class="fab fa-google"></i>
          </a>
          <a href="#" class="social-icon">
            <i class="fab fa-linkedin-in"></i>
          </a>
        </div> -->
      </form>
    </div>
  </div>



</div>