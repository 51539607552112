import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../environments/environment';
import { AuthServicePaths } from '../enums/auth-service-paths';
import { GetCompaniesModel } from '../app/views/admin/super-admin/super-admin.models';
import { tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { jwtDecode } from 'jwt-decode';
import { Permission, PermissionDTO } from 'src/app/views/MOVD/models/permission.model';
import { CompanySetting } from 'src/app/views/MOVD/models/companysetting.model';
import { RolePermission } from 'src/app/views/MOVD/models/role-permission.model';
export interface LoginResponse {
  token: string;
  user: any; // Replace 'any' with the type of your user object
  permissions: any;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  readonly APIUrl = environment.baseUrl;

  constructor(
    private http: HttpClient,
    private _router: Router,
    private _route: ActivatedRoute
  ) { }

  register(user: any): Observable<any> {
    return this.http.post(this.APIUrl + AuthServicePaths.register, user);
  }

  login(user: any): Observable<LoginResponse> {
    return this.http
      .post<LoginResponse>(this.APIUrl + AuthServicePaths.login, user)
      .pipe(
        tap((res) => {
          localStorage.clear();
          if (res && res.token) {
            localStorage.setItem('token', res.token);
            localStorage.setItem(
              'permissions',
              JSON.stringify(res.permissions)
            );
          }
        })
      );
  }

  forgotPassword(email: string, isForgotPassword = true): Observable<any> {
    const params = new HttpParams()
      .set('email', email.trim().toString())
      .set('isForgotPassword', isForgotPassword.toString())
    // console.log("forgotPassword email", email);
    return this.http.post(`${this.APIUrl}/Auth/ForgotPassword`, {}, { params: params });
  }

  resetPassword(token: string, newPassword: string): Observable<any> {
    const params = new HttpParams()
      .set('token', token)
      .set('newPassword', newPassword);
    // console.log("reset password params", params);
    return this.http.post(`${this.APIUrl}/Auth/ResetPassword`, {}, { params: params });
  }

  checkEmailExists(email: string,) {
    const params = new HttpParams()
      .set('email', email)
    return this.http.get<any>(`${this.APIUrl}/Auth/CheckEmailExists`, { params: params });
  }

  getToken(): string {
    return localStorage.getItem('token') || '';
  }

  getPermissionsFromLocalStorage(): string[] {
    let permissions = localStorage.getItem('permissions');
    return permissions ? JSON.parse(permissions) : [];
  }

  decodeToken(token: string): any {
    return jwtDecode(token);
  }

  getAllCompanies(): Observable<GetCompaniesModel[]> {
    return this.http.get<any[]>(this.APIUrl + AuthServicePaths.getAllCompanies);
  }

  getUsersOfCompany(): Observable<any[]> {
    const companyId = localStorage.getItem('companyId');
    return this.http.get<any[]>(this.APIUrl + AuthServicePaths.getUsersOfCompany + '/' + companyId);
  }

  updateUserStatus(userId: any, status: boolean, softDelete = false) {
    const url = `${this.APIUrl}/Auth/UpdateUserStatus?id=${userId}&status=${status}&softDelete=${softDelete}`;
    return this.http.post(url, null);
  }

  // Service for RolePermission API
  savePermission(permission: Permission): Observable<any> {
    return this.http.post(`${this.APIUrl}/Auth/SavePermission`, permission);
  }
  // Service for GetAllPermissions API
  getAllPermissions(): Observable<PermissionDTO[]> {
    const ownedBy = Number(localStorage.getItem('companyId'));
    return this.http.get<PermissionDTO[]>(
      `${this.APIUrl}/Auth/GetAllPermissions?OwnedBy=${ownedBy}`
    );
  }

  // Service for SaveUserPermission API
  saveRolePermission(permission: RolePermission): Observable<any> {
    return this.http.post(`${this.APIUrl}/Auth/SaveRolePermission`, permission);
  }

  // Service for DeletePermission API
  deletePermission(id: number): Observable<any> {
    return this.http.delete(`${this.APIUrl}/Auth/DeletePermission/${id}`);
  }

  getCompanySettings(): Observable<CompanySetting> {
    const ownedBy = Number(localStorage.getItem('companyId'));
    return this.http.get<CompanySetting>(
      `${this.APIUrl}/Auth/GetCompanySettings?ownedBy=${ownedBy}`
    );
  }

  createOrUpdateCompanySettings(companySetting: CompanySetting): Observable<any> {
    return this.http.post(
      `${this.APIUrl}/Auth/CreateOrUpdateCompanySettings`, companySetting);
  }

  async showUnAuthorizedDialog(
    title: string,
    text: string,
    permission: string
  ) {
    const result = await Swal.fire({
      title: title,
      text: text,
      icon: 'error',
      showCancelButton: true,
      showConfirmButton: false,
    });

    if (!result.isConfirmed) {
      // console.log("reuslt not confirmed and router object", this._router);
      // console.log("route", this._route);
      // User pressed cancel, navigate back
      const urlTree = this._router.parseUrl(this._router.url);
      const graftedTree = this._router.createUrlTree([], {
        relativeTo: this._route,
        queryParams: urlTree.queryParams,
        fragment: urlTree.fragment ?? undefined,
        queryParamsHandling: 'preserve',
      });
      // console.log("grafted tree", graftedTree);

      //current auto route is not working
      // await this._router.navigateByUrl(graftedTree);

      if (permission === 'read') {
        this._router.navigate(['/dashboard']);
      }
    }

    return result.isConfirmed;
  }
  // savePermission(data: any): Observable<any> {
  //   return this.http.post(this.APIUrl + AuthServicePaths.savePermission, data);
  // }

  // getAllPermissions(ownedId: number) {
  //   const params = { ownedBy: ownedId };
  //   return this.http.get<any[]>(this.APIUrl + AuthServicePaths.getAllPermissions, { params });
  // }

  // saveRolePermission(data: any): Observable<any> {
  //   return this.http.post(this.APIUrl + AuthServicePaths.saveRolePermission, data);
  // }
}
