import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../../../services/auth.service';
import { AppComponent } from '../../../app.component';
import { UserModel } from '../auth.models';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  constructor(private router: Router,
    private authService: AuthService,
    private _appComponent: AppComponent) { }

  registerForm: FormGroup = new FormGroup({});
  username: string = '';
  email: string = '';
  password: string = '';
  _roleId: number = 0;
  _user = new UserModel();

  ngOnInit(): void {
    this.registerForm = new FormGroup({
      username: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', Validators.required)
    });
  }

  register() {
    
  }

  onRegister(e: Event) {
    e.preventDefault();

    const usernameInput = document.getElementById('username') as HTMLInputElement;
    const emailInput = document.getElementById('txtemail') as HTMLInputElement;
    const passInput = document.getElementById('txtpass') as HTMLInputElement;
    const username = usernameInput.value;
    const email = emailInput.value;
    const pass = passInput.value;

    this._user.username = username;
    this._user.email = email;
    this._user.password = pass;
    this._user.roleId = this._roleId;

    this.authService.register(this._user).subscribe(
      res => {
        // Handle successful registration
        this._appComponent.showSuccessLogin("Regsitered Successfully");
        //localStorage.setItem('isLoggedin', 'true');
        if (localStorage.getItem('isLoggedin')) {
          this.router.navigate(['/']);
        }
      },
      err => {
        // Handle registration error
        this._appComponent.showFailureLogin("User Regsiteration Failed");
      }
    );

  }
  redirectToLogin() {
    this.router.navigate(['/login']);
  }

}
