import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'formatUrl' })
export class FormatUrlPipe implements PipeTransform {
    transform(url: string): string {
        if (!/^http[s]?:\/\//.test(url)) {
            return `http://${url}`;
        } else {
            return url;
        }
    }
}