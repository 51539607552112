export class Permission {
  id: number;
  module: string | null;
  operation: string | null;
  name: string | null;
  ownedBy: number;
  isSelected: boolean;

  constructor() {
    this.id = 0;
    this.module = null;
    this.operation = null;
    this.name = `${this.module}.${this.operation}`;
    this.ownedBy = Number(localStorage.getItem('companyId'));
    this.isSelected = false;
  }
}

export interface PermissionDetailsDTO {
  permissionId: number;
  permissionName: string;
  isSelected: boolean;
}

export interface PermissionDTO {
  roleId: number;
  roleName: string;
  permissions: PermissionDetailsDTO[];
}
