import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BaseComponent } from './views/layout/base/base.component';
import { AuthGuard } from './core/guard/auth.guard';
import { ErrorPageComponent } from './views/error-page/error-page.component';
import { LoginComponent } from '../app/views/auth/login/login.component';
import { RegisterComponent } from './views/auth/register/register.component';
import { AuthGuardService } from 'src/services/auth-guard.service';
import { CompanySettingComponent } from './views/MOVD/admin/company-setting/company-setting';
import { ForgotPasswordComponent } from './views/auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './views/auth/reset-password/reset-password.component';
import { TaskComponent } from './views/CRM/profile/task/task.component';
import { TaskBoardComponent } from './views/CRM/profile/task-board/task-board.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  {
    path: '',
    component: BaseComponent,
    // canActivate: [AuthGuardService],
    canActivateChild: [AuthGuardService],
    children: [
      {
        path: 'company-setting',
        component: CompanySettingComponent,
        data: {
          title: 'Company Setting',
        },
      },
      {
        path: 'task',
        component: TaskComponent,
        data: {
          title: 'Task',
        },
      },
      {
        path: 'task-board',
        component: TaskBoardComponent,
        data: {
          title: 'task',
        },
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        data: {
          title: 'dashboard',
        },
      },
      {
        path: 'super-admin',
        loadChildren: () =>
          import('./views/admin/super-admin/super-admin.module').then(
            (m) => m.SuperAdminModule
          ),
      },
      {
        path: 'company-admin',
        loadChildren: () =>
          import('./views/admin/company-admin/company-admin.module').then(
            (m) => m.CompanyAdminModule
          ),
      },
      {
        path: 'contact',
        loadChildren: () =>
          import('./views/CRM/contact/contact.module').then(
            (m) => m.ContactModule
          ),
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('./views/CRM/profile/profile.module').then(
            (m) => m.ProfileModule
          ),
      },
      {
        path: 'sales',
        loadChildren: () =>
          import('./views/CRM/sales/deals/deals.module').then(
            (m) => m.DealsModule
          ),
      },
      {
        path: 'edit-profile',
        loadChildren: () =>
          import('./views/edit-profile/edit-profile.module').then(
            (m) => m.EditProfileModule
          ),
      },
    ],
  },
  {
    path: 'error',
    component: ErrorPageComponent,
    data: {
      type: 404,
      title: 'Page Not Found',
      desc: "Oopps!! The page you were looking for doesn't exist.",
    },
  },
  {
    path: 'error/:type',
    component: ErrorPageComponent,
  },
  { path: '**', redirectTo: 'error', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
