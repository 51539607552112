<nav class="page-breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a routerLink="../dashboard">Dashboard</a></li>
    <li class="breadcrumb-item active" aria-current="page">Super Admin Panel</li>
  </ol>
</nav>
<!-- Button trigger modal -->

<ng-template #addCompanyModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="#addCompanyModalLabel">
      <i class="mdi mdi-account-plus"> </i> &nbsp;{{_modalTitle}}
    </h5> <!---->
    <!--<button type="button" class="btn-close" (click)="modal.close('by: close icon'); closePopup();" aria-label="Close"></button>-->
  </div>
  <div class="modal-body">
    <div class="row mb-3">
      <div class="col-md-12">
        <!--<input type="hidden" value="_company.id" />-->
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-3">
        <label for="_company.companyName" class="col-form-label">Name</label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control" [(ngModel)]="_companyName" id="_company.companyName"
          autocomplete="on" placeholder="Enter Company name" required pattern="[A-Za-z\s]{1,25}"
          title="Company name should only contain letters and spaces, and be
                   between 1-25 characters long." />
        <div *ngIf="!_companyName || (_companyName && _companyName.length === 0)"
          class="error-message error" style="color: red">
          * required
        </div>
        <div *ngIf="!textIsValidLength(_company.companyName)" style="color: red;"></div>
      </div>
    </div>
    <!--<div class="row mb-3" *ngIf="_industries.length > 0">-->
    <div class="row mb-3">
      <div class="col-md-3">
        <label for="_industryId" class="col-form-label">Industry</label>
      </div>
      <div class="col-md-9">
        <ng-select [items]="_industryTableData" bindLabel="title" bindValue="id" [searchable]="true"
          [(ngModel)]="_industryId" placeholder="Select Industry">
        </ng-select>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-md-3">
        <label for="_company.email" class="form-label">Email</label>
      </div>
      <div class="col-md-9">
        <input type="email" class="form-control" [(ngModel)]="_company.email" id="_company.email"
          autocomplete="on" placeholder="Enter email" (blur)="checkEmailExists(_company.email)" />
        <div *ngIf="!_company.email || (_company.email && _company.email.length === 0)"
          class="error-message error" style="color: red;">
          * required
        </div>
        <div *ngIf="emailExists == true" style="color: red;">
          Already exists
        </div>
        <div
          *ngIf="_company.email && _company.email.length > 0 && !_company.email.match('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$')"
          class="error-message error" style="color: red;">
          Invalid format.
        </div>
      </div>
    </div>

    <div>
      <div class="row mb-3">
        <div class="col-md-3">
          <label for="_company.phone" class="form-label">Phone</label>
        </div>
        <div class="col-md-9">
          <input type="text" class="form-control" [(ngModel)]="_company.phone" id="_company.phone"
            autocomplete="on" placeholder="Enter Phone #"
            (keypress)="validatePhoneNumber($event)" />
          <div *ngIf="_company.phone.length<=0" class="error-message error" style="color: red;">
            * required
          </div>
          <div *ngIf="_company.phone.length > 0 && _company.phone.length < 8" style="color: red">
            Invalid number
          </div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-md-3">
          <label for="_company.city" class="form-label">City</label>
        </div>
        <div class="col-md-9">
          <input type="text" class="form-control" [(ngModel)]="_company.city" id="_company.city"
            autocomplete="on" placeholder="Enter City" />
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-3">
          <label for="_company.country" class="form-label">Country</label>
        </div>
        <div class="col-md-9">
          <input type="text" class="form-control" [(ngModel)]="_company.country"
            id="_company.country" autocomplete="on" placeholder="Enter Country" />
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary btn-sm" (click)="saveCompany();"> <i
        class="mdi mdi-content-save"> </i>
      &nbsp; Create Company </button>
    <button type="button" class="btn btn-danger btn-sm" (click)="modal.close('by: close button')">
      <i class="mdi mdi-close"> </i>&nbsp;Close</button>
    <!--<p>Modal Footer</p>-->
  </div>
</ng-template>


<ng-template #updateCompModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="#updateCompModalLabel"> <i class="mdi mdi-account-plus"> </i>
      &nbsp;Update Company </h5>
    <!---->
    <!--<button type="button" class="btn-close" (click)="modal.close('by: close icon'); closePopup();" aria-label="Close"></button>-->
  </div>
  <div class="modal-body">
    <div class="row mb-3">
      <div class="col-md-12">
        <!--<input type="hidden" value="_company.id" />-->
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-3">
        <label for="_company.companyName" class="col-form-label">Name</label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control" [(ngModel)]="_companyName" id="_company.companyName"
          autocomplete="on" placeholder="Enter Company name" required pattern="[A-Za-z\s]{1,25}"
          title="Company name should only contain letters and spaces, and be between 1-25 characters long." />
        <div *ngIf="!_companyName || (_companyName && _companyName.length === 0)"
          class="error-message error" style="color: red">
          * required
        </div>
      </div>
    </div>
    <div class="row mb-3" *ngIf="_industryTableData.length > 0">
      <div class="col-md-3">
        <label for="_industryId" class="col-form-label">Industry</label>
      </div>
      <div class="col-md-9">
        <ng-select [items]="_industryTableData" bindLabel="title" bindValue="id" [searchable]="true"
          [(ngModel)]="_industryId" placeholder="Select Industry">
        </ng-select>
      </div>
    </div>
    <input type="hidden" id="_compnay.id" />

    <div class="row mb-3">
      <div class="col-md-3">
        <label for="_company.email" class="form-label">Email</label>
      </div>
      <div class="col-md-9">
        <input type="email" class="form-control" [(ngModel)]="_companyEmail" id="_company.email"
          autocomplete="on" placeholder="Enter email" (blur)="checkEmailExists(_companyEmail)" />
        <div *ngIf="!_companyEmail || (_companyEmail && _companyEmail.length === 0)"
          class="error-message error" style="color: red;">
          * required
        </div>
        <div *ngIf="emailExists" style="color: red;">
          Already exists
        </div>
        <div
          *ngIf="_companyEmail && _companyEmail.length > 0 && !_companyEmail.match('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$')"
          class="error-message error" style="color: red;">
          Invalid email format.
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-3">
        <label for="_company.phone" class="form-label">Phone</label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control" [(ngModel)]="_companyPhone" id="_company.phone"
          autocomplete="on" placeholder="Enter Phone #" (keypress)="validatePhoneNumber($event)" />

        <div *ngIf="_companyPhone.length<=0" class="error-message error" style="color: red;">
          * required
        </div>
        <div *ngIf="_companyPhone.length > 0 && _companyPhone.length < 8" style="color: red">
          Invalid number
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-3">
        <label for="_company.city" class="form-label">City</label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control" [(ngModel)]="_company.city" id="_company.city"
          autocomplete="on" placeholder="Enter City" />
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-3">
        <label for="_company.country" class="form-label">Country</label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control" [(ngModel)]="_company.country" id="_company.country"
          autocomplete="on" placeholder="Enter Country" />
      </div>
    </div>


  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary btn-sm" (click)="saveCompany();"> <i
        class="mdi mdi-content-save"> </i>
      &nbsp; Update Company </button>
    <button type="button" class="btn btn-danger btn-sm" (click)="modal.close('by: close button')">
      <i class="mdi mdi-close"> </i>&nbsp;Close</button>
    <!--<p>Modal Footer</p>-->
  </div>
</ng-template>

<!--Industry FormModal-->
<ng-template #addIndustryModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="#addIndustryModalLabel">
      <i class="mdi mdi-account-plus"> </i> &nbsp;{{_modalTitle}}
    </h5> <!---->
    <!--<button type="button" class="btn-close" (click)="modal.close('by: close icon'); closePopup();"
            aria-label="Close"></button>-->
  </div>
  <div class="modal-body">
    <div class="row mb-3">
      <div class="col-md-12">
        <input type="hidden" value="_industry.id" />
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-3">
        <label for="_industry.title" class="col-form-label">Name</label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control" [(ngModel)]="_industryTitle" id="_industry.title"
          autocomplete="on" placeholder="Enter Industry name" required pattern="[A-Za-z\s]{1,15}"
          title="Industry name should only contain letters and spaces, and be
                   between 1-15 characters long." (keypress)="validateIndustryExists()"
          (input)="validateIndustryExists()" (blur)="validateIndustryExists()"
          (keyup.enter)="validateIndustryExists()" />
        <div *ngIf="!_industryTitle || (_industryTitle && _industryTitle.length === 0)"
          class="error-message error" style="color: red;">
          * required
        </div>
        <div *ngIf="industryExists" style="color: red;">
          Already exists
        </div>
      </div>
    </div>

  </div>
  <div class="modal-footer">
    <!-- Modal footer content -->
    <button type="button" class="btn btn-primary btn-sm" (click)="saveIndustry();">
      <i class="mdi mdi-content-save"> </i> &nbsp; {{_saveTitle}}
    </button> <!--Save Company-->
    <button type="button" class="btn btn-danger btn-sm" (click)="modal.close('by: close button')">
      <i class="mdi mdi-close"> </i>&nbsp;Close
    </button>
    <!--<p>Modal Footer</p>-->
  </div>
</ng-template>


<ul ngbNav #defaultNav="ngbNav" [(activeId)]="defaultNavActiveId" class="nav-tabs">
  <li [ngbNavItem]="1" (click)="onChangeTab(1)">
    <a ngbNavLink class="container">
      <!--<lord-icon src="https://cdn.lordicon.com/gqzfzudq.json"
                 trigger="hover"
                 colors="primary:#6571ff,secondary:#0a2e5c"
                 style="width:23px;height:23px">
      </lord-icon>-->
      <span class="text"> Companies </span>
    </a>

    <ng-template ngbNavContent>
      <div class="card rounded">
        <div class="card-header">
          &nbsp;
          <div class="md-3 row">
            <div class="col-md-3 col-sm-auto align-right">
              <input id="table-complete-search" type="text" class="form-control" name="searchTerm"
                placeholder="Search..." [(ngModel)]="searchTerm" (keyup)="performSearch()" />

            </div>
            <div class="col-md-4 col-md-auto">
            </div>
            <div class="col-md-5 col-md-auto">
              <button class="btn btn-xs btn-primary btn-icon-text float-end"
                (click)="openAddModal(addCompanyModal)" style="margin-left:3px;width: 130px;">
                <lord-icon src="https://cdn.lordicon.com/xzksbhzh.json" trigger="hover"
                  colors="primary:#000000,secondary:#ebe6ef" style="width:20px;height:20px">
                </lord-icon>
                &nbsp; Add Company
              </button>
            </div>
          </div>
          &nbsp;
          <div class="row" *ngIf="tableData.length === 0">
            <div class="col-md-12">
              <div class="card">
                <div class="card-body text-center">
                  <p> No Companies, currently. Please Add a company.</p>
                </div>
              </div>
            </div>
          </div>
          &nbsp;
          <div class="row" *ngIf="tableData.length > 0">
            <div class="col-md-12 stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="table-responsive">
                    <ngx-datatable [rows]="tableData" class="bootstrap"
                      [columnMode]="ColumnMode.force" [summaryRow]="false" [footerHeight]="40"
                      [limit]="10" [pagination]="true" [pageSize]="10"
                      [loadingIndicator]="loadingIndicator" [rowHeight]="40">
                      <ngx-datatable-column *ngFor="let column of tableColumns"
                        [name]="column.header" class="thead">
                        <ng-template ngx-datatable-cell-template let-row="row" let-value="value">

                          <ng-container *ngIf="column.header === 'Company Name'">
                            <div class="d-flex align-items-center hover"
                              (mouseenter)="onRowMouseEnter(row)"
                              (mouseleave)="onRowMouseLeave(row)">
                              {{ row.companyName | slice:0:15 }}
                              <button class="btn btn-secondary button-wrapper btn-icon-text
                              btn-small-transparent btn-smaller" *ngIf="row.isHovered"
                                (click)="previewClick(row.id, updateCompModal)">
                                Preview
                              </button>
                            </div>
                          </ng-container>
                          <ng-container *ngIf="column.header !== 'Company Name'">
                            {{value}}
                          </ng-container>
                        </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column name="Send Credentials">
                        <ng-template ngx-datatable-cell-template let-row="row"
                          let-rowIndex="rowIndex" let-value="value">
                          <button type="button" class="btn btn-xs send-btn datatable-action-cell"
                            (click)="sendCreds(row)">
                            Send
                          </button>
                        </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column name="Action">
                        <ng-template ngx-datatable-cell-template let-row="row"
                          let-rowIndex="rowIndex" let-value="value" class="">
                          <button type="button" class="btn btn-xs datatable-action-cell"
                            [class.btn-success]="!row.isActive" [class.btn-danger]="row.isActive"
                            (click)="updateUserStatus(row, row.isActive)">
                            {{ !row.isActive ? "Activate" : "Deactivate" }}
                          </button>
                          &nbsp;
                          <button type="button"
                            class="btn btn-xs btn-danger datatable-action-cell ml-2"
                            (click)="updateUserStatus(row, row.isActive, true)">
                            Delete
                          </button>
                        </ng-template>
                      </ngx-datatable-column>
                    </ngx-datatable>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="2" (click)="onChangeTab(2)">
    <a ngbNavLink class="container">
      <span class="text"> Industries </span>
    </a>

    <ng-template ngbNavContent>
      <div class="card rounded">
        <div class="card-header">
          &nbsp;
          <div class="md-3 row">
            <div class="col-md-3 col-sm-auto align-right">
              <input id="table-complete-search" type="text" class="form-control"
                name="searchIndustry" placeholder="Search..." [(ngModel)]="_searchIndustry"
                (keyup)="performIndustrySearch()" />

            </div>
            <div class="col-md-4 col-md-auto">
            </div>
            <div class="col-md-5 col-md-auto">
              <button class="btn btn-xs btn-primary btn-icon-text float-end"
                (click)="openAddIndustry(addIndustryModal)" style="margin-left:3px;width: 130px;">
                <lord-icon src="https://cdn.lordicon.com/xzksbhzh.json" trigger="hover"
                  colors="primary:#000000,secondary:#ebe6ef" style="width:20px;height:20px">
                </lord-icon>
                &nbsp; Add Industry
              </button>
            </div>
          </div>
          &nbsp;
          <div class="row" *ngIf="_industryTableData.length === 0">
            <div class="col-md-12">
              <div class="card">
                <div class="card-body text-center">
                  <p> No Industries, currently. Please Add an industry.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="_industryTableData.length > 0">
            <div class="col-md-12 stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="table-responsive">
                    <ngx-datatable [rows]="_industryTableData" class="bootstrap"
                      [columnMode]="ColumnMode.force" [summaryRow]="false" [footerHeight]="40"
                      [limit]="10" [pagination]="true" [pageSize]="10"
                      [loadingIndicator]="loadingIndicator" [rowHeight]="40">
                      <ngx-datatable-column *ngFor="let column of _industryTableColumns"
                        [name]="column.header" class="thead">
                        <ng-template ngx-datatable-cell-template let-row="row" let-value="value">

                          <ng-container *ngIf="column.header === 'Industry Name'">
                            <div class="d-flex align-items-center hover"
                              (mouseenter)="onRowMouseEnter(row)"
                              (mouseleave)="onRowMouseLeave(row)">
                              {{ row.title | slice:0:15 }}
                              <button class="btn btn-secondary button-wrapper btn-icon-text
                              btn-small-transparent btn-smaller" *ngIf="row.isHovered"
                                (click)="industryPreviewClick(row.id)">
                                Preview
                              </button>
                            </div>
                          </ng-container>
                          <ng-container *ngIf="column.header !== 'Industry Name'">
                            {{value}}
                          </ng-container>
                        </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column name="Action">
                        <ng-template ngx-datatable-cell-template let-row="row"
                          let-rowIndex="rowIndex" let-value="value" class="">
                          <button type="button" class="btn btn-xs datatable-action-cell"
                            [class.btn-success]="!row.isActive" [class.btn-danger]="row.isActive"
                            (click)="updateIndustryStatus(row, row.isActive)">
                            {{ !row.isActive ? "Activate" : "Deactivate" }}
                          </button>
                          &nbsp;
                          <button type="button"
                            class="btn btn-xs btn-danger datatable-action-cell ml-2"
                            (click)="updateIndustryStatus(row, row.isActive, true)">
                            Delete
                          </button>
                        </ng-template>
                      </ngx-datatable-column>
                    </ngx-datatable>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </li>
</ul>

<div [ngbNavOutlet]="defaultNav" class="mt-2"></div>