export enum ContactServicePaths {
  getUserProfile = '/Contact/GetUserProfile',
  updateUserProfile = '/Contact/UpdateUserProfile',
  customerStats = '/Contact/GetCustomerStats',
  GetCustomersStatsForChart = '/Contact/GetCustomersStatsForChart',
  getActivitiesStatsForChart = '/Contact/GetActivitiesStatsForChart',
  contactList = '/Contact/GetContacts',
  getContact = '/Contact/GetContact/{id}',
  addContact = '/Contact/AddContact',
  addContactWithAddress = '/Contact/AddContactWithAddress',
  updateContact = '/Contact/UpdateContact',
  updateContactFromProfile = '/Contact/UpdateContactFromProfile',
  deleteContact = '/Contact/DeleteContact/{id}',
  getAddressByContactId = '/Contact/GetAddressByContactId/{id}',
  //end contact
  addCustomerCall = '/Contact/AddCall',
  getCustomerCall = '/Contact/GetCall/{id}',
  getCustomerCalls = '/Contact/GetCalls/{id}',
  updateCall = '/Contact/UpdateCall',
  deleteCall = '/Contact/DeleteCall/{id}',
  //end call
  addCustomerEmail = '/Contact/AddEmail',
  getCustomerEmail = '/Contact/GetEmail/{id}',
  getCustomerEmails = '/Contact/GetEmails/{id}',
  updateEmail = '/Contact/UpdateEmail',
  deleteEmail = '/Contact/DeleteEmail/{id}',
  //end email
  addMeeting = '/Contact/AddMeeting',
  getMeeting = '/Contact/GetMeeting/{id}',
  getMeetings = '/Contact/GetMeetings/{id}',
  updateMeeting = '/Contact/UpdateMeeting',
  deleteMeeting = '/Contact/DeleteMeeting/{id}',
  //end meeting
  addCustomerNote = '/Contact/AddNote',
  getCustomerNote = '/Contact/GetNote/{id}',
  getCustomerNotes = '/Contact/GetNotes/{id}',
  updateNote = '/Contact/UpdateNote',
  deleteNote = '/Contact/DeleteNote/{id}',
  //end note
  addCustomerAttachment = '/Contact/AddAttachment',
  getCustomerAttachment = '/Contact/GetAttachment/{id}',
  getCustomerAttachments = '/Contact/GetAttachments/{id}',
  updateAttachment = '/Contact/UpdateAttachment',
  deleteAttachment = '/Contact/DeleteAttachment/{id}',
  //end attachment
  addCustomerActivity = '/Contact/AddActivity',
  getCustomerActivity = '/Contact/GetActivity/{id}',
  getCustomerActivities = '/Contact/GetActivities/{id}',
  updateActivity = '/Contact/UpdateActivity',
  deleteActivity = '/Contact/DeleteActivity/{id}',
  //end activity
  addRole = '/Contact/addRole',
  getRole = '/Contact/GetRole',
  updateRole = '/Contact/UpdateRole',
  deleteRole = '/Contact/deleteRole/{id}',
  getCompaniese = '/Contact/GetCompanies',
  //  getRoleById = '/Contact/GetRoleById/{id}',
  getRoles = '/Contact/GetRoles/{id}',
  //end role
  getIndustries = '/Contact/GetIndustries',
  addIndustry = '/Contact/AddIndustry',
  updateIndustry = '/Contact/UpdateIndustry',
  //tasks
  getTasks = '/Contact/GetTasks/{id}',
  addTask = '/Contact/AddTask',
  deleteTask = '/Contact/DeleteTask/{id}',
  updateTask = '/Contact/UpdateTask',
  updateTaskStatus = `/Contact/UpdateTaskStatus`,

  //get latest activities
  getLatestActivities = '/Contact/GetLatestActivites/{id}',
}

