import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { FeatherIconModule } from 'src/app/core/feather-icon/feather-icon.module';
import { NgbDropdownModule, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';

// Ng-ApexCharts
import { NgApexchartsModule } from "ng-apexcharts";
import { AddEditContactComponent } from '../contact/add-edit-contact/add-edit-contact.component';
import { ShowContactComponent } from '../contact/show-contact/show-contact.component';
import { ContactComponent } from '../contact/contact.component';

const routes: Routes = [
  {
    path: '',
    component: ContactComponent,
    children: [
      {
        path: 'contact//',
        redirectTo: 'show-contact',
        pathMatch: 'full'
      },
      {
        path: 'contacts',
        component: ShowContactComponent
      }
    ]
  }
]

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    FeatherIconModule,
    NgbDropdownModule,
    NgbDatepickerModule,
    NgApexchartsModule
  ],
  exports: [
    //AddEditContactComponent
  ]
})
export class ContactModule {  }
