
/*user.Password = "newComp@Techflo";*/

export class AddCompanyModel {
  id: number;
  username: string;
  phone: string;
  email: string;
  password: string;
  city: string;
  country: string;
  roleId: number;
  companyName: string;
  salesType: number;
  industryId: number | null;
  companyId: number;
  creationDate: Date;
  createdBy: number;
  isDeleted: boolean;
  isActive: boolean;
  addressId: number;

  constructor() {
    this.id = 0;
    this.username = '';
    this.phone = '';
    this.email = '';
    this.password = "newpass";
    this.city = '';
    this.country = '';
    this.companyName = '';
    this.industryId = null;
    this.companyId = 0;
    this.salesType = 0;
    this.roleId = 0;
    this.createdBy = 0;
    this.creationDate = new Date();
    this.isDeleted = false;
    this.isActive = true;
    this.addressId = 0;
  }
}

export class GetCompaniesModel {
  Id: number;
  CustomerId: number;
  CompanyName: string;
  Phone: string;
  Email: string;
  RoleId: number;
  RoleName: string;
  IndustryId: number;
  IndustryName: string;
  IsDeleted: boolean;
  AddressId: number;
  constructor() {
    this.Id = 0;
    this.CustomerId = 0;
    this.CompanyName = '';
    this.Phone = '';
    this.Email = '';
    this.IndustryId = 0;
    this.IndustryName = '';
    this.RoleId = 0;
    this.RoleName = '';
    this.IsDeleted = false;
    this.AddressId = 0;
  }
}


export class CustomerModel {
  companyName: string;
  customerName: string;
  id: number;
  phone: string;
  industryId: number | null;
  ownerId: number;
  roleId: number;
  companyId: number;
  email: string;
  fax: string;
  dateOfBirth: Date | null;
  details: string;
  contactType: number;
  salesType: any; // Update the type accordingly
  openPositions: number;
  linkedInURL: string;
  companyURL: string;
  noOfEmployees: string;
  creationDate: Date;
  createdBy: number;
  modificationDate: Date | null;
  modifiedBy: number | null;
  deletionDate: Date | null;
  isDeleted: boolean;
  adrs: {
    id: number;
    customerId: number;
    phoneNo: string;
    email: string;
    fax: string;
    streetAddress: string;
    zipCode: string;
    city: string;
    state: string;
    country: string;
    creationDate: Date;
    createdBy: number | null;
    modificationDate: Date | null;
    modifiedBy: number | null;
    deletionDate: Date | null;
    isDeleted: boolean;
  };

  constructor() {
    this.companyName = '';
    this.customerName = '';
    this.id = 0;
    this.phone = '';
    this.industryId = 0;
    this.ownerId = 0;
    this.roleId = 0;
    this.companyId = 0;
    this.email = '';
    this.fax = '';
    this.dateOfBirth = null;
    this.details = '';
    this.contactType = 1;
    this.salesType = null; // Update the default value accordingly
    this.openPositions = 0;
    this.linkedInURL = '';
    this.companyURL = '';
    this.noOfEmployees = '';
    this.creationDate = new Date();
    this.createdBy = 0;
    this.modificationDate = null;
    this.modifiedBy = null;
    this.deletionDate = null;
    this.isDeleted = false;
    this.adrs = {
      id: 0,
      customerId: 0,
      phoneNo: '',
      email: '',
      fax: '',
      streetAddress: '',
      zipCode: '',
      city: '',
      state: '',
      country: '',
      creationDate: new Date(),
      createdBy: null,
      modificationDate: null,
      modifiedBy: null,
      deletionDate: null,
      isDeleted: false
    };
  }
}



export class PipeModel {
  id: number;
  companyId: number;
  pipeName: string;
  createdBy: number;
  isDeleted: boolean;
  isActive: boolean | null;
  constructor() {
    this.id = 0;
    this.companyId = 0;
    this.pipeName = '';
    this.createdBy = 0;
    this.isDeleted = false;
    this.isActive = true;
  }
}

export class RoleModel {
  id: number;
  companyId: number;
  roleName: string;
  createdBy: number;
  isDeleted: boolean;
  isActive: boolean | null;

  constructor() {
    this.id = 0;
    this.companyId = 0;
    this.roleName = '';
    this.createdBy = 0;
    this.isDeleted = false;
    this.isActive = true;
  }
}

export class PipeAttributeModel {
  id: number;
  companyId: number;
  name: string;
  dataType: string;
  createdBy: number;
  isDeleted: boolean;

  constructor() {
    this.id = 0;
    this.companyId = 0;
    this.name = '';
    this.dataType = '';
    this.createdBy = 0;
    this.isDeleted = false;
  }
}

export class IndustryModel {
  id: number;
  title: string;
  createdBy: number;
  isDeleted: boolean;
  isActive: boolean | null;

  constructor() {
    this.id = 0;
    this.title = '';
    this.createdBy = 0;
    this.isDeleted = false;
    this.isActive = true;
  }
}
