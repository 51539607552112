import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ContactService } from '../../../../services/contact.service';
import { DecimalPipe } from '@angular/common';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomerModel, AddCompanyModel, GetCompaniesModel, IndustryModel } from './super-admin.models';
import { AppComponent } from '../../../app.component';
import { AuthService } from '../../../../services/auth.service';
import { error } from 'console';
import { DatatableComponent, ColumnMode } from '@swimlane/ngx-datatable';
import { Router } from '@angular/router';
import { replace } from 'feather-icons/generated/feather-icons';

@Component({
  selector: 'app-super-admin',
  templateUrl: './super-admin.component.html',
  styleUrls: ['./super-admin.component.scss'],
})

export class SuperAdminComponent implements OnInit {
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _total$ = new BehaviorSubject<number>(0);

  ColumnMode = ColumnMode;

  _currentUserId: number = 0;
  currentCompanyId: number = 0;
  emailExists: boolean = false;
  industryExists: boolean = false;

  loadingIndicator = true;
  reorderable = true;
  //allContacts$: Observable<AllContacts[]> = of([]);
  searchTerm: string = '';
  _contacts: any = [];
  _companies: any = [];

  _company = new AddCompanyModel();
  _companyName: string = '';
  _companyEmail!: string | null;
  _companyPhone: string = '';
  _industryTitle!: string | null;

  _industryId: number | null = null;
  _salesTypeLabel: string = "Company Type";
  _types: any = null;
  _modalTitle: any;
  _saveTitle: any;
  _modalFunction: any;
  closeResult: string = '';

  //for all users/contacts/companies
  _hasSelectedColumns = false;
  _selectedColumns: { [key: string]: boolean } = {};

  @ViewChild('addCompanyModal') addCompanyModal: any;
  @ViewChild('updateCompModal') updateCompModal: any;
  @ViewChild('ngx-datatable') table?: DatatableComponent;


  @ViewChild('emailInput') emailInputRef!: ElementRef<HTMLInputElement> | undefined;
  emailErrorMessage = '';

  //for modals
  _companyVisible = true;
  _contactVisible = false;

  //for nav-tabs
  defaultNavActiveId = 1;

  // for industries tab
  @ViewChild('addIndustryModal') addIndustryModal: any;
  _industry = new IndustryModel();
  _searchIndustry: string = '';
  _industryTableData: any[] = [];
  _industryTableColumns: any[] = [];

  constructor(
    private _contactService: ContactService,
    private pipe: DecimalPipe,
    private _modalService: NgbModal,
    private _appComponent: AppComponent,
    private _authService: AuthService,
    private _router: Router) { }


  tableData: any[] = []; // Array to hold the table data
  tableColumns: any[] = []; // Array to hold the table column headers

  ngOnInit(): void {
    const role = localStorage.getItem('role');
    let isLoggedin = localStorage.getItem('isLoggedin');
    this.currentCompanyId = Number(localStorage.getItem('companyId'));

    // console.log("isLoggedin", isLoggedin);
    if (isLoggedin == 'true') {
      if (role == 'SuperAdmin') {
        this._currentUserId = Number(localStorage.getItem('currentUserId'));
        // console.log("current user id", this._currentUserId);

        this.refreshTableData();
        this.refreshIndustryTableData();
      }
      else {
        this._router.navigate(['/dashboard']);
      }
    } else {
      this._router.navigate(['/login']);
    }

  }

  performSearch() {
    // Check if the search term is empty
    if (this.searchTerm === '') {
      // Reset the tableData to the original data
      this.refreshTableData();
    } else {
      // Filter the tableData array based on the search term
      const filteredData = this.tableData.filter((row: any) => {
        // Convert all values of the row to string and check if any value contains the search term
        return Object.values(row).some((value: any) => {
          return String(value).toLowerCase().includes(this.searchTerm.toLowerCase());
        });
      });

      // Assign the filtered data to the ngx-datatable rows
      this.tableData = filteredData;
    }

    // Reset the page to the first page
    if (this.table) {
      this.table.offset = 0;
    }

    // Recalculate and update the table
    this.table?.recalculate();
    this.table?.bodyComponent.updateOffsetY();
  }


  refreshTableData() {
    this._authService.getAllCompanies().subscribe(
      (data: any[]) => {
        // console.log("users data", data);
        if (data && data.length > 0) {
          const allUsers = data;
          //, customerId: number &&
          // item.customerId === this.currentCompanyId 
          if (allUsers.length > 0) {
            this.tableData = data.filter(
              (item: { roleName: string }) =>
                item.roleName !== 'SuperAdmin' &&
                item.roleName === 'CompanyAdmin'
            );

            // console.log("filter users data", this.tableData);
            this.tableColumns = Object.keys(data[0])
              .filter((key) =>
                key !== 'id' &&
                key !== 'customerId' &&
                key !== 'industryId' &&
                key !== 'roleId' &&
                key !== 'roleName' &&
                key !== 'isDeleted' &&
                key !== 'addressId' &&
                key !== 'city' &&
                key !== 'country' &&
                key !== 'password' &&
                key !== 'isActive'
              )
              .map((key) => {
                let header: string;
                let isCheckbox: boolean = false;

                switch (key) {
                  case 'companyName':
                    header = 'Company Name';
                    break;
                  case 'email':
                    header = 'Email';
                    break;
                  case 'roleName':
                    header = 'Role Name';
                    break;
                  case 'phone':
                    header = 'Phone';
                    break;
                  case 'industryName':
                    header = 'Industry Name';
                    break;
                  case 'isDeleted':
                    header = 'Is Deleted';
                    isCheckbox = true;
                    break;
                  default:
                    header = key; // Use the original key if no specific header is defined
                    break;
                }

                return { header, isCheckbox };
              });
          }
        }
      },
      (error: any) => {
        console.error("error getting users data", error);
      });
  }

  openAddModal(template: TemplateRef<any>) {

    this._modalTitle = "Add Company";
    this._saveTitle = "Create Company";
    this._company = new AddCompanyModel();
    this._companyName = '';
    this._companyEmail = null;
    this._companyPhone = '';
    this._industryId = 0;
    this.emailExists = false;

    //console.log("this company open add", this._company);
    this._modalService.open(template, { size: 'md' }).result.then(
      result => {
        this.closeResult = `Closed with: ${result}`;
        this._companyName = this._company.companyName;
        this._companyEmail = this._company.email;
        // this.validateEmailExists(this._company.email, false);
        // console.log("this company close add", this._company);

      },
      reason => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );

  }
  //template: TemplateRef<any>
  openEditModal(template: TemplateRef<any>) {
    this._modalTitle = "Update Company";
    this._saveTitle = "Update Company";
    this._modalFunction = "update";
    this.emailExists = false;
    // console.log("this company open edit", this._company);

    this._modalService.open(template, { size: 'md' }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
        this.validateEmailExists(this._companyEmail ?? '', true);
        // console.log("this company close edit ", this._company);
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );

  }

  previewClick(id: number, template: TemplateRef<any>) {
    // console.log("preview click id", id);

    this.getSingleUser(Number(id), template);
  }

  getSingleUser(id: number, template: TemplateRef<any>): void {

    const data = this.tableData.filter(item => item.id === id);
    this._company = data[0];
    // console.log('single company', this._company);

    if (data[0] && data[0] !== undefined) {
      const item = this._industryTableData.find((item: any) => item.title === data[0].industryName);
      // Check if the stringValueObj is found, and if so, get the "id" property
      this._industryId = item ? item.id : null;
      this._companyName = this._company.companyName;
      this._companyEmail = this._company.email;
      this._companyPhone = this._company.phone;
    }
    this.openEditModal(template);
    //this._previewClickCounter = 0;
  }

  async updateUserStatus(row: any, status: any, softDelete = false) {
    const userId = Number(row.id);
    let action: string;

    if (row.isActive) {
      status = false;
      action = 'deactivated';
    } else {
      status = true;
      action = 'activated';
    }

    if (softDelete) {
      let confirmed = this._appComponent.showConfirmationDialog(
        'Are you sure?',
        'You are about to delete this user',
        'Yes, delete it!',
      );

      if (await confirmed === false) {
        return;
      } else {
        action = 'deleted';
      }
    }

    this._authService.updateUserStatus(userId, status, softDelete).subscribe(
      (response: any) => {
        this._appComponent.showSuccessSwal(action, 'user')
        this.refreshTableData();
        this.table?.recalculate();
        this.table?.bodyComponent.updateOffsetY();

      },
      (error: any) => {
        // Handle any error that occurred during the update process
        console.error('Error updating user status:', error);
        this._appComponent.showErrorSwal('updating', 'user')
      }
    );
  }


  getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  isValidEmail(email: string): boolean {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }

  saveCompany() {

    if (this.emailExists === true) {
      // Handle the case when the email already exists in 'tableData'
      // You can show an error message or take appropriate action.
      // For example:
      // this.errorMessage = 'Email already exists.';
      return;
    }


    if (
      /*(!this._company.companyName || this._company.companyName.trim().length === 0) ||*/
      ((!this._company.email || this._company.email.trim().length === 0) || !this.isValidEmail(this._company.email.trim()))
    ) {
      // If companyName is empty or contains only whitespace, or
      // if email is empty or contains only whitespace, or
      // if email is not a valid email address, then the validation fails.
      // You may uncomment the following line for debugging purposes.
      // console.log('Validation failed.');
      return;
    }
    this._company.createdBy = this._currentUserId;
    this._company.industryId = this._industryId;
    //this._company.roleId =

    // console.log("saving company", this._company);
    // console.log("currentuser id ", this._currentUserId);
    this._company.companyName = this._companyName;
    if (this._company.id == 0) {
      this._authService.register(this._company).subscribe({
        next: response => {
          this._modalService.dismissAll('by: calling closeModal()');
          this._appComponent.showSuccessSwal('saved', 'company');
          this.refreshTableData();
          this.table?.recalculate();
          // console.log("saving company response", response);
          this.table?.bodyComponent.updateOffsetY();
        },
        error: error => {
          this._appComponent.showErrorSwal('saving', 'company');
          console.error("saving company response", error);

        }
      });
    }
    else {
      this._company.industryId = this._industryId;
      // console.log('company fields', this._company)

      this._authService.register(this._company).subscribe({
        next: response => {
          this._modalService.dismissAll('by: calling closeModal()');
          this._appComponent.showSuccessSwal('updated', 'company');
          this.refreshTableData();
          this.table?.recalculate();
          // console.log("saving company response", response);
          this.table?.bodyComponent.updateOffsetY();
        },
        error: error => {
          console.error("saving company response", error);
          this._appComponent.showErrorSwal('updating', 'company');
        }
      });
    }
  }

  resetPassword(id: any) {
    const data = this.tableData.filter(item => item.id === id)[0];
    this._company = data;
    this._company.password = "newpass";

    // console.log("reset password ", this._company);
    this._authService.register(this._company).subscribe(
      (response: any) => {
        this._appComponent.showSuccessSwal('reset password', '')
      },
      (error: any) => {
        // Handle any error that occurred during the update process
        console.error('Error resetting password:', error);
        this._appComponent.showErrorSwal('reset password', '')
      }
    );
  }

  closePopup() {
    this._modalService.dismissAll('by: calling closeModal()');
    //this._popupVisible = false;

  }

  parentFunction(): void {
    this._modalService.dismissAll('by: calling closeModal()');
    this.refreshContactList();
  }

  refreshContactList() {
    this._contactService.getContacts().subscribe(
      data => {
        this._contacts = data.filter(item => item.contactType === 0);
        this._companies = data.filter(item => item.contactType === 1);

      }
    );

  }


  //for form validation
  isValidPhoneNumber(value: string): boolean {
    return /[0-9+^$]/.test(value) && value.length >= 8 && value.length <= 15;
  }

  textIsValidLength(input: string): boolean {
    return input !== null && input !== undefined &&
      input.length >= 5 && input.length <= 35;
  }

  //for industries tab

  onChangeTab(tabNo: number) {
    //1 for companies
    //2 for industries
    //console.log("tab No", tabNo);
    if (tabNo === 1) {
      this.refreshTableData();
    } else if (tabNo == 2) {
      this.refreshIndustryTableData();
    }
  }

  openAddIndustry(template: TemplateRef<any>) {
    this._modalTitle = "Add Industry";
    this._saveTitle = "Create Industry";
    this.industryExists = false;
    this._industry = new IndustryModel();
    this._industryTitle = null;
    this._modalService.open(template, { size: 'md' }).result.then(
      (result) => {

        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;

      }
    );
  }

  openEditIndustry() {
    this._modalTitle = "Update Industry";
    this._saveTitle = "Update Industry";
    this._industryTitle = this._industry.title;
    this.industryExists = false;
    this._modalService.open(this.addIndustryModal, { size: 'md' }).result.then(
      (result) => {
        //this.basicModalCloseResult = "Modal closed" + result

      }).catch((res) => { });
  }

  saveIndustry() {
    if (this.industryExists === true || this._industryTitle === null) {
      // Handle the case when the industry already exists in 'tableData'
      // You can show an error message or take appropriate action.
      // For example:
      // this.errorMessage = 'Industry already exists.';
      return;
    }
    this._industry.title = this._industryTitle;

    if (this._industry.title.trim().length === 0) {
      return;
    }

    //console.log("save _industry", this._industry);
    if (this._industry.id == 0) {
      this._industry.createdBy = this._currentUserId;

      this._contactService.addIndustry(this._industry).subscribe(
        response => {
          this._modalService.dismissAll('by: calling closeModal()');
          this._appComponent.showSuccessSwal('saved', 'industry');
          this.refreshIndustryTableData();
          this.table?.recalculate();
          this.table?.bodyComponent.updateOffsetY();
        },
        error => {
          this._appComponent.showErrorSwal('saving', 'industry');
        }
      );
    }
    else {
      this._contactService.updateIndustry(this._industry).subscribe(
        response => {
          this._modalService.dismissAll('by: calling closeModal()');
          this._appComponent.showSuccessSwal('updated', 'industry');
          this.refreshIndustryTableData();
          this.table?.recalculate();
          this.table?.bodyComponent.updateOffsetY();
        },
        error => {
          this._appComponent.showErrorSwal('updating', 'industry');
        }
      )
    }
    this._industry = new IndustryModel();

  }

  refreshIndustryTableData() {
    this._contactService.getIndustries().subscribe(
      (data: any) => {
        // console.log("company industry data", data);
        if (data.length > 0) {
          this._industryTableData = data;

          this._industryTableColumns = Object.keys(data[0])
            .filter((key) =>
              key !== 'id' &&
              key !== 'creationDate' &&
              key !== 'deletionDate' &&
              key !== 'createdBy' &&
              key !== 'modificationDate' &&
              key !== 'modifiedBy' &&
              key !== 'isDeleted' &&
              key !== 'isActive')

            .map((key) => {
              let header: string;
              if (key == 'title') {
                header = 'Industry Name';
              } else {
                header = key;
              }
              return { header };
            });
        }
      },
      (error: any) => {
        console.error("error getting role data", error);
      });

  }

  async updateIndustryStatus(row: any, status: any, softDelete = false) {
    const industryId = Number(row.id);
    let action: string;

    if (row.isActive) {
      status = false;
      action = 'deactivated';
    } else {
      status = true;
      action = 'activated';
    }
    //console.log("industryId", industryId);

    if (softDelete) {
      let confirmed = this._appComponent.showConfirmationDialog(
        'Are you sure?',
        'You are about to delete this industry',
        'Yes, delete it!',
      );

      if (await confirmed === false) {
        return;
      } else {
        action = 'deleted';
      }
    }
    // console.log(`status ${status} and action${action}`);

    this._contactService.updateIndustryStatus(industryId, status, softDelete).subscribe(
      (response: any) => {
        this._appComponent.showSuccessSwal(action, 'industry')
        this.refreshIndustryTableData();
        this.table?.recalculate();
        this.table?.bodyComponent.updateOffsetY();

      },
      (error: any) => {
        // Handle any error that occurred during the update process
        console.error('Error updating industry status:', error);
        this._appComponent.showErrorSwal('updating', 'not successful')
      }
    );
  }

  industryPreviewClick(id: number) {
    // console.log("industryId id", id);
    this.getIndustryAndOpenModal(Number(id));
  }

  getIndustryAndOpenModal(id: number): void {
    this.industryExists = false;
    const data = this._industryTableData.filter(item => item.id === id)[0];
    this._industry = data;
    // console.log("_industry", this._industry);

    this.openEditIndustry();
  }

  async sendCreds(user: any) {
    let text: string = "Please note that sending these will also activate the user. Please confirm your action.";
    let confirmButtonText: string = "Yes!";

    if (user.isDeleted) {
      let confirmed = this._appComponent.showConfirmationDialog(
        'Are you sure?',
        text,
        confirmButtonText
      );

      if (await confirmed === true) {
        this.forgotpassword(user);
      } else {
        return;
      }
    }
    else {
      this.forgotpassword(user);
    }

  }

  forgotpassword(user: any) {
    if (user.email) {
      // console.log("email", this.email.toString()); // Log the email
      this._authService.forgotPassword(user.email.trim().toString(), false).subscribe({
        next: (response) => {
          // console.log("forgot password response", response);
          if (user.isDeleted === true) {
            this.updateUserStatus(user, false);
          }
          this._appComponent.showSuccessSwal('email', 'sent');

        },
        error: (error) => {
          this._appComponent.showErrorSwal('Oops! The email authentication', '');
        }
      });
    } else {
      console.error("Email is not provided");
    }
  }

  performIndustrySearch() {
    // Check if the search term is empty
    if (this._searchIndustry === '') {
      // Reset the tableData to the original data
      this.refreshIndustryTableData();
    } else {
      // Filter the tableData array based on the search term
      const filteredData = this._industryTableData.filter((row: any) => {
        // Convert all values of the row to string and check if any value contains the search term
        return Object.values(row).some((value: any) => {
          return String(value).toLowerCase().includes(this._searchIndustry.toLowerCase());
        });
      });

      // Assign the filtered data to the ngx-datatable rows
      this._industryTableData = filteredData;
    }

    // Reset the page to the first page
    if (this.table) {
      this.table.offset = 0;
    }

    // Recalculate and update the table
    this.table?.recalculate();
    this.table?.bodyComponent.updateOffsetY();
  }

  validatePhoneNumber(event: KeyboardEvent): void {
    const input = event.key;
    const isNumeric = /[0-9+^$]/.test(input);
    if (!isNumeric) {
      event.preventDefault();
    }
  }

  validateEmailExists(input: string, isEditMode: boolean): void {
    if (!input || input.trim().length === 0) {
      this.emailExists = false;
      return;
    }

    if (this.tableData && this.tableData.length > 0) {
      if (isEditMode) {
        this.emailExists = this.tableData.some(user => user.email === input && user.email !== this._company.email);
      } else {
        this.emailExists = this.tableData.some(user => user.email === input);
      }
    } else {
      this.emailExists = false;
    }
  }

  validateIndustryExists(): void {
    const input = this._industryTitle;
    if (!input || input.trim().length === 0) {
      this.industryExists = false;
      return;
    }

    if (this._industryTableData && this._industryTableData.length > 0) {
      this.industryExists = this._industryTableData.some(user => user.title === input && user.title !== this._industry.title);

    } else {
      this.industryExists = false;

    }
  }

  onRowMouseEnter(row: any) {
    row.isHovered = true;
  }

  onRowMouseLeave(row: any) {
    row.isHovered = false;
  }

  checkEmailExists(email: string | null) {
    if (!email) {
      return;
    }
    this._authService.checkEmailExists(email).subscribe({
      next: (response: boolean) => {
        this.emailExists = response;
        // console.log("checking email exists or not", response);
      },
      error: (error: any) => {
        console.error("error validating email", error);
      }
    })
  }
}
