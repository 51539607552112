import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service'; // Your authentication service

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (localStorage.getItem('isLoggedin')) {
      // logged in so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }


  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if (!Boolean(localStorage.getItem('isLoggedin'))) {
      // not logged in so redirect to login page with the return url
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
      return false;
    }

    // console.log("state: Router State Snapshot", state);
    // console.log("next: Activated Route snapshot ", next);


    let permissions: string[] = this.authService.getPermissionsFromLocalStorage();
    // console.log("permissions on authguard", permissions);

    let role = localStorage.getItem('role');
    // console.log("role ", role);

    // Create conditions for all these module names
    if (state.url.includes('/dashboard')) {
      // console.log("dashboard permission granted");
      return true;
    }

    else if (state.url.includes('/task-board')) {
      // console.log("dashboard permission granted");
      return true;
    }

    // super admin and company admin
    if (state.url.includes('/super-admin') && role === 'SuperAdmin') {
      // console.log("super admin  permission");
      return true;
    } else if (state.url.includes('/company-admin') || role === 'CompanyAdmin') {
      // console.log("company admin permission");
      return true;
    }

    // edit profile
    else if (state.url.includes('/edit-profile')) {
      // console.log("edit profile  permission");
      return true;
    }

    // sales 
    else if (state.url.includes('/contact') && permissions.some((p: string) => p.startsWith('Contacts')) || role === 'CompanyAdmin') {
      // console.log("crm contacts permission");
      return true;
    } else if (state.url.includes('/profile') && permissions.some((p: string) => p.startsWith('Contacts') || p.startsWith('Sales')) || role === 'CompanyAdmin') {
      // console.log("crm profile permission");
      return true;
    } else if (state.url.includes('/sales') && permissions.some((p: string) => p.startsWith('Sales')) || role === 'CompanyAdmin') {
      // console.log("crm sales permission");
      return true;
    }


    // Admin Settings
    else if (role === 'CompanyAdmin' && (state.url.includes('/company-setting') || state.url.includes('/user-permissions'))) {
      // console.log("Admin Settings permissions");
      return true;
    }
    else if (state.url.includes('/company-setting') && permissions.some((p: string) => p.startsWith('Admin Settings')) && role === 'CompanyAdmin') {
      // console.log("general-setting permission");
      return true;
    } else {
      // commented this for testing
      this.router.navigate(['/dashboard']);
      return false;
      // return true;
    }

  }



}


// const permissions = decodedToken.permissions.split(','); // Assuming permissions are comma-separated
// console.log("permissions ", permissions);

// Check if the user has the necessary permissions for the operation
//if (next& next.data.permissions.every(permission => permissions.includes(`${next.data.module}.${permission}`))) {
//  return true;
//} else {
//  this.router.navigate(['/unauthorized']); // Redirect to unauthorized page
//  return false;
//}
