import { MenuItem } from './menu.model';

export function getMenuItems(): MenuItem[] {
  let localStoragePermissions = localStorage.getItem('permissions');
  if (localStoragePermissions) {
    let permissions: string[] = JSON.parse(localStoragePermissions);

    let role = localStorage.getItem('role');

    return [
      {
        label: 'Main',
        isTitle: true
      },
      {
        label: 'Dashboard',
        icon: 'dashboard-icon',
        link: '/dashboard',
        show: true,
      },
      {
        label: 'Tasks',
        icon: 'tasks-icon',
        link: '/task-board',
        show: true
      },

      {
        label: 'CRM',
        isTitle: true,
        show: permissions?.includes("Contacts") || permissions?.includes("Sales") || role === 'CompanyAdmin'
      },
      {
        label: 'Contacts',
        icon: 'contacts-icon',
        link: '/contact//',
        show: permissions.includes("Contacts.Read") || role === 'CompanyAdmin'
      },
      {
        label: 'Sales',
        icon: 'bar-chart-2',
        link: '/sales',
        show: permissions.includes("Sales.Read") || role === 'CompanyAdmin'
      },

      {
        label: 'Super Admin',
        icon: 'admin-icon',
        link: '/super-admin',
        show: role === 'SuperAdmin'
      },

      {
        label: 'Admin',
        icon: 'admin-icon',
        show: role === 'CompanyAdmin',
        subItems: [
          {
            label: 'Company Settings',
            icon: 'settings',
            link: '/company-setting',
            // show: role === 'CompanyAdmin'
          },
          {
            label: 'User Role Access',
            icon: 'admin-icon',
            link: '/company-admin',
            show: permissions.includes("Company Admin.Read") || role === 'CompanyAdmin'
          }
        ]
      }
    ];
  }
  else {
    return [];
  }
}




// earlier menu
// export const MENU: MenuItem[] = [
//   {
//     label: 'Main',
//     isTitle: true
//   },
//   {
//     label: 'Dashboard',
//     icon: 'dashboard-icon',
//     link: '/dashboard'
//   },
//   {
//     label: 'CRM',
//     isTitle: true
//   },
//   {
//     label: 'Contacts',
//     icon: 'contacts-icon',
//     link: '/contact//'
//   },
//   {
//     label: 'Sales',
//     icon: 'bar-chart-2',
//     link: '/sales',
//   },
//   {
//     label: 'Super Admin',
//     icon: 'admin-icon',
//     link: '/super-admin'
//   },
//   {
//     label: 'Company Admin',
//     icon: 'admin-icon',
//     link: '/company-admin'
//   }, {
//     label: 'Movd',
//     isTitle: true
//   }, {
//     label: 'Leads',
//     icon: 'lead',
//     link: '/leads-board'
//   },
//   {
//     label: 'Moves',
//     icon: 'survey',
//     link: '/moves-board'
//   },
//   {
//     label: 'Survey Dashboard',
//     icon: 'dashboard-icon',
//     link: '/survey-dashboard'
//   },
//   {
//     label: 'Survey List',
//     icon: 'survey-list-icon',
//     link: '/survey-list'
//   },
//   {
//     label: 'Packing List',
//     icon: 'packing',
//     link: '/packing-list'
//   },
//   {
//     label: 'Delivery List',
//     icon: 'delivery-list-icon',
//     link: '/delivery-list'
//   },
//   {
//     label: 'Admin',
//     icon: 'admin-icon',
//     subItems: [
//       {
//         label: 'General',
//         icon: 'settings',
//         link: '/general-setting'
//       },
//       {
//         label: 'Inventory',
//         icon: 'inventory',
//         link: '/inventory-setting'
//       },
//       {
//         label: 'Template',
//         icon: 'template',
//         link: '/template-setting'
//       },
//       {
//         label: 'Documents',
//         icon: 'template',
//         link: '/documents-setting'
//       },
//       {
//         label: 'Status Settings',
//         icon: 'status',
//         link: '/status-setting'
//       },
//       {
//         label: 'Survey',
//         icon: 'survey',
//         link: '/survey-setting'
//       },
//       {
//         label: 'User Permission',
//         icon: 'admin-icon',
//         link: '/user-permissions'
//       }
//     ]
//   }
// ];
