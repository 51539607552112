<div class="d-flex justify-content-between align-items-center flex-wrap grid-margin">
  <div>
    <h4 class="mb-3 mb-md-0">Welcome {{ getNameFromLocalStorage() }}</h4>
  </div>
  <div class="d-flex align-items-center flex-wrap text-nowrap">
    <!--<div class="input-group wd-200 me-2 mb-2 mb-md-0">
      <button class="btn btn-outline-primary btn-icon calendar" type="button" (click)="d.toggle()">
        <i data-feather="calendar" appFeatherIcon></i>
      </button>
      <input class="form-control border-primary bg-transparent" placeholder="yyyy-mm-dd"
          name="dp" [(ngModel)]="currentDate" ngbDatepicker #d="ngbDatepicker">
    </div>
    <button type="button" class="btn btn-outline-primary btn-icon-text me-2 mb-2 mb-md-0">
      <i class="btn-icon-prepend" data-feather="printer" appFeatherIcon></i>
      Print
    </button>
    <button type="button" class="btn btn-primary btn-icon-text mb-2 mb-md-0">
      <i class="btn-icon-prepend" data-feather="download-cloud" appFeatherIcon></i>
      Download Report
    </button>-->
  </div>
</div>


<ng-container *ngIf="_isLoading">
  <span id="loader">
    <span class="spinner"></span>
  </span>
</ng-container>

<ng-container *ngIf="!_isLoading">
  <div class="row" *ngIf="isDataLoaded">
    <div class="col-12 col-xl-12 stretch-card">
      <div class="row flex-grow-1">
        <div class="col-md-4 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-between align-items-baseline">
                <h6 class="card-title mb-0">Customers</h6>
                <!--<div ngbDropdown class="mb-2">
                  <button ngbDropdownToggle class="btn p-0 no-dropdown-toggle-icon" type="button" id="dropdownMenuButton">
                    <i class="icon-lg text-muted pb-3px" data-feather="more-horizontal" appFeatherIcon></i>
                  </button>
                  <div ngbDropdownMenu aria-labelledby="dropdownMenuButton">
                    <a ngbDropdownItem class="d-flex align-items-center" href="" (click)="false"><i data-feather="eye" appFeatherIcon class="icon-sm me-2"></i> <span class="">View</span></a>
                    <a ngbDropdownItem class="d-flex align-items-center" href="" (click)="false"><i data-feather="edit-2" appFeatherIcon class="icon-sm me-2"></i> <span class="">Edit</span></a>
                    <a ngbDropdownItem class="d-flex align-items-center" href="" (click)="false"><i data-feather="trash" appFeatherIcon class="icon-sm me-2"></i> <span class="">Delete</span></a>
                    <a ngbDropdownItem class="d-flex align-items-center" href="" (click)="false"><i data-feather="printer" appFeatherIcon class="icon-sm me-2"></i> <span class="">Print</span></a>
                    <a ngbDropdownItem class="d-flex align-items-center" href="" (click)="false"><i data-feather="download" appFeatherIcon class="icon-sm me-2"></i> <span class="">Download</span></a>
                  </div>
                </div>-->
              </div>
              <div class="row" *ngIf="_customerStats && _customerStats.length > 0">
                <div class="col-6 col-md-12 col-xl-5">
                  <h3 class="mb-2">{{_customerStats[0].totalCustomers}}</h3>
                  <div class="d-flex align-items-baseline">
                    <p class="text-success">
                      <span>{{_customerStats[0].changeOverPreviousWeek}}</span>
                      <i data-feather="arrow-up" appFeatherIcon class="icon-sm mb-1"></i>
                    </p>
                  </div>
                </div>
                <div class="col-6 col-md-12 col-xl-7">
                  <div class="mt-md-3 mt-xl-0">
                    <apx-chart [series]="customersChartOptions.series" [chart]="customersChartOptions.chart"
                      [colors]="customersChartOptions.colors" [xaxis]="customersChartOptions.xaxis"
                      [stroke]="customersChartOptions.stroke" [markers]="customersChartOptions.markers"></apx-chart>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-between align-items-baseline">
                <h6 class="card-title mb-0">Deals</h6>
                <!--<div ngbDropdown class="mb-2">
                  <button ngbDropdownToggle class="btn p-0 no-dropdown-toggle-icon" type="button" id="dropdownMenuButton1">
                    <i class="icon-lg text-muted pb-3px" data-feather="more-horizontal" appFeatherIcon></i>
                  </button>
                  <div ngbDropdownMenu aria-labelledby="dropdownMenuButton1">
                    <a ngbDropdownItem class="d-flex align-items-center" href="" (click)="false"><i data-feather="eye" appFeatherIcon class="icon-sm me-2"></i> <span class="">View</span></a>
                    <a ngbDropdownItem class="d-flex align-items-center" href="" (click)="false"><i data-feather="edit-2" appFeatherIcon class="icon-sm me-2"></i> <span class="">Edit</span></a>
                    <a ngbDropdownItem class="d-flex align-items-center" href="" (click)="false"><i data-feather="trash" appFeatherIcon class="icon-sm me-2"></i> <span class="">Delete</span></a>
                    <a ngbDropdownItem class="d-flex align-items-center" href="" (click)="false"><i data-feather="printer" appFeatherIcon class="icon-sm me-2"></i> <span class="">Print</span></a>
                    <a ngbDropdownItem class="d-flex align-items-center" href="" (click)="false"><i data-feather="download" appFeatherIcon class="icon-sm me-2"></i> <span class="">Download</span></a>
                  </div>
                </div>-->
              </div>
              <div class="row" *ngIf="_dealsStats && _dealsStats.length > 0">
                <div class="col-6 col-md-12 col-xl-5">
                  <h3 class="mb-2">{{_dealsStats[0].totalDeals}}</h3>
                  <div class="d-flex align-items-baseline">
                    <p class="text-success">
                      <span>{{_dealsStats[0].changeOverPreviousWeekFormatted}}%</span>
                      <i data-feather="arrow-up" appFeatherIcon class="icon-sm mb-1"></i>
                    </p>
                  </div>
                </div>
                <div class="col-6 col-md-12 col-xl-7">
                  <div class="mt-md-3 mt-xl-0">
                    <apx-chart [series]="ordersChartOptions.series" [chart]="ordersChartOptions.chart"
                      [colors]="ordersChartOptions.colors" [plotOptions]="ordersChartOptions.plotOptions"
                      [xaxis]="ordersChartOptions.xaxis"></apx-chart>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-between align-items-baseline">
                <h6 class="card-title mb-0">Activities</h6>
                <!--<div ngbDropdown class="mb-2">
                  <button ngbDropdownToggle class="btn p-0 no-dropdown-toggle-icon" type="button" id="dropdownMenuButton2">
                    <i class="icon-lg text-muted pb-3px" data-feather="more-horizontal" appFeatherIcon></i>
                  </button>
                  <div ngbDropdownMenu aria-labelledby="dropdownMenuButton2">
                    <a ngbDropdownItem class="d-flex align-items-center" href="" (click)="false"><i data-feather="eye" appFeatherIcon class="icon-sm me-2"></i> <span class="">View</span></a>
                    <a ngbDropdownItem class="d-flex align-items-center" href="" (click)="false"><i data-feather="edit-2" appFeatherIcon class="icon-sm me-2"></i> <span class="">Edit</span></a>
                    <a ngbDropdownItem class="d-flex align-items-center" href="" (click)="false"><i data-feather="trash" appFeatherIcon class="icon-sm me-2"></i> <span class="">Delete</span></a>
                    <a ngbDropdownItem class="d-flex align-items-center" href="" (click)="false"><i data-feather="printer" appFeatherIcon class="icon-sm me-2"></i> <span class="">Print</span></a>
                    <a ngbDropdownItem class="d-flex align-items-center" href="" (click)="false"><i data-feather="download" appFeatherIcon class="icon-sm me-2"></i> <span class="">Download</span></a>
                  </div>
                </div>-->
              </div>
              <div class="row">
                <div class="col-6 col-md-12 col-xl-5">
                  <h3 class="mb-2">{{_totalActivites}}</h3>
                  <div class="d-flex align-items-baseline">
                    <p class="text-success">
                      <!--<span>+2.8%</span>-->
                      <!--<i data-feather="arrow-up" appFeatherIcon class="icon-sm mb-1"></i>-->
                    </p>
                  </div>
                </div>
                <div class="col-6 col-md-12 col-xl-7">
                  <div class="mt-md-3 mt-xl-0">
                    <apx-chart [series]="growthChartOptions.series" [chart]="growthChartOptions.chart"
                      [colors]="growthChartOptions.colors" [xaxis]="growthChartOptions.xaxis"
                      [stroke]="growthChartOptions.stroke" [markers]="growthChartOptions.markers"></apx-chart>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> <!-- row -->

  <!--<div class="row">
    <div class="col-12 col-xl-12 grid-margin stretch-card">
      <div class="card overflow-hidden">
        <div class="card-body">
          <div class="d-flex justify-content-between align-items-baseline mb-4 mb-md-3">
            <h6 class="card-title mb-0">Revenue</h6>
            <div ngbDropdown>
              <button ngbDropdownToggle class="btn p-0 no-dropdown-toggle-icon" type="button" id="dropdownMenuButton3">
                <i class="icon-lg text-muted pb-3px" data-feather="more-horizontal" appFeatherIcon></i>
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownMenuButton3">
                <a ngbDropdownItem class="d-flex align-items-center" href="" (click)="false"><i data-feather="eye" appFeatherIcon class="icon-sm me-2"></i> <span class="">View</span></a>
                <a ngbDropdownItem class="d-flex align-items-center" href="" (click)="false"><i data-feather="edit-2" appFeatherIcon class="icon-sm me-2"></i> <span class="">Edit</span></a>
                <a ngbDropdownItem class="d-flex align-items-center" href="" (click)="false"><i data-feather="trash" appFeatherIcon class="icon-sm me-2"></i> <span class="">Delete</span></a>
                <a ngbDropdownItem class="d-flex align-items-center" href="" (click)="false"><i data-feather="printer" appFeatherIcon class="icon-sm me-2"></i> <span class="">Print</span></a>
                <a ngbDropdownItem class="d-flex align-items-center" href="" (click)="false"><i data-feather="download" appFeatherIcon class="icon-sm me-2"></i> <span class="">Download</span></a>
              </div>
            </div>
          </div>
          <div class="row align-items-start">
            <div class="col-md-7">
              <p class="text-muted tx-13 mb-3 mb-md-0">Revenue is the income that a business has from its normal business activities, usually from the sale of goods and services to customers.</p>
            </div>
            <div class="col-md-5 d-flex justify-content-md-end">
              <div class="btn-group mb-3 mb-md-0" role="group" aria-label="Basic example">
                <button type="button" class="btn btn-outline-primary">Today</button>
                <button type="button" class="btn btn-outline-primary d-none d-md-block">Week</button>
                <button type="button" class="btn btn-primary">Month</button>
                <button type="button" class="btn btn-outline-primary">Year</button>
              </div>
            </div>
          </div>
          <div class="flot-wrapper">
            <apx-chart [series]="revenueChartOptions.series"
                       [chart]="revenueChartOptions.chart"
                       [colors]="revenueChartOptions.colors"
                       [grid]="revenueChartOptions.grid"
                       [xaxis]="revenueChartOptions.xaxis"
                       [yaxis]="revenueChartOptions.yaxis"
                       [markers]="revenueChartOptions.markers"
                       [stroke]="revenueChartOptions.stroke"></apx-chart>
          </div>
        </div>
      </div>
    </div>
  </div>--> <!-- row -->

  <div class="row">
    <div class="col-lg-7 col-xl-8 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between align-items-baseline mb-2">
            <h6 class="card-title mb-0">Monthly Deals</h6>
            <div ngbDropdown class="mb-2">
              <!--<button ngbDropdownToggle class="btn p-0 no-dropdown-toggle-icon" type="button" id="dropdownMenuButton4">
                <i class="icon-lg text-muted pb-3px" data-feather="more-horizontal" appFeatherIcon></i>
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownMenuButton4">
                <a ngbDropdownItem class="d-flex align-items-center" href="" (click)="false"><i data-feather="eye" appFeatherIcon class="icon-sm me-2"></i> <span class="">View</span></a>
                <a ngbDropdownItem class="d-flex align-items-center" href="" (click)="false"><i data-feather="edit-2" appFeatherIcon class="icon-sm me-2"></i> <span class="">Edit</span></a>
                <a ngbDropdownItem class="d-flex align-items-center" href="" (click)="false"><i data-feather="trash" appFeatherIcon class="icon-sm me-2"></i> <span class="">Delete</span></a>
                <a ngbDropdownItem class="d-flex align-items-center" href="" (click)="false"><i data-feather="printer" appFeatherIcon class="icon-sm me-2"></i> <span class="">Print</span></a>
                <a ngbDropdownItem class="d-flex align-items-center" href="" (click)="false"><i data-feather="download" appFeatherIcon class="icon-sm me-2"></i> <span class="">Download</span></a>
              </div>-->
            </div>
          </div>
          <p class="text-muted">Deals are activities related to selling or the number of goods or
            services sold in a given time period.</p>

          <apx-chart [series]="monthlySalesChartOptions.series" [chart]="monthlySalesChartOptions.chart"
            [colors]="monthlySalesChartOptions.colors" [fill]="monthlySalesChartOptions.fill"
            [grid]="monthlySalesChartOptions.grid" [xaxis]="monthlySalesChartOptions.xaxis"
            [yaxis]="monthlySalesChartOptions.yaxis" [legend]="monthlySalesChartOptions.legend"
            [stroke]="monthlySalesChartOptions.stroke" [dataLabels]="monthlySalesChartOptions.dataLabels"
            [plotOptions]="monthlySalesChartOptions.plotOptions"></apx-chart>

        </div>
      </div>
    </div>
    <div class="col-lg-5 col-xl-4 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between align-items-baseline mb-2">
            <h6 class="card-title">Latest Tasks</h6>
            <div ngbDropdown class="mb-2">
             
            </div>
          </div>
          <!--*ngIf="_tasksList.length === 0"-->
          <!-- <div class="row mb-3" *ngIf="_tasksList.length === 0">
            <p>No Tasks Currently</p>
          </div> -->
          <!-- <div class="row mb-3" *ngIf="_tasksList && _tasksList.length > 0" style="max-height: 400px; overflow-y: auto;">
            <ng-container *ngFor="let dataItem of _tasksList" >
              <a href="" (click)="false" class="d-flex align-items-center border-bottom py-3" >
                <div class="me-3">
                  <div class="title-icon img-xs rounded-circle">{{ dataItem.title.charAt(0) }}</div>
                  <img src="https://via.placeholder.com/35x35" class="rounded-circle wd-35" alt="user">
                </div>
                <div class="w-100">
                  <div class="d-flex justify-content-between">
                    <h6 class="text-body mb-2">{{ dataItem.title }}</h6>
                    <p class="tx-11 text-muted">{{dataItem.dueDate | date:'MMM d, yyyy \'at\' h:mm
                      a' }}</p>
                    <p class="text-muted tx-12">{{ dataItem.dealCount }} Deals</p>
                  </div>
                   <p class="text-muted tx-13">Additional information or message here...</p>
                </div>
              </a>
            </ng-container>
          </div> -->
          <app-task [customerId]="_localCustomerId" [isDeal]="false" type="dashboard"></app-task>
        </div>
      </div>
    </div>
  </div> <!-- row -->

  <div class="row">
    <div class="col-lg-5 col-xl-4 grid-margin grid-margin-xl-0 stretch-card">
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between align-items-baseline mb-2">
            <h6 class="card-title mb-0">Top Sales</h6>
            <!--<div ngbDropdown class="mb-2">
              <button ngbDropdownToggle class="btn p-0 no-dropdown-toggle-icon" type="button" id="dropdownMenuButton6">
                <i class="icon-lg text-muted pb-3px" data-feather="more-horizontal" appFeatherIcon></i>
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownMenuButton6">
                <a ngbDropdownItem class="d-flex align-items-center" href="" (click)="false"><i data-feather="eye" appFeatherIcon class="icon-sm me-2"></i> <span class="">View</span></a>
                <a ngbDropdownItem class="d-flex align-items-center" href="" (click)="false"><i data-feather="edit-2" appFeatherIcon class="icon-sm me-2"></i> <span class="">Edit</span></a>
                <a ngbDropdownItem class="d-flex align-items-center" href="" (click)="false"><i data-feather="trash" appFeatherIcon class="icon-sm me-2"></i> <span class="">Delete</span></a>
                <a ngbDropdownItem class="d-flex align-items-center" href="" (click)="false"><i data-feather="printer" appFeatherIcon class="icon-sm me-2"></i> <span class="">Print</span></a>
                <a ngbDropdownItem class="d-flex align-items-center" href="" (click)="false"><i data-feather="download" appFeatherIcon class="icon-sm me-2"></i> <span class="">Download</span></a>
              </div>
            </div>-->
          </div>
          <div class="d-flex flex-column">
            <ng-container *ngIf="_topSalesList.length > 0">
              <ng-container *ngFor="let rep of _topSalesList">
                <span class="d-flex align-items-center border-bottom py-3">
                  <div class="me-3">
                    <div class="title-icon img-xs rounded-circle">
                      {{ rep.dealOwnerName.charAt(0) }}
                    </div>
                  </div>
                  <div class="w-100">
                    <a (click)="redirectToProfile(rep)">
                      <div class="d-flex justify-content-between">
                        <h6 class="text-body mb-2">{{ rep.dealOwnerName }}</h6>
                        <p class="text-muted tx-12">{{ rep.dealCount }} Deals</p>
                      </div>
                    </a>
                  </div>
                </span>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="_topSalesList.length === 0">
              <p> No Sales Currently</p>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-7 col-xl-8 stretch-card">
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between align-items-baseline mb-2">
            <h6 class="card-title mb-0">Latest Sales Activities</h6>
            <!--<div ngbDropdown class="mb-2">
              <button ngbDropdownToggle class="btn p-0 no-dropdown-toggle-icon" type="button" id="dropdownMenuButton7">
                <i class="icon-lg text-muted pb-3px" data-feather="more-horizontal" appFeatherIcon></i>
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownMenuButton7">
                <a ngbDropdownItem class="d-flex align-items-center" href="" (click)="false"><i data-feather="eye" appFeatherIcon class="icon-sm me-2"></i> <span class="">View</span></a>
                <a ngbDropdownItem class="d-flex align-items-center" href="" (click)="false"><i data-feather="edit-2" appFeatherIcon class="icon-sm me-2"></i> <span class="">Edit</span></a>
                <a ngbDropdownItem class="d-flex align-items-center" href="" (click)="false"><i data-feather="trash" appFeatherIcon class="icon-sm me-2"></i> <span class="">Delete</span></a>
                <a ngbDropdownItem class="d-flex align-items-center" href="" (click)="false"><i data-feather="printer" appFeatherIcon class="icon-sm me-2"></i> <span class="">Print</span></a>
                <a ngbDropdownItem class="d-flex align-items-center" href="" (click)="false"><i data-feather="download" appFeatherIcon class="icon-sm me-2"></i> <span class="">Download</span></a>
              </div>
            </div>-->
          </div>
          <div class="table-responsive">
            <table class="table table-hover mb-0">
              <thead>
                <tr>
                  <th class="pt-0">#</th>
                  <th class="pt-0">Deal Name</th>
                  <th class="pt-0">Date</th>
                  <th class="pt-0">Amount</th>
                  <th class="pt-0">Deal Owner</th>
                  <th class="pt-0">Status</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let deal of _dealsList; let i = index">
                  <tr>
                    <td>{{ i + 1 }}</td>
                    <td>{{ deal.name }}</td>
                    <td>{{ deal.creationDate | date: 'dd/MM/yyyy' }}</td>
                    <td>{{ deal.amount }}</td>
                    <td>{{ deal.dealOwner }}</td>
                    <td>
                      <!-- <span [ngClass]="{'badge bg-danger': deal.Status === 'Released', 'badge bg-success': deal.Status === 'Approved', 'badge bg-info': deal.Status === 'Pending'}">-->
                      {{ deal.pipeName }}
                      <!--</span>-->
                    </td>
                  </tr>
                </ng-container>

              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>