export enum AuthServicePaths {
  register = '/Auth/Register',
  login = '/Auth/Login',
  getAllCompanies = '/Auth/GetAllCompanies',
  getUsersOfCompany = '/Auth/getUsersOfCompany',
  getAllCompaniesPages = '/Auth/GetAllCompaniesPages',
  updateUserStatus = '/Auth/UpdateUserStatus/{id}/status',
  savePermission = '/Auth/SavePermission',
  getAllPermissions = '/Auth/GetAllPermissions',
  saveRolePermission = '/Auth/SaveRolePermission',
}
