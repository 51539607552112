<ng-template #tasksModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="tasksModalLabel">
      <div class="TitleIcon">
        <lord-icon class="solid_icons" src="https://cdn.lordicon.com/winbdcbm.json" trigger="hover"
          colors="primary:#5f606c"></lord-icon>
        <span class="TitleText">Task</span>
      </div>
    </h5>
  </div>
  <div class="modal-body">
    <div class="row">
      <input type="hidden" id="_task.id" />
      <div class="col-md-12">
        <input type="text" class="form-control no-border" [(ngModel)]="_task.title" id="_task.title" autocomplete="on"
          value="{{_task.title}}" placeholder="Enter your task" required #taskTitle="ngModel" />
        <div *ngIf="taskTitle.invalid && (taskTitle.dirty || taskTitle.touched)" class="error-message error"
          style="color: red;">
          <div *ngIf="taskTitle.errors?.['required']"> * required.</div>
        </div>
      </div>
    </div>
    &nbsp;
    <div class="row">
      <div class="col-md-6">
        <label for="_task.dueDate" class="form-label no-border">Due Date</label>
        <input type="date" class="form-control" [(ngModel)]="_task.dueDate" id="_task.dueDate" autocomplete="on"
          required />
      </div>
      <div class="col-md-6">
        <label for="_task.assignedto" class="form-label no-border">Assigned To</label>
        <ng-select [items]="_companyUsers" bindLabel="customerName" bindValue="id" [searchable]="true"
          [(ngModel)]="_task.assignedto" placeholder="Select Contact" [clearable]="true" required (change)="onUserSelect($event)">
        </ng-select>
      </div>

    </div>
    &nbsp;
    <div class="row">
      <div class="col-md-4">
        <label for="_task.type" class="input-form-labels">Type</label>
        <ng-select class="left-align input-color" [(ngModel)]="_task.type" id="_task.type">
          <ng-option value="To-do" selected>To-do</ng-option>
          <ng-option value="Call">Call</ng-option>
          <ng-option value="Email">Email</ng-option>
        </ng-select>
      </div>
      <div class="col-md-4">
        <label for="_task.priority" class="input-form-labels">Priority</label>
        <ng-select class="left-align input-color" [(ngModel)]="_task.priority" id="_task.priority">
          <ng-option value="None" selected>None</ng-option>
          <ng-option value="Low">Low</ng-option>
          <ng-option value="Medium">Medium</ng-option>
          <ng-option value="High">High</ng-option>
        </ng-select>
      </div>
      <div class="col-md-4">
        <ng-container *ngIf="_taskUpdate">
          <label for="_task.label" class="input-form-labels">Mark as Done</label>
          <ng-select class="left-align input-color" [(ngModel)]="_task.done" [bindLabel]="_task.done ? 'Done' : 'None'"
            (ngModelChange)="toggleTaskDone(_task)" id="_task.done">
            <ng-option [value]="true">Done</ng-option>
            <ng-option [value]="false">None</ng-option>
          </ng-select>

          <span class="checkmark" [ngClass]="{ 'task-done': _task.done, 'overdue': isOverdue(_task) }"></span>
        </ng-container>
      </div>
    </div>
    &nbsp;
    <div class="row">
      <div class="col-md-12">
        <textarea class="form-control no-border" [(ngModel)]="_task.details" d="_task.details" autocomplete="on"
          value="{{_task.details}}" placeholder="Notes..." required></textarea>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary btn-sm" (click)="saveTask()">
      <i class="mdi mdi-content-save"> </i> &nbsp;{{_saveTaskTitle}}
    </button>
    <button type="button" class="btn btn-danger btn-sm" (click)="modal.close('by: close button')">
      <i class="mdi mdi-close"> </i>&nbsp;Close
    </button>
  </div>
</ng-template>

<ng-template #taskListTemplate>
  <div class="todo-task">
    <ngx-datatable [rows]="_filteredTasksList" class="bootstrap custom-datatable table-bordered table-responsive"
      [columnMode]="'force'" [summaryRow]="false" [footerHeight]="40" [limit]="10" [pagination]="true" [pageSize]="10"
      [loadingIndicator]="loadingIndicator" [rowHeight]="40" [scrollbarH]="true">

      <!-- <ngx-datatable-column name="Title">
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row.title }}
        </ng-template>
      </ngx-datatable-column> -->

      <ngx-datatable-column name="Title">
        <ng-template let-row="row" ngx-datatable-cell-template>
          <div class="d-flex align-items-center hover" (mouseenter)="onRowMouseEnter(row)"
            (mouseleave)="onRowMouseLeave(row)">
            <div class="customer-name-wrapper">
              <div class="customer-name profileClick" [ngbPopover]="row.title" triggers="hover" placement="top"
                container="body" [ngClass]="{ 'preview-active': row.showPreview }" [attr.data-id]="row.id.toString()">
                {{row.title}}
              </div>
            </div>



            <button
              class="btn btn-secondary button-wrapper btn-icon-text previewClick btn-small-transparent btn-smaller"
              *ngIf="row.isHovered" [attr.data-id]="row.id" (click)="openTasksModal(tasksModal, row.id, row)">
              Preview
            </button>
          </div>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="Activity Date">
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row.activityDate | date : "MMM d, yyyy 'at' h:mm a" }}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="Status">
        <ng-template let-row="row" ngx-datatable-cell-template>
          <span *ngIf="row.done; else notDone" class="badge bg-success">Done</span>
          <ng-template #notDone>
            <span *ngIf="row.dueDate && isOverdue(row)" class="badge bg-danger">Over Due</span>
            <span *ngIf="!row.dueDate || (!isOverdue(row) && !row.done)" class="badge bg-info">Pending</span>
          </ng-template>
        </ng-template>
      </ngx-datatable-column>


      <ngx-datatable-column name="Due Date">
        <ng-template let-row="row" ngx-datatable-cell-template>
          <span *ngIf="row.dueDate; else dashes">{{ row.dueDate | date : "MMM d, yyyy" }}</span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="Priority">
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row.priority || ' - ' }}
        </ng-template>
      </ngx-datatable-column>

    </ngx-datatable>
  </div>
</ng-template>

<ng-template #dashes>-</ng-template>

<div class="row mt-1">
  <div class="col-md-2">
    <h3>My Tasks</h3>
  </div>
  <div class="col-md-10 text-end">
  </div>
</div>

<div class="row mt-1">
  <div class="col-md-2"></div>
  <div class="col-md-10 text-end">
    <button class="btn btn-xs btn-primary btn-icon-text" style="margin-left:2px"
      (click)="openTasksModal(tasksModal, 0, null);">
      <i class="mdi mdi-account-plus"> </i>
      &nbsp; Add Task
    </button>
  </div>
</div>
<div class="row mt-1">
  <ul ngbNav #defaultNav="ngbNav" class="nav-tabs sub-tabs" role="tablist">
    <li [ngbNavItem]="1" (click)="onTabChange('to-do'); fetchData('task')">
      <a ngbNavLink class="nav-tab sub-tabs">TO-DO</a>
      <ng-template ngbNavContent>
        <ng-template [ngTemplateOutlet]="taskListTemplate"></ng-template>
      </ng-template>
    </li>

    <li [ngbNavItem]="2" (click)="onTabChange('done'); fetchData('task')">
      <a ngbNavLink class="nav-tab sub-tabs">DONE</a>
      <ng-template ngbNavContent>
        <ng-template [ngTemplateOutlet]="taskListTemplate"></ng-template>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="defaultNav"></div>
</div>