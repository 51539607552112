export class OptionModel {
  id: number;
  value: string;

  constructor() {
    this.id = 0;
    this.value = '';
  }
}

// export const _contactColumns: string[] = ["NAME", "PHONE", "EMAIL", "CITY", "COUNTRY", "ACCOUNT", "POSITION", "TYPE"];
export const _contactColumns: string[] = ["NAME", "PHONE", "EMAIL", "CITY", "COUNTRY", "ACCOUNT", "POSITION"];
export const _compColumns: string[] = ["NAME", "PHONE", "EMAIL", "CITY", "COUNTRY", "OWNER"];
export const _dealsColumns: string[] = ["Deal", "Contact Name", "Created On", "Deal Owner", "Amount", "Probability", "Sales Rep", "Last Contact"];


export class Customer {
  id: number = 0;
  customerName: string | null;
  phone: string | null;
  companyId: number | null;
  role: string | null;
  industryId: number | null;
  compOwnerId: number | null;
  email: string | null;
  fax: string | null;
  dateOfBirth: Date | null;
  details: string | null;
  contactType: number | null;
  salesType: number | null;
  companyURL: string | null;
  linkedInURL: string | null;
  noOfEmployees: string | null;
  openPositions: number | null;
  ownedBy: number | null;
  createdBy: number | null;

  constructor() {
    this.id = 0;
    this.customerName = null;
    this.phone = null;
    this.companyId = null;
    this.role = null;
    this.industryId = null;
    this.compOwnerId = null;
    this.email = null;
    this.fax = null;
    this.dateOfBirth = null;
    this.details = null;
    this.contactType = null;
    this.salesType = null;
    this.companyURL = null;
    this.linkedInURL = null;
    this.noOfEmployees = null;
    this.openPositions = null;
    this.ownedBy = null;
    this.createdBy = null;
  }
}


export class Address {
  id: number;
  customerId: number | null;
  phoneNo: string | null;
  email: string | null;
  fax: string | null;
  streetAddress: string | null;
  zipCode: string | null;
  city: string | null;
  state: string | null;
  country: string | null;
  

  constructor() {
    this.id = 0;
    this.customerId = null;
    this.phoneNo = null;
    this.email = null;
    this.fax = null;
    this.streetAddress = null;
    this.zipCode = '';
    this.city = null;
    this.state = null;
    this.country = null;
  }
}

