import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { UserProfileDTO } from 'src/app/views/edit-profile/edit-profile.model';
import { NotificationModel } from 'src/app/views/MOVD/models/notification.model';

@Injectable({
  providedIn: 'root'
})

export class NotificationsService {
  readonly APIUrl = environment.baseUrl;
  constructor(private http: HttpClient) { }
  getNotifications() {
    const id = localStorage.getItem('customerId');
    const params = new HttpParams()
      .set('userId', id ? id.toString() : '');
    return this.http.get<NotificationModel[]>(this.APIUrl + '/Notification/GetNotifications', { params: params });
  }

  updateNotification(id: number) {
    return this.http.post(`${this.APIUrl}/Notification/UpdateIsRead/${id}`, {});
  }
  // updateNotification(notificationId: number) {
  //   return this.http.post(this.APIUrl + '/Notification/UpdateIsRead', notificationId);
  // }
}
