import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {
  private profilePicture: string | null = null;
  //profilePictureUpdated: any;

  profilePictureUpdated = new EventEmitter<string | null>();

  formatDate(startDate: string | Date | null, endDate: string | Date | null): string | null {
    const isValidDate = (date: any) => date && !isNaN(Date.parse(date));

    if (isValidDate(startDate)) {
      const formatDate = (date: Date) => {
        const day = date.getDate().toString().padStart(2, '0');
        const month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
        const year = date.getFullYear().toString().slice(-2); // Get the last two digits of the year
        return `${day}-${month}-${year}`;
      };

      const startDateFormatted = formatDate(new Date(startDate as string));

      if (isValidDate(endDate)) {
        const endDateFormatted = formatDate(new Date(endDate as string));
        return `${startDateFormatted} - ${endDateFormatted}`;
      } else {
        return startDateFormatted;
      }
    }

    return null;
  }

  getProfilePicture(): string | null {
    return this.profilePicture;
  }

  setProfilePicture(image: string | null): void {
    this.profilePicture = image;
    this.profilePictureUpdated.emit(image);
  }

}
