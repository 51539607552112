export enum SaleServicePaths {
  //pipes
  //addPipe = '/Sales/InsertPipe',

  addPipe = '/Sales/AddPipe',
  updatePipe = '/Sales/UpdatePipe',
  getDealsStats = '/Sales/GetDealsStats/{companyId}',
  getPipes = '/Sales/GetPipes/{companyId}',
  getPipeAttributes = '/Sales/GetPipeAttributes/{companyId}',
  getDealsStatsForChart = '/Sales/GetDealsStatsForChart',
  getTopSalesRep = '/Sales/GetTopSalesRep',
  getDealsSummaryLast12Months = '/Sales/GetDealsSummaryLast12Months',

  
  addPipeAttribute = '/Sales/AddPipeAttribute',
  updatePipeAttribute = '/Sales/UpdatePipeAttribute',

  //deals
  addDeal = '/Sales/AddDeal',
  getDeals = '/Sales/GetDeals',
  //addDeal = '/Sales/InsertDeal',
  //getDeals = '/Sales/GetDeals',
  getDeal = '/Sales/GetDeal/{id}', 
  updateDeal = '/Sales/UpdateDeal',
  updateDealFromProfile = '/Sales/UpdateDealFromProfile',
  deleteDeal = '/Sales/DeleteDeal/{id}',
}
