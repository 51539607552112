<nav class="page-breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a routerLink="../dashboard">Dashboard</a></li>
    <li class="breadcrumb-item active" aria-current="page">Admin Panel</li>
  </ol>
</nav>
<ng-template #addCompanyModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="#addCompanyModalLabel">
      <i class="mdi mdi-account-plus"> </i> &nbsp;{{ _modalTitle }}
    </h5>
  </div>
  <div class="modal-body">
    <div class="row mb-3">
      <div class="col-md-12">
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-3">
        <label for="_companyUser.companyName" class="col-form-label">Name</label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control" [(ngModel)]="_companyUser.companyName"
          id="_companyUser.companyName" autocomplete="on" placeholder="Enter user name"
          maxlength="30" required pattern="[A-Za-z\s]{1,20}"
          title="Company name should only contain letters and spaces, and be between 1-20 characters long." />
        <div *ngIf="!_companyUser.companyName" class="error-message error" style="color: red;">*
          required</div>
      </div>

    </div>
    <div class="row mb-3">
      <div class="col-md-3">
        <label for="selectedRoleId" class="col-form-label">Role</label>
      </div>
      <div class="col-md-9">
        <ng-select [items]="roleTableData" bindLabel="roleName" bindValue="id" [searchable]="true"
          [(ngModel)]="selectedRoleId" placeholder="Select Role">
        </ng-select>
        <div *ngIf="!selectedRoleId || selectedRoleId === 0" class="error-message error"
          style="color: red;">
          * required
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-3">
        <label for="_companyUser.email" class="form-label">Email</label>
      </div>
      <div class="col-md-9">
        <input type="email" class="form-control" [(ngModel)]="_userEmail" id="_companyUser.email"
          autocomplete="on" maxlength="75" placeholder="Enter email"
          (blur)="checkEmailExists(_userEmail)" />
        <div *ngIf="!_userEmail || _userEmail.length === 0" class="error-message error"
          style="color: red;">
          * required
        </div>

        <div *ngIf="_emailExists" style="color: red;">
          already exists
        </div>
        <div *ngIf="!_emailExists && _userEmail && !(_userEmail.match(emailPattern))"
          style="color: red;">
          invalid format
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-3">
        <label for="_companyUser.phone" class="form-label">Phone</label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control" [(ngModel)]="_companyUser.phone"
          id="_companyUser.phone" autocomplete="on" maxlength="15" placeholder="Phone Number"
          (keypress)="validatePhoneNumber($event)" />
        <div *ngIf="!isValidLength()" style="color: red;">
          Only numeric digits are allowed.
        </div>
      </div>

    </div>

    <div class="row mb-3">
      <div class="col-md-3">
        <label for="_companyUser.city" class="form-label">City</label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control" [(ngModel)]="_companyUser.city"
          id="_companyUser.city" autocomplete="on" placeholder="Enter City" maxlength="50" />
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-3">
        <label for="_companyUser.country" class="form-label">Country</label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control" [(ngModel)]="_companyUser.country"
          id="_companyUser.country" autocomplete="on" placeholder="Enter Country" maxlength="60" />
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary btn-sm" (click)="saveCompanyUser()">
      <i class="mdi mdi-content-save"> </i> &nbsp; {{ _saveTitle }}
    </button>
    <button type="button" class="btn btn-danger btn-sm" (click)="closeModal()">
      <i class="mdi mdi-close"> </i>&nbsp;Close
    </button>
  </div>
</ng-template>

<ng-template #addRoleModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="#addRoleModalLabel">
      <i class="mdi mdi-account-plus"> </i> &nbsp;{{ _modalTitle }}
    </h5>
  </div>
  <div class="modal-body">
    <div class="row mb-3">
      <div class="col-md-12">
        <input type="hidden" value="_role.id" />
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-3">
        <label for="_role.roleName" class="col-form-label">Name</label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control" [(ngModel)]="_role.roleName" id="_role.roleName"
          maxlength="50" autocomplete="on" placeholder="Enter Role name" required
          pattern="[A-Za-z\s]{1,15}" title="Role name should only contain letters and spaces, and be
                   between 1-15 characters long." />
        <div *ngIf="!_role.roleName || (_role.roleName && _role.roleName.length === 0)"
          class="error-message error" style="color: red;">
          * required
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary btn-sm" (click)="saveRole()">
      <i class="mdi mdi-content-save"> </i> &nbsp; {{ _saveTitle }}
    </button>
    <button type="button" class="btn btn-danger btn-sm" (click)="modal.close('by: close button')">
      <i class="mdi mdi-close"> </i>&nbsp;Close
    </button>
  </div>
</ng-template>

<ul ngbNav #defaultNav="ngbNav" [(activeId)]="defaultNavActiveId" class="nav-tabs">
  <li [ngbNavItem]="1">
    <a ngbNavLink (click)="onChangeTab(1)" class="container">
      <lord-icon src="https://cdn.lordicon.com/uukerzzv.json" trigger="hover"
        colors="primary:#110a5c,secondary:#66a1ee" style="width: 23px; height: 23px">
      </lord-icon>
      <span class="text"> Users </span>
    </a>
    <ng-template ngbNavContent>
      <div class="row">
        <div class="col-md-3 col-sm-auto align-right">
          <input id="table-complete-search" type="text" class="form-control" name="searchTerm"
            placeholder="Search..." [(ngModel)]="searchTerm" (keyup)="performSearch()" />
        </div>
        <div class="col-md-4 col-md-auto"></div>
        <div class="col-md-5 col-md-auto">
          <button class="btn btn-xs btn-primary btn-icon-text float-end"
            (click)="openAddModal(addCompanyModal)" style="margin-left:3px;width: 130px;">
            <lord-icon src="https://cdn.lordicon.com/xzksbhzh.json" trigger="hover"
              colors="primary:#000000,secondary:#ebe6ef" style="width:20px;height:20px">
            </lord-icon>
            &nbsp; {{ _addBtnTitle }}
            <!--Add User-->
          </button>
        </div>
      </div>
      &nbsp;
      <div class="row" *ngIf="tableData.length === 0">
        <div class="col-md-12" style="align-self: auto">
          <div class="card">
            <div class="card-body text-center">
              <p>No Users, currently. Please Add a user.</p>
            </div>
          </div>
        </div>
      </div>
      &nbsp;

      <div class="row" *ngIf="tableData.length > 0">
        <div class="col-md-12 stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <ngx-datatable [rows]="tableData" class="bootstrap" [loadingIndicator]="true"
                  [columnMode]="ColumnMode.force" [footerHeight]="40" [limit]="10"
                  [pagination]="true" [pageSize]="10" [rowHeight]="40">
                  <ngx-datatable-column *ngFor="let column of tableColumns" [name]="column.header">
                    <ng-template ngx-datatable-cell-template let-row="row" let-value="value">

                      <ng-container *ngIf="column.header === 'Customer Name'">
                        <div class="d-flex align-items-center hover"
                          (mouseenter)="onRowMouseEnter(row)" (mouseleave)="onRowMouseLeave(row)">
                          {{ row.customerName | slice:0:15 }}
                          <button class="btn btn-secondary button-wrapper btn-icon-text
                            btn-small-transparent btn-smaller" *ngIf="row.isHovered"
                            (click)="previewClick(row)">
                            Preview
                          </button>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="column.header !== 'Customer Name'">
                        {{ value }}
                      </ng-container>
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column name="Send Credentials">
                    <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex"
                      let-value="value">
                      <button type="button" class="btn btn-xs send-btn datatable-action-cell"
                        (click)="sendCreds(row)">
                        Send
                      </button>
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column name="Action">
                    <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex"
                      let-value="value">
                      <button type="button" class="btn btn-xs datatable-action-cell"
                        [class.btn-success]="!row.isActive" [class.btn-danger]="row.isActive"
                        (click)="updateUserStatus(row, row.isActive)">
                        {{ !row.isActive ? "Activate" : "Deactivate" }}
                      </button>
                      &nbsp;
                      <button type="button" class="btn btn-xs btn-danger datatable-action-cell ml-2"
                        (click)="updateUserStatus(row, row.isActive, true)">
                        Delete
                      </button>
                    </ng-template>
                  </ngx-datatable-column>
                </ngx-datatable>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- </div>
      </div> -->
    </ng-template>
  </li>
  <li [ngbNavItem]="2">
    <a ngbNavLink (click)="onChangeTab(2)" class="container">
      <lord-icon src="https://cdn.lordicon.com/rqbhwzez.json" trigger="hover"
        colors="primary:#110a5c,secondary:#66a1ee" style="width:23px;height:23px">
      </lord-icon>
      <span class="text"> Roles </span>
    </a>
    <ng-template ngbNavContent>
      <div class="row">
        <div class="col-md-3 col-sm-auto align-right">
          <input id="table-complete-search" type="text" class="form-control" name="searchRole"
            placeholder="Search..." [(ngModel)]="searchRole" (keyup)="performRolesSearch()" />
        </div>
        <div class="col-md-9 col-sm-auto text-end">
          <button class="btn btn-xs btn-primary btn-icon-text" (click)="openAddRole(addRoleModal)"
            style="width: 130px;">
            <lord-icon src="https://cdn.lordicon.com/xzksbhzh.json" trigger="hover"
              colors="primary:#000000,secondary:#ebe6ef" style="width:20px;height:20px">
            </lord-icon>
            &nbsp; {{ _addBtnTitle }}
          </button>
        </div>
      </div>
      &nbsp;
      <div class="row" *ngIf="roleTableData.length === 0">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body text-center">
              <p>No Roles, currently. Please Add a role.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="roleTableData.length > 0">
        <div class="col-md-12 stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <ngx-datatable [rows]="roleTableData" class="bootstrap" [loadingIndicator]="true"
                  [columnMode]="ColumnMode.force" [footerHeight]="40" [limit]="10"
                  [pagination]="true" [pageSize]="10" [rowHeight]="40">
                  <ngx-datatable-column *ngFor="let column of roleTableColumns"
                    [name]="column.header">
                    <ng-template ngx-datatable-cell-template let-row="row" let-value="value">

                      <ng-container *ngIf="column.header === 'Role Name'">
                        <div class="d-flex align-items-center hover"
                          (mouseenter)="onRowMouseEnter(row)" (mouseleave)="onRowMouseLeave(row)">
                          {{ row.roleName | slice:0:15 }}
                          <button class="btn btn-secondary button-wrapper btn-icon-text
                            btn-small-transparent btn-smaller" *ngIf="row.isHovered"
                            (click)="rolePreviewClick(row.id)">
                            Preview
                          </button>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="column.header !== 'Role Name'">
                        {{ value }}
                      </ng-container>
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column name="Action">
                    <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex"
                      let-value="value">
                      <button type="button" class="btn btn-xs datatable-action-cell"
                        [class.btn-success]="!row.isActive" [class.btn-danger]="row.isActive"
                        (click)="updateRoleStatus(row, row.isActive)">
                        {{ !row.isActive ? "Activate" : "Deactivate" }}
                      </button>
                      &nbsp;
                      <button type="button" class="btn btn-xs btn-danger datatable-action-cell ml-2"
                        (click)="updateRoleStatus(row, row.isActive, true)">
                        Delete
                      </button>
                    </ng-template>
                  </ngx-datatable-column>
                </ngx-datatable>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- </div> -->
      <!-- </div>
      </div> -->
    </ng-template>
  </li>
  <li [ngbNavItem]="3">
    <a ngbNavLink class="container">
      <span class="text"> Role Permission </span>
    </a>
    <ng-template ngbNavContent>
      <app-role-permissions></app-role-permissions>
    </ng-template>
  </li>
</ul>

<div [ngbNavOutlet]="defaultNav" class="mt-2"></div>
<!--wihtout this line we can't see the nav-tabs-->
<!--<button type="button" class="btn btn-success">Success</button>
<button type="button" class="btn btn-danger">Danger</button>-->


<!-- not using these status attributes (they were dynamic attributes for statuses) -->

<!-- 
<ng-template #addPipeAttrbModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="#addPipeAttrbModalLabel">
      <i class="mdi mdi-account-plus"> </i> &nbsp;{{ _modalTitle }}
    </h5>
  </div>
  <div class="modal-body">
    <div class="row mb-3">
      <div class="col-md-12">
        <input type="hidden" value="_pipeAttrb.id" />
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-3">
        <label for="_pipeAttrb.name" class="col-form-label">Name</label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control" [(ngModel)]="_pipeAttrb.name" id="_pipeAttrb.name"
          autocomplete="on" placeholder="Enter Attribute name" maxlength="50" required
          pattern="[A-Za-z\s]{1,15}" title="Attribute name should only contain letters and spaces, and be
                   between 1-15 characters long." />
        <div *ngIf="!_pipeAttrb.name|| (_pipeAttrb.name && _pipeAttrb.name.length === 0)"
          class="error-message error" style="color: red;">
          * required
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-3">
        <label for="selectedTypeId" class="col-form-label">Type</label>
      </div>
      <div class="col-md-9">
        <ng-select [items]="types" bindLabel="value" bindValue="value" [searchable]="true"
          [(ngModel)]="selectedTypeId" placeholder="Select Data Type">
        </ng-select>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary btn-sm" (click)="savePipeAttribute()">
      <i class="mdi mdi-content-save"> </i> &nbsp; {{ _saveTitle }}
    </button>
    <button type="button" class="btn btn-danger btn-sm" (click)="modal.close('by: close button')">
      <i class="mdi mdi-close"> </i>&nbsp;Close
    </button>
  </div>
</ng-template> -->

<!-- 
<li [ngbNavItem]="4">
  <a ngbNavLink (click)="onChangeTab(4)" class="container">
    <lord-icon src="https://cdn.lordicon.com/xzksbhzh.json" trigger="hover"
      colors="primary:#000000,secondary:#ebe6ef" style="width:20px;height:20px">
    </lord-icon>
    <span class="text"> Lead Status Attributes </span>
  </a>
  <ng-template ngbNavContent>
    <div class="row">
      <div class="col-md-3 col-sm-auto align-right">
        <input id="table-complete-search" type="text" class="form-control" name="searchPipeAttrb"
          placeholder="Search..." [(ngModel)]="searchPipeAttrb"
          (keyup)="performPipeAttrbSearch()" />
      </div>
      <div class="col-md-9 col-sm-auto text-end">
        <button class="btn btn-xs btn-primary btn-icon-text"
          (click)="openAddPipeAttribute(addPipeAttrbModal)" style="margin-left:3px;width: 130px;">
          <lord-icon src="https://cdn.lordicon.com/xzksbhzh.json" trigger="hover"
            colors="primary:#000000,secondary:#ebe6ef" style="width:20px;height:20px">
          </lord-icon>
          &nbsp; {{ _addBtnTitle }}
        </button>
      </div>
    </div>
    &nbsp;
    <div class="row" *ngIf="pipeAttrbTableData.length === 0">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body text-center">
            <p>
              No Status Attributes, currently. Please add an attributes
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="pipeAttrbTableData.length > 0">
      <div class="col-md-12 stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <ngx-datatable [rows]="pipeAttrbTableData" class="bootstrap"
                [loadingIndicator]="true" [columnMode]="ColumnMode.force" [footerHeight]="40"
                [limit]="10" [pagination]="true" [pageSize]="10" [rowHeight]="40">
                <ngx-datatable-column *ngFor="let column of pipeAttrbTableColumns"
                  [name]="column.header">
                  <ng-template ngx-datatable-cell-template let-row="row" let-value="value">

                    <ng-container *ngIf="column.header === 'Attribute Name'">
                      <div class="d-flex align-items-center hover"
                        (mouseenter)="onRowMouseEnter(row)" (mouseleave)="onRowMouseLeave(row)">
                        {{ row.name | slice:0:15 }}
                        <button class="btn btn-secondary button-wrapper btn-icon-text
                          btn-small-transparent btn-smaller" *ngIf="row.isHovered"
                          (click)="pipeAttrbPreviewClick(row.id)">
                          Preview
                        </button>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="column.header !== 'Attribute Name'">
                      {{ value }}
                    </ng-container>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Action">
                  <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex"
                    let-value="value">
                    <button type="button" class="btn btn-xs datatable-action-cell"
                      [class.btn-success]="row.isDeleted" [class.btn-danger]="!row.isDeleted"
                      (click)="updatePipeAttrbStatus(row, row.isDeleted)">
                      {{ row.isDeleted ? "Activate" : "Deactivate" }}
                    </button>
                  </ng-template>
                </ngx-datatable-column>
              </ngx-datatable>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</li> -->