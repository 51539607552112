import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  public forecasts?: WeatherForecast[];

  constructor(http: HttpClient) {
    //http.get<WeatherForecast[]>('/weatherforecast').subscribe(result => {
    //  this.forecasts = result;
    //}, error => console.error(error));
    //localStorage.setItem('isLoggedin', 'true');
  }

  title = 'CRMFlo';

  showSuccessLogin(message: string) {
    const dynamicTitle = `${message}`;
    Swal.fire({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true,
      title: dynamicTitle,
      icon: 'success'
    });
  }

  showFailureLogin(message: string) {
    const dynamicTitle = `${message}`;
    Swal.fire({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true,
      title: dynamicTitle,
      icon: 'error'
    });
  }

  showSuccessSwal(operation: string, message: string) {
    const dynamicTitle = `${operation} ${message} successfully`;
    Swal.fire({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true,
      title: dynamicTitle,
      icon: 'success'
    });
  }

  showErrorSwal(operation: string, message: string) {
    const dynamicTitle = `${operation} ${message} failed`;
    Swal.fire({
      toast: true,
      position: 'top-end',
      icon: 'error',
      title: dynamicTitle,
      timerProgressBar: true,
      showConfirmButton: false,
      timer: 2000
    });
  }

  async showConfirmationDialog(title: string, text: string, confirmButtonText: string) {
    const result = await Swal.fire({
      title: title,
      text: text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#6571ff',
      cancelButtonColor: '#d33',
      confirmButtonText: confirmButtonText
    });

    return result.isConfirmed;
  }

  async showErrorDialog(title: string, text: string) {
    const result = await Swal.fire({
      title: title,
      text: text,
      icon: 'error',
      showCancelButton: true,
      showConfirmButton: false,
    });

    return result.isConfirmed;
  }


}

interface WeatherForecast {
  date: string;
  temperatureC: number;
  temperatureF: number;
  summary: string;
}
