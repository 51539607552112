import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { ContactService } from 'src/services/contact.service';
import { AuthService } from 'src/services/auth.service';
import { NgbCalendar, NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CustomerDTO, DealDetailsDTO, TasksModel } from '../profile.models';
@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.scss']
})
export class TaskComponent implements OnChanges {

  @Input() isDeal: any = false;
  @Input() customerId: any;
  @Input() type: any;
  // @Input() isDeal: any;
  @Output() taskAdded: EventEmitter<TasksModel> = new EventEmitter<TasksModel>();
  _tasksList: TasksModel[] = [];
  _saveTaskTitle: string = 'Create';
  _task = new TasksModel();
  _companyUsers: any[] = [];
  _taskUpdate: boolean = false;
  fromDate: NgbDate | null = null;
  toDate: NgbDate | null = null;
  _customerId: number = 0;
  _currentUserId: number = 0;
  _companyId: number = 0;
  selectedDate!: string;
  selectedTime!: string;
  _selectedUserId: number | null = 0;
  _selectedCustomerId: number | null = 0;
  constructor(
    private _appComponent: AppComponent,
    private _contactService: ContactService,
    private _authService: AuthService,
    private _modalService: NgbModal,
    private calendar: NgbCalendar,
    private _router: Router,) {

    // this._customerId = Number(localStorage.getItem('customerId'));
    this._companyId = Number(localStorage.getItem('companyId'));
    this._currentUserId = Number(localStorage.getItem('currentUserId'));
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
  }
  ngOnInit(): void {

    this.fetchData('Task');
    this.getCompanyUsers();

  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['customerId'] || changes['isDeal'] || changes['type']) {
      if (changes['customerId']) {
        this.customerId = changes['customerId'].currentValue;
      }
      if (changes['type']) {
        this.type = changes['type'].currentValue;
      }

      if (changes['isDeal']) {
        this.isDeal = changes['isDeal'].currentValue;
      }

      this.getCompanyUsers()
      // this.getLeadsForBoardById();
      this.fetchData('Task');

    }
  }


  // ngOnInit(): void {
  // }


  getCompanyUsers() {

    this._authService.getUsersOfCompany().subscribe(
      (data: any) => {
        // console.log("get contacts response", data);
        if (data && data.length > 0) {
          this._companyUsers = data;
        }
        // console.log("this._contacts", this._contacts);
      },
      error => {
        console.error("error getting contacts", error);
      }
    );
  }

  toggleTaskDone(task: TasksModel) {
    task.done = !task.done;

    this._task = task;

    const id = task.id;
    const status = !task.done;
    if (!this._task.done) {
      this._task.done = false;
    }
    else {
      this._task.done = true;
    }
    this._contactService.updateTaskStatus(id, status).subscribe(
      () => {
        task.done = status;
        // console.log('updating task status to done', task.done)
      },
      (error) => {
        console.error('Error updating task status:', error);
      }
    );

  }
  openTasksModal(content: TemplateRef<any>, id = 0) {
    // console.log("openTasksModal", id);
    this._task = new TasksModel();

    // console.log("task ", this._task);

    this.selectedDate = this.formatDate(new Date());
    this.selectedTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });

    this.openModal(content, "Tasks", id);
  }

  openModal(content: TemplateRef<any>, title: string, id: number) {

    // console.log("openModal", id);

    if (id === 0) {
      title = "Add " + title;
      this._taskUpdate = false;
      this._saveTaskTitle = "Create";
    } else {
      title = "Update " + title;
      this._taskUpdate = true;
      this._saveTaskTitle = "Update";
    }

    this._modalService.open(content, {}).result.then((result) => {
      // Handle the result if needed
    }).catch((res) => { });
  }
  isOverdue(task: TasksModel): boolean {
    const currentDateTime = new Date().getTime();
    const taskDueDateTime = new Date(task.dueDate).getTime();

    return currentDateTime > taskDueDateTime;
  }
  async deleteTask(id: any) {
    await this.confirmAndDelete("Task", id);
  }
  async confirmAndDelete(type: string, id: any) {
    const confirmation = confirm(`Are you sure you want to delete this ${type}?`);

    if (!confirmation) {
      return;
    }

    try {
      switch (type) {
        case 'Task':
          await this._contactService.deleteTask(id).toPromise();
          break;
        default:
          break;
      }
      this._appComponent.showSuccessSwal('Deleted', type);
      if (type === 'Task') {
        await this.fetchData('Task');
      }
    } catch (error: any) {
      console.error(`error deleting ${type}`);
      this._appComponent.showErrorSwal('Deleting', type);
    }
  }

  editTask(dataItem: any) {
    this._selectedCustomerId = null;
    this._selectedUserId = null;

    this._task.id = dataItem.id;
    this._task.title = dataItem.title;
    this._task.details = dataItem.details;
    this._task.activityDate = dataItem.activityDate;
    this._task.status = dataItem.status;
    this._task.assignedto = dataItem.assignedto;
    this._task.dueDate = this.formatTaskDate(dataItem.dueDate);
    this._task.done = dataItem.done;
    this._task.priority = dataItem.priority;
    this._task.queue = dataItem.queue;
    this._task.type = dataItem.type;

    if (dataItem.assignedto) {
      this._selectedUserId = dataItem.assignedto;
    }

    if (dataItem.customerId) {
      this._selectedCustomerId = dataItem.customerId;
    }

    // console.log("Task", dataItem);

  }
  formatTaskDate(dateString: any) {
    if (isNaN(Date.parse(dateString))) {
      return null;
    }
    return dateString.split('T')[0]; // Return only the date part 'yyyy-mm-dd'
  }
  saveTask() {
    if (this._task.title.trim().length === 0) {
      return;
    }

    this.saveData(this._task, 'Task');
  }
  saveData(entity: any, entityType: string) {
    const isAdding = entity.id === 0;

    entity.leadDetailId = 0;
    if (this.type == 'profile') {
      if (this._task.assignedto != null) {
        entity.customerId = this.customerId;
        entity.assignedto = this._selectedUserId;
      }
      else {
        entity.customerId = this.customerId;
        entity.assignedto = this._currentUserId;
      }
    }
    else {
      if (this._task.assignedto != null) {
        entity.assignedto = this._selectedUserId;
        entity.customerId = this._selectedCustomerId;
      }
      else {
        entity.assignedto = this._currentUserId;
        entity.customerId = null;
      }
    }
    if (this.isDeal) {
      entity.dealId = this.customerId
    }
    entity.createdBy = this._currentUserId;
    const activityDate = new Date(`${this.selectedDate}T${this.selectedTime}:00.000Z`);
    entity.activityDate = activityDate.toISOString();

    // console.log('saving task data', entity);

    let observable: Observable<any> | null = null;;

    if (entityType === 'Task') {
      observable = isAdding ? this._contactService.addTask(entity) :
        this._contactService.updateTask(entity);
    }
    //console.log("observable", observable);
    if (observable) {
      observable.subscribe(
        (response: any) => {
          //console.log("response", response);
          const entityName = entityType.slice(0, -5);

          if (response && response.status !== 200) {
            this._appComponent.showErrorSwal(isAdding ? 'saving' : 'updating', entityName);
            this.taskAdded.emit(entity); // Emit the newly saved task data
          } else {
            this.fetchData(entityType);
            this._modalService.dismissAll('by: calling closeModal()');
            this._appComponent.showSuccessSwal(isAdding ? 'saved' : 'updated', entityName);
          }
          // console.log('task data saviing', this._task.dueDate)
        },
        (error: any) => {
          console.error("error saving data", error);
          // Handle errors as needed
        }
      );
    }
  }
  async fetchData(type: string): Promise<void> {
    try {
      // console.log('fetching tasks');
      let data: any;
      switch (type) {
        case 'Task':
        case 'TaskModel':
          // console.log(`current cust id ${this._customerId} && isDeal${this.isDeal}`);
          if (this.type == 'profile') {
            data = await this._contactService.getTasks(this.customerId, this.isDeal).toPromise();
          }
          else {
            // console.log('current user id ', this._currentUserId);
            data = await this._contactService.getTasks(this._currentUserId, this.isDeal).toPromise();
          }
          console.log("tasks list", data);

          // this._tasksList = data;
          break;
      }
      if (data && data.length > 0) {
        this._tasksList = data;
        const listName = `_${type.toLowerCase()}sList`; // Derive the list name dynamically
        (this as any)[listName] = data;

        (this as any)[listName].sort((a: { activityDate: Date }, b: { activityDate: Date }) =>
          new Date(b.activityDate).getTime() - new Date(a.activityDate).getTime()
        );
        const mostRecentData = data.length === 1 ? data[0] : data[data.length - 1];

        if (mostRecentData) {
          mostRecentData.timeAgo = this.calculateActivityTime(mostRecentData.activityDate);
        }

        // Get company users
        let companyUsers = await this._authService.getUsersOfCompany().toPromise();

        // Add assignedToUser to each task
        for (let task of this._tasksList) {
          if (companyUsers && companyUsers.length > 0) {
            const assignedUser = companyUsers.filter(user => user.id === task.assignedto)[0];
            // console.log("single assigned user", assignedUser);

            if (assignedUser) {
              task.assignedToName = assignedUser.customerName;
            } else {
              task.assignedToName = null;
            }
          } else {
            task.assignedToName = null;
          }

        }

      }
      // console.log('fetching tasks lissts', this._tasksList);
    } catch (error) {
      console.error(`Error fetching customer ${type}s:`, error);
    }
  }

  calculateActivityTime(activityDate: string): string {
    const currentTime = new Date(); // Get the current time
    const activityTime = new Date(activityDate); // Convert the activityDate to a Date object
    const timeDiff = currentTime.getTime() - activityTime.getTime(); // Calculate the time difference in milliseconds

    // Convert the time difference to minutes ago, hours ago, or days ago
    if (timeDiff < 60000) { // Less than 1 minute
      return 'Just Now';
    }
    else if (timeDiff < 3600000) { // Less than 1 hour
      return Math.floor(timeDiff / 60000) + ' minutes ago';
    }
    else if (timeDiff < 86400000) { // Less than 1 day
      const hours = Math.floor(timeDiff / 3600000);
      return hours === 1 ? '1 hour ago' : hours + ' hours ago';
    }
    else { // More than 1 day
      const days = Math.floor(timeDiff / 86400000);
      return days === 1 ? '1 day ago' : days + ' days ago';
    }
  }

  todoTab(): boolean {
    return this._tasksList.length === 0 || this._tasksList.every(task => task.done);
  }
  doneTab(): boolean {
    return this._tasksList.length === 0 || this._tasksList.every(task => !task.done);
  }
  private formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = this.padZero(date.getMonth() + 1);
    const day = this.padZero(date.getDate());
    return `${year}-${month}-${day}`;
  }

  private formatTime(date: Date): string {
    let hours = date.getHours();
    const minutes = this.padZero(date.getMinutes());
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    const formattedTime = `${this.padZero(hours)}:${minutes}`;
    return `${formattedTime} ${ampm}`;
  }
  private padZero(value: number): string {
    return value.toString().padStart(2, '0');
  }
  onUserSelect(event: any) {

    // const selectedUserId = this._task.assignedto;
    const selectedUser = this._companyUsers.find(user => user.id === this._task.assignedto);
    if (selectedUser) {
      this._selectedUserId = selectedUser.id;
      this._selectedCustomerId = selectedUser.customerId;
    } else {
      this._selectedUserId = null;
      this._selectedCustomerId = null;
    }
  }
}
