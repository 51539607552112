<!-- <div class="forgot-password-container text-center">

  <div class="forms-container">
    <div class="signin-signup">
      <a routerLink="/" class="sidebar-brand nobleui-logo" style="font-size: 50px;">
        <img src="assets/images/movd/movd_logo.png" width="200" />
      </a>
      <form class="form-container">
        <h2>Forgot Password</h2>
        <p>Enter your email address to reset your password.</p>

        <div class="form-group">
          <label for="email">Email:</label>
          <input type="email" id="email" name="email" class="form-control" [(ngModel)]="email" required>
        </div>

        <button type="button" class="btn btn-primary mt-3" (click)="forgotpassword()">Send Email</button>
      </form>
    </div>
  </div>

</div> -->


<div class="loader-overlay" *ngIf="isLoading === true">
  <div id="loader">
    <div class="spinner"></div>
  </div>
</div>

<div class="login-container">

  <div class="forms-container">
    <div class="signin-signup">
      <form [formGroup]="forgotPasswordForm" class="sign-in-form"
        (ngSubmit)="forgotpassword($event)">
        <a routerLink="/" class="sidebar-brand nobleui-logo" style="font-size: 50px;">
          CRM<span>Flo</span>
        </a>
        <a routerLink="/" class="sidebar-brand nobleui-logo" style="font-size: 50px;">
        </a>
        <a class="sidebar-brand nobleui-logo mt-2" style="font-size: 20px;">
          <span>Forgot Password</span>
        </a>

        <div class="input-field mt-2">
          <i class="fas fa-envelope"></i>
          <input type="email" class="form-control" id="txtemail" formControlName="email"
            placeholder="Email" autocomplete="on">
        </div>
        <div
          *ngIf="forgotPasswordForm.get('email')?.invalid && (forgotPasswordForm.get('email')?.dirty || forgotPasswordForm.get('email')?.touched || submitted)">
          <div *ngIf="forgotPasswordForm.get('email')?.errors?.['required']" class="error-message">
            *Email is required!
          </div>
        </div>

        <br />
        <div>
          <input type="submit" value="Send Email" class="btn btn-primary no-capitalization" />
          <div *ngIf="errorMessage" class="error-message">{{ errorMessage }}</div>
        </div>
        <div class="mt-3">
          <a routerLink="/login" class="clickable-link">Back To Login</a>
        </div>
      </form>
    </div>
  </div>

  <div class="panels-container">
    <div class="panel left-panel">
      <div class="content">

        <h1>Techflo</h1>
        <p class="italic-content">
          Innovate, Collaborate, Evolve: Transforming Visions into Reality
        </p>

      </div>
    </div>

  </div>

</div>