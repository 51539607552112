import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AppComponent } from 'src/app/app.component';
import {
  CompanySetting,
  Currency,
  CurrencyOptions,
  VolumeUnit,
  VolumeUnitOptions,
  WeightUnit,
  WeightUnitOptions,
} from '../../models/companysetting.model';
import { AuthService } from 'src/services/auth.service';
import { PipeModel } from 'src/app/views/admin/super-admin/super-admin.models';
import { SaleService } from 'src/services/sale.service';
/*import { Lead } from '../../models/lead.model';*/

@Component({
  selector: 'app-company-setting',
  templateUrl: './company-setting.html',
  styleUrls: ['./company-setting.scss'],
})
export class CompanySettingComponent implements OnInit {
  @ViewChild('fileInput')
  fileInput!: ElementRef;
  defaultNavActiveId: number = 1;
  ColumnMode = ColumnMode;

  isLetterHeadUnderConstruction: boolean = true;
  // objects
  _companySetting = new CompanySetting();

  _weightUnit = WeightUnitOptions;
  _volumeUnit = VolumeUnitOptions;
  _currency = CurrencyOptions;
  _selectedWeightUnit!: WeightUnit | null;
  _selectedVolumeUnit!: VolumeUnit | null;
  _selectedCurrency: Currency = Currency.USD;
  _selectedColor = '#7987a1';

  selectedLogoFile: File | null = null;
  logoBase64String: string = '';

  selectedCurrency: string = 'PKR';
  currencyOptions: string[] = ['PKR', 'USD', 'EUR', 'GBP'];
  selectedMeasurementUnit: string = 'KG';
  measurementUnitOptions: string[] = ['KG', 'Foot', 'Inch', 'Centimeter'];

  selectedTheme: string = 'light';

  themeOptions = [
    { label: 'Light', value: 'light' },
    { label: 'Dark', value: 'dark' },
  ];

  //for validations
  areaExists: boolean = false;

  //pipes
  @ViewChild('ngx-datatable') table?: DatatableComponent;
  @ViewChild('addPipeModal') addPipeModal: any;
  _pipe = new PipeModel();
  searchPipe: string = '';
  pipeTableData: any[] = [];
  pipeTableColumns: any[] = [];

  _modalTitle = "Add Status";
  _saveTitle = "Create";
  _modalFunction: any;

  closeResult: string = '';

  _currentUserId: number = 0;
  _companyId: number = 0;

  constructor(
    private _modalService: NgbModal,
    private _appComponent: AppComponent,
    private _saleService: SaleService,
    private _authService: AuthService
  ) {
    this._currentUserId = Number(localStorage.getItem('currentUserId'));
    this._companyId = Number(localStorage.getItem('companyId'));
  }

  ngOnInit(): void {
    //this.refreshAreaTableData();
    //this.refreshAreaItemsTableData();
    this.getCompanySetting();
    this.refreshPipeTableData();
  }
  onChangeTab(_type: any) { }

  onCurrencyChange() {
    // console.log('Selected currency:', this.selectedCurrency);
  }
  onMeasurementUnitChange() {
    // console.log('Selected measurement unit:', this.selectedMeasurementUnit);
  }
  toggleTheme() {
    const body = document.getElementsByTagName('body')[0];

    if (this.selectedTheme === 'dark') {
      body.classList.add('dark-theme');
    } else {
      body.classList.remove('dark-theme');
    }
  }

  updateCompanySettings() {
    const companyId = Number(localStorage.getItem('companyId'));
    this._companySetting.ownedBy = companyId;

    if (!this._selectedWeightUnit) {
      this._selectedWeightUnit = WeightUnit.Kilograms;
    }

    if (!this._selectedVolumeUnit) {
      this._selectedVolumeUnit = VolumeUnit.CubicMeters;
    }

    this._companySetting.currency = this._selectedCurrency;
    this._companySetting.weightUnit = this._selectedWeightUnit;
    this._companySetting.volumeUnit = this._selectedVolumeUnit;
    this._companySetting.themeColor = this._selectedColor;
    this._companySetting.logo = this.logoBase64String;
    // console.log('company settings:', this._companySetting);

    this._authService
      .createOrUpdateCompanySettings(this._companySetting)
      .subscribe({
        next: (response: any) => {
          this._companySetting = response;
          // console.log('Settings updated successfully:', response);
          this._appComponent.showSuccessSwal('Updated', 'setting');
          this.getCompanySetting();
          this.clearLogoFile();
        },
        error: (error: any) => {
          console.error('Error updating settings:', error);
          this._appComponent.showErrorSwal('updating', 'setting');
        },
      });
  }

  getCompanySetting() {
    const companyId = Number(localStorage.getItem('companyId'));
    this._companySetting = new CompanySetting();
    this._companySetting.ownedBy = companyId;
    this._authService.getCompanySettings().subscribe({
      next: (response: CompanySetting) => {
        if (response) {
          this._companySetting = response;
          this._selectedWeightUnit = this._companySetting.weightUnit;
          this._selectedVolumeUnit = this._companySetting.volumeUnit;
          this._selectedCurrency = this._companySetting.currency;
          this._selectedColor = this._companySetting.themeColor;
        }
        // console.log('Company Setting', response);
      },
      error: (error: any) => {
        console.error('Error getting settings:', error);
        // this._appComponent.showErrorSwal('getting', 'company settings');
      },
    });
  }

  onLogoFileSelected(event: any) {
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const file: File = fileList[0];
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.logoBase64String = e.target.result;
      };
      reader.readAsDataURL(file);
    } else {
      this.logoBase64String = '';
    }
  }

  clearLogoFile() {
    // console.log('Clear button clicked');
    this.logoBase64String = '';
    if (this.fileInput && this.fileInput.nativeElement) {
      this.fileInput.nativeElement.value = '';
    }
  }

  //pipes - Sales lead Status
  openAddPipe(template: TemplateRef<any>) {
    this._modalTitle = "Add Status";
    this._saveTitle = "Create";
    this._modalFunction = "add";

    this._pipe = new PipeModel();

    this._modalService.open(template, { size: 'md' }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  openEditPipe() {
    this._modalTitle = "Lead Status";
    this._saveTitle = "Update";

    this._modalService.open(this.addPipeModal, { size: 'md' }).result.then(
      (result) => {
        //this.basicModalCloseResult = "Modal closed" + result

      }).catch((res) => { });
  }

  savePipe() {
    //console.log("save pipe", this._pipe);
    if (this._pipe.pipeName.trim().length === 0) {
      return;
    }

    if (this._pipe.id == 0) {
      this._pipe.createdBy = this._currentUserId;
      this._pipe.companyId = this._companyId;

      this._saleService.addPipe(this._pipe).subscribe(
        response => {
          this._modalService.dismissAll('by: calling closeModal()');
          this._appComponent.showSuccessSwal('saved', 'status');
          this.refreshPipeTableData();
          this.table?.recalculate();
          this.table?.bodyComponent.updateOffsetY();
        },
        error => {
          this._appComponent.showErrorSwal('saving', 'status');
        }
      );
    }
    else {

      this._saleService.updatePipe(this._pipe).subscribe(
        response => {
          this._modalService.dismissAll('by: calling closeModal()');
          this._appComponent.showSuccessSwal('updated', 'status');
          this.refreshPipeTableData();
          this.table?.recalculate();
          this.table?.bodyComponent.updateOffsetY();
        },
        error => {
          this._appComponent.showErrorSwal('updating', 'status');
        }
      )
    }
  }

  refreshPipeTableData() {
    this._saleService.getPipes(this._companyId).subscribe(
      (data: any) => {
        // console.log("company pipes data", data);
        if (data && data.length > 0) {
          this.pipeTableData = data;

          this.pipeTableColumns = Object.keys(data[0])
            .filter((key) =>
              key !== 'id' &&
              key !== 'isDeleted' &&
              key !== 'isActive')

            .map((key) => {
              let header: string;
              let isCheckbox: boolean = false;

              if (key == 'pipeName') {
                header = 'Title';
              } else {
                header = key;
              }

              return { header };
            });
        } else {
          this.pipeTableData = [];
        }

      },
      (error: any) => {
        console.log("error getting lead status data", error);
      });

  }

  async updatePipeStatus(row: any, status: any, softDelete = false) {
    const pipeId = Number(row.id);
    let action: string;

    if (row.isActive) {
      status = false;
      action = 'deactivated';
    } else {
      status = true;
      action = 'activated';
    }

    if (softDelete) {
      let confirmed = this._appComponent.showConfirmationDialog(
        'Are you sure?',
        'You are about to delete this status',
        'Yes, delete it!',
      );

      if (await confirmed === false) {
        return;
      } else {
        action = 'deleted';
      }
    }

    this._saleService.updatePipeStatus(pipeId, status, softDelete).subscribe(
      (response: any) => {
        console.log("deleted sales lead status successfully", response);

        this._appComponent.showSuccessSwal(action, 'sales status');
        this.refreshPipeTableData();
        // this.table?.recalculate();
        // //this.table?.renderRows();
        this.table?.bodyComponent.updateOffsetY();

      },
      (error: any) => {
        // Handle any error that occurred during the update process
        console.error('Error updating attribute status:', error);
        // this._appComponent.showErrorSwal('updating', 'sales lead');
      }
    );
  }

  pipePreviewClick(id: number) {
    //console.log("pipe id", id);
    this.getPipeAndOpenModal(Number(id));
  }

  getPipeAndOpenModal(id: number): void {
    this._pipe = new PipeModel();
    const data = this.pipeTableData.filter(item => item.id === id)[0];
    this._pipe = data;
    //console.log("_pipe", this._pipe);

    this.openEditPipe();
  }


  performPipesSearch() {
    // Check if the search term is empty
    if (this.searchPipe === '') {
      // Reset the tableData to the original data
      this.refreshPipeTableData();
    } else {
      // Filter the tableData array based on the search term
      const filteredData = this.pipeTableData.filter((row: any) => {
        // Convert all values of the row to string and check if any value contains the search term
        return Object.values(row).some((value: any) => {
          return String(value).toLowerCase().includes(this.searchPipe.toLowerCase());
        });
      });

      // Assign the filtered data to the ngx-datatable rows
      this.pipeTableData = filteredData;
    }

    // Reset the page to the first page
    if (this.table) {
      this.table.offset = 0;
    }

    // Recalculate and update the table
    this.table?.recalculate();
    this.table?.bodyComponent.updateOffsetY();

  }


  onRowMouseEnter(row: any) {
    row.isHovered = true;
  }

  onRowMouseLeave(row: any) {
    row.isHovered = false;
  }

  getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
