import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../environments/environment';
import { __makeTemplateObject } from 'tslib';
import { BehaviorSubject } from 'rxjs';
import { SaleServicePaths } from '../enums/sale-service-paths';
@Injectable({
  providedIn: 'root'
})
export class SaleService {
  readonly APIUrl = environment.baseUrl;
  private _overviewListSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public overviewList$: Observable<any[]> = this._overviewListSubject.asObservable();


  constructor(private http: HttpClient) { }
  addPipe(pipe: any) {
    return this.http.post(this.APIUrl + SaleServicePaths.addPipe, pipe);
  }
  getDealsStats(companyId: any): Observable<any[]> {
    const url = `${this.APIUrl}${SaleServicePaths.getDealsStats.replace('{companyId}', companyId)}`;
    return this.http.get<any[]>(url);
  }
  getDealsStatsForChart(): Observable<any[]> {
    const companyId = localStorage.getItem('role') === 'SuperAdmin' ? '0' : localStorage.getItem('companyId');
    return this.http.get<any[]>(this.APIUrl + SaleServicePaths.getDealsStatsForChart + '/' + companyId);
  }
  getDealsSummaryLast12Months(): Observable<any[]> {
    const companyId = localStorage.getItem('role') === 'SuperAdmin' ? '0' : localStorage.getItem('companyId');
    return this.http.get<any[]>(this.APIUrl + SaleServicePaths.getDealsSummaryLast12Months + '/' + companyId);
  }

  updatePipe(pipe: any) {
    return this.http.post(this.APIUrl + SaleServicePaths.updatePipe, pipe);
  }

  updatePipeStatus(pipeId: any, status: boolean, softDelete = false) {
    const url = `${this.APIUrl}/Sales/UpdatePipeStatus?id=${pipeId}&status=${status}&softDelete=${softDelete}`;
    return this.http.post(url, null);
  }


  getTopSalesRep() {
    const companyId = localStorage.getItem('role') === 'SuperAdmin' ? '0' : localStorage.getItem('companyId');
    const url = this.APIUrl + SaleServicePaths.getTopSalesRep + '/' + companyId;
    return this.http.get(url);
  }

  getTopSales() {
    const ownedBy = localStorage.getItem('role') === 'SuperAdmin' ? '0' : localStorage.getItem('companyId') || '0';
    const params = new HttpParams()
      .set('ownedBy', ownedBy);
    // console.log("reset password params", params);
    return this.http.get(`${this.APIUrl}/Sales/GetTopSales`, { params: params });
  }

  getPipes(id: any) {
    const url = `${this.APIUrl}${SaleServicePaths.getPipes.replace('{companyId}', id)}`;
    return this.http.get(url);
  }

  deletePipe(id: any) {
    return this.http.post(this.APIUrl + SaleServicePaths.deleteDeal.replace('{id}', id), {});
  }

  addPipeAttribute(pipeAttrb: any) {
    return this.http.post(this.APIUrl + SaleServicePaths.addPipeAttribute, pipeAttrb);
  }

  getPipeAttributes(id: any) {
    const url = `${this.APIUrl}${SaleServicePaths.getPipeAttributes.replace('{companyId}', id)}`;
    return this.http.get(url);
  }

  updatePipeAttribute(pipeAttrb: any) {
    return this.http.post(this.APIUrl + SaleServicePaths.updatePipeAttribute, pipeAttrb);
  }

  updatePipeAttributeStatus(pipeAttrbId: any, status: boolean) {
    const url = `${this.APIUrl}/Sales/UpdatePipeAttrbStatus?id=${pipeAttrbId}&status=${status}`;
    return this.http.post(url, null);
  }

  addDeal(deal: any) {
    return this.http.post(this.APIUrl + SaleServicePaths.addDeal, deal);
  }

  saveDeal(deal: any): Observable<any> {
    return this.http.post(this.APIUrl + SaleServicePaths.addDeal, deal);
  }

  updateDeal(updatedDeal: any) {
    return this.http.post(this.APIUrl + SaleServicePaths.updateDeal, updatedDeal);
  }

  updateDealFromProfile(updatedDeal: any) {
    return this.http.post(this.APIUrl + SaleServicePaths.updateDealFromProfile, updatedDeal);
  }

  getDeals() {
    const companyId = localStorage.getItem('role') === 'SuperAdmin' ? '0' : localStorage.getItem('companyId');
    const url = this.APIUrl + SaleServicePaths.getDeals + '/' + companyId;
    return this.http.get(url);
  }

  deleteDeal(id: any) {
    return this.http.post(this.APIUrl + SaleServicePaths.deleteDeal.replace('{id}', id), {});
  }

  getDeal(id: any) {
    const url = `${this.APIUrl}${SaleServicePaths.getDeal.replace('{id}', id)}`;
    return this.http.get(url);
  }
  //can't define type id as number as we can't assign number in replacing string
}
