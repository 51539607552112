<div class="loader-overlay" *ngIf="isLoading === true">
    <div id="loader">
        <div class="spinner"></div>
    </div>
</div>

<div class="login-container">

    <div class="forms-container">
        <div class="signin-signup mt-2">
            <form [formGroup]="resetPasswordForm" class="sign-in-form"
                (ngSubmit)="resetPassword($event)">
                <a routerLink="/" class="sidebar-brand nobleui-logo" style="font-size: 50px;">
                    CRM<span>Flo</span>
                </a>
                <a class="sidebar-brand nobleui-logo mt-2" style="font-size: 20px;">
                    <span>Reset Password</span>
                </a>

                <div class="input-field mt-2">
                    <i class="fas fa-lock"></i>
                    <input type="password" class="form-control" id="passwordInput"
                        formControlName="password" placeholder="Password">
                </div>
                <div class="text-center"
                    *ngIf="resetPasswordForm.get('password')?.invalid && (resetPasswordForm.get('password')?.dirty || resetPasswordForm.get('password')?.touched || submitted)">
                    <div *ngIf="resetPasswordForm.get('password')?.errors?.['required']"
                        class="error-message ">
                        *Password is required!</div>
                    <div *ngIf="resetPasswordForm.get('password')?.errors?.['passwordLength']"
                        class="error-message">
                        <p>*Password must be at least 8 characters long at least one special
                            character!</p>
                    </div>
                </div>

                <div class="input-field mt-2">
                    <i class="fas fa-lock"></i>
                    <input type="password" class="form-control" id="confirmPasswordInput"
                        formControlName="confirmPassword" placeholder="Confirm Password">
                </div>
                <div
                    *ngIf="resetPasswordForm.get('confirmPassword')?.invalid && (resetPasswordForm.get('confirmPassword')?.dirty || resetPasswordForm.get('confirmPassword')?.touched || submitted)">
                    <div *ngIf="resetPasswordForm.get('confirmPassword')?.errors?.['required']"
                        class="error-message">
                        *Enter your password again!
                    </div>
                </div>
                <br />
                <div>
                    <input type="submit" value="Reset" class="btn btn-primary no-capitalization" />
                    <div *ngIf="errorMessage" class="error-message">{{ errorMessage }}</div>
                </div>
                <div class="mt-3">
                    <a routerLink="/login" class="clickable-link">Back To Login</a>
                </div>
            </form>
        </div>
    </div>

    <div class="panels-container">
        <div class="panel left-panel">
            <div class="content">

                <h1>Techflo</h1>
                <p class="italic-content">
                    Innovate, Collaborate, Evolve: Transforming Visions into Reality
                </p>

            </div>
        </div>

    </div>

</div>