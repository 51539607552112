<nav class="sidebar">
  <div class="sidebar-header">

    <!--- Logo -->
    <a routerLink="/dashboard" class="sidebar-brand nobleui-logo">
      CRM<span>Flo</span>
    </a>

    <!-- <a routerLink="/" class="sidebar-brand nobleui-logo">
      <img src="assets/images/logo.png" alt="logo">
    </a> -->

    <!--- Toggler -->
    <div class="sidebar-toggler not-active" #sidebarToggler (click)="toggleSidebar($event)">
      <span></span>
      <span></span>
      <span></span>
    </div>

  </div>
  <div class="sidebar-body" (mouseenter)="operSidebarFolded()" (mouseleave)="closeSidebarFolded()"
    [perfectScrollbar]>

    <!--- Sidemenu start -->

    <ul class="sidebar-nav metismenu" id="sidebar-menu" #sidebarMenu>
      <ng-container *ngFor="let item of menuItems">

        <li class="nav-item nav-category" *ngIf="item.isTitle">{{ item.label }}</li>


        <li class="nav-item" *ngIf="!item.isTitle && item.show === true &&
        (item.label !== 'Super Admin' || (item.label === 'Super Admin' && role === 'SuperAdmin')) &&
        (item.label !== 'Company Admin' || (item.label === 'Company Admin' && role === 'CompanyAdmin')) &&
        (item.label !== 'Sales' && item.label !== 'Contacts' || role !== 'SuperAdmin')">
          <!-- Rest of the code -->

          <a class="nav-link" *ngIf="hasItems(item)" href="javascript:void(0);">
            <lord-icon src="https://cdn.lordicon.com/gqdnbnwt.json" trigger="hover"
              colors="primary:#3080e8,secondary:#6571ff" class="sidebar-icon">
            </lord-icon>
            <span class="link-title"> {{ item.label }}</span>
            <span class="link-arrow" data-feather="chevron-down" appFeatherIcon></span>
          </a>

          <!-- Contact and Dashboard -->

          <a class="nav-link nav-link-ref" [routerLink]="item.link" *ngIf="!hasItems(item)"
            [attr.data-parent]="item.parentId">
            <lord-icon [src]="getLordIconSrc(item.icon)"
              [trigger]="item.icon === 'tasks-icon' ? 'loop' : 'hover'" class="sidebar-icon"
              colors="primary:#3080e8,secondary:#6571ff"></lord-icon>
            <span class="link-title">{{ item.label }}</span>
            <span class="badge bg-{{ item.badge.variant }}" *ngIf="item.badge">{{ item.badge.text
              }}</span>
          </a>

          <ul class="sidebar-nav nav-second-level" *ngIf="hasItems(item)" aria-expanded="false"
            style="padding-top: 10px;">

            <li class="nav-item" *ngFor="let subitem of item.subItems"
              [ngClass]="{'side-nav-item': hasItems(subitem)}">
              <a class="nav-link side-nav-link-a-ref" *ngIf="hasItems(subitem)"
                href="javascript:void(0);">
                <lord-icon [src]="getLordIconSrc(subitem.icon)" trigger="hover" class="sidebar-icon"
                  colors="primary:#3080e8,secondary:#6571ff"></lord-icon>
                <!--<i class="link-icon" [attr.data-feather]="subitem.icon" appFeatherIcon *ngIf="subitem.icon"></i>-->
                <span class="link-title"> {{ subitem.label }}</span>
                <span class="link-arrow" data-feather="chevron-down" appFeatherIcon></span>
              </a>

              <a class="nav-link nav-link-ref" [routerLink]="subitem.link"
                *ngIf="!hasItems(subitem)" [attr.data-parent]="subitem.parentId">
                <!--<i class="link-icon" [attr.data-feather]="subitem.icon" appFeatherIcon *ngIf="subitem.icon"></i>-->
                <lord-icon [src]="getLordIconSrc(subitem.icon)" trigger="hover" class="sidebar-icon"
                  colors="primary:#3080e8,secondary:#6571ff"></lord-icon>
                {{ subitem.label }}
              </a>

              <ul class="sidebar-nav nav-third-level" *ngIf="hasItems(subitem)"
                aria-expanded="false">
                <li class="nav-item" *ngFor="let subSubitem of subitem.subItems">
                  <a class="nav-link nav-link-ref" [routerLink]="subSubitem.link"
                    [routerLinkActive]="['active']" [attr.data-parent]="subSubitem.parentId">
                    <lord-icon [src]="getLordIconSrc(subitem.icon)" trigger="hover"
                      class="sidebar-icon" colors="primary:#3080e8,secondary:#6571ff"></lord-icon>
                    <!--<i class="link-icon" [attr.data-feather]="subitem.icon" appFeatherIcon *ngIf="subitem.icon"></i>-->
                    {{ subSubitem.label }}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ng-container>
      <!--<li class="nav-item nav-category">Docs</li>
      <li class="nav-item">
        <a href="https://www.nobleui.com/angular/documentation/docs.html" target="_blank" class="nav-link">
          <i class="link-icon" data-feather="hash" appFeatherIcon></i>
          <span class="link-title">Documentation</span>
        </a>
      </li>-->
    </ul>

    <!--- Sidemenu end -->

  </div>
</nav>
<!--<nav class="settings-sidebar">
  <div class="sidebar-body">
    <a href="" class="settings-sidebar-toggler" (click)="toggleSettingsSidebar($event)">
      <i class="icon feather icon-settings"></i>
    </a>
    <h6 class="text-muted mb-2">Sidebar:</h6>
    <div class="mb-3 pb-3 border-bottom">
      <div class="form-check form-check-inline">
        <label class="form-check-label">
          <input type="radio" class="form-check-input" name="sidebarThemeSettings" (change)="onSidebarThemeChange($event)" id="sidebarLight" value="sidebar-light" checked>
          <i class="input-frame"></i>
          Light
        </label>
      </div>
      <div class="form-check form-check-inline">
        <label class="form-check-label">
          <input type="radio" class="form-check-input" name="sidebarThemeSettings" (change)="onSidebarThemeChange($event)" id="sidebarDark" value="sidebar-dark">
          <i class="input-frame"></i>
          Dark
        </label>
      </div>
    </div>
  </div>
</nav>-->