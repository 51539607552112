import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { NgbCalendar, NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../../../../services/auth.service';
import { ContactService } from '../../../../../services/contact.service';
import { AppComponent } from '../../../../app.component';

import { TasksModel } from '../profile.models';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-task-board',
  templateUrl: './task-board.component.html',
  styleUrls: ['./task-board.component.scss']
})
export class TaskBoardComponent implements OnInit {

  _tasksList: TasksModel[] = [];
  _filteredTasksList: TasksModel[] = [];

  filteredTasks: any[] = [];
  _customerId: number = 0;
  _currentUserId: number = 0;
  @Input() isDeal: any = false;
  loadingIndicator: boolean = true;
  fromDate: NgbDate | null = null;
  toDate: NgbDate | null = null;

  _task = new TasksModel();
  _selectedDate!: string;
  _selectedTime!: string;

  _saveTaskTitle: string = 'Create';
  _taskUpdate = false;
  _companyUsers: any[] = [];
  _tabType: string = 'to-do';
  _selectedUserId: number | null = 0;
  _selectedCustomerId: number | null = 0;


  constructor(
    private _appComponent: AppComponent,
    private _contactService: ContactService,
    private _modalService: NgbModal,
    private _authService: AuthService,
    private calendar: NgbCalendar,) {
    this._customerId = Number(localStorage.getItem('customerId'));
    this._currentUserId = Number(localStorage.getItem('currentUserId'));
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);


  }

  ngOnInit(): void {

    this.getCompanyUsers();
    this.fetchData('Task');
  }

  async fetchData(type: string): Promise<void> {
    try {
      // console.log('fetching tasks');
      let data: any;
      switch (type) {
        case 'Task':
        case 'TaskModel':
          // console.log(`current cust id ${this._customerId} && isDeal${this.isDeal}`);

          data = await this._contactService.getTasks(this._currentUserId, this.isDeal).toPromise();
          // console.log("tasks list", data);

          this._tasksList = data;
          // this._filteredTasksList = data;

          break;
      }
      if (data && data.length > 0) {
        console.log("tasks list", data);
        this._tasksList = data;
        if (this._tabType == 'to-do') {
          this._filteredTasksList = this._tasksList.filter(task => !(task.done));
        }
        else if (this._tabType == 'done') {
          this._filteredTasksList = this._tasksList.filter(task => (task.done));
        }

        const listName = `_${type.toLowerCase()}sList`; // Derive the list name dynamically
        (this as any)[listName] = data;

        (this as any)[listName].sort((a: { activityDate: Date }, b: { activityDate: Date }) =>
          new Date(b.activityDate).getTime() - new Date(a.activityDate).getTime()
        );
        const mostRecentData = data.length === 1 ? data[0] : data[data.length - 1];

        if (mostRecentData) {
          mostRecentData.timeAgo = this.calculateActivityTime(mostRecentData.activityDate);
        }

        // Get company users
        let companyUsers = await this._authService.getUsersOfCompany().toPromise();

        // Add assignedToUser to each task
        for (let task of this._tasksList) {

          if (companyUsers && companyUsers.length > 0) {
            const assignedUser = companyUsers.filter(user => user.id === task.assignedto)[0];
            // console.log("single assigned user", assignedUser);

            if (assignedUser) {
              task.assignedToName = assignedUser.customerName;
            } else {
              task.assignedToName = null;
            }
          } else {
            task.assignedToName = null;
          }
          // console.log("single assigned user", task.assignedToName);

        }

      }
      // console.log('fetching tasks lissts', this._tasksList);
    } catch (error) {
      console.error(`Error fetching customer ${type}s:`, error);
    }
  }

  calculateActivityTime(activityDate: string): string {
    const currentTime = new Date(); // Get the current time
    const activityTime = new Date(activityDate); // Convert the activityDate to a Date object
    const timeDiff = currentTime.getTime() - activityTime.getTime(); // Calculate the time difference in milliseconds

    // Convert the time difference to minutes ago, hours ago, or days ago
    if (timeDiff < 60000) { // Less than 1 minute
      return 'Just Now';
    }
    else if (timeDiff < 3600000) { // Less than 1 hour
      return Math.floor(timeDiff / 60000) + ' minutes ago';
    }
    else if (timeDiff < 86400000) { // Less than 1 day
      const hours = Math.floor(timeDiff / 3600000);
      return hours === 1 ? '1 hour ago' : hours + ' hours ago';
    }
    else { // More than 1 day
      const days = Math.floor(timeDiff / 86400000);
      return days === 1 ? '1 day ago' : days + ' days ago';
    }
  }

  onTabChange(type: string) {
    this.fetchData('Task');

    if (type === 'to-do') {
      this._tabType = 'to-do';
      this._filteredTasksList = this._tasksList.filter(task => !(task.done));
    } else if (type === 'done') {
      this._tabType = 'done';
      this._filteredTasksList = this._tasksList.filter(task => task.done);
    }
  }

  // filterTasks(): void {
  //   // Filter tasks where done is true
  //   this.filteredTasks = this._tasksList.filter(task => task.done);
  // }

  isOverdue(task: TasksModel): boolean {
    const currentDateTime = new Date().getTime();
    const taskDueDateTime = new Date(task.dueDate).getTime();

    return currentDateTime > taskDueDateTime;
  }

  openTasksModal(content: TemplateRef<any>, id = 0, task: any | null) {
    // console.log("openTasksModal", id);
    this._task = new TasksModel();

    // console.log("task ", this._task);
    if (task) {
      this._selectedCustomerId = null;
      this._selectedUserId = null;
      this._task = task;
      this._task.dueDate = this.formatTaskDate(task.dueDate);
      if (task.assignedto) {
        this._selectedUserId = task.assignedto;
      }

      if (task.customerId) {
        this._selectedCustomerId = task.customerId;
      }
    }

    this._selectedDate = this.formatDate(new Date());
    this._selectedTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });

    this.openModal(content, "Tasks", id);

  }
  formatTaskDate(dateString: any) {
    if (isNaN(Date.parse(dateString))) {
      return null;
    }
    return dateString.split('T')[0]; // Return only the date part 'yyyy-mm-dd'
  }
  openModal(content: TemplateRef<any>, title: string, id: number) {

    // console.log("openModal", id);

    if (id === 0) {
      title = "Add " + title;
      this._taskUpdate = false;
      this._saveTaskTitle = "Create";
    } else {
      title = "Update " + title;
      this._taskUpdate = true;
      this._saveTaskTitle = "Update";
    }

    this._modalService.open(content, {}).result.then((result) => {
      // Handle the result if needed
    }).catch((res) => { });
  }


  saveTask() {
    if (this._task.title.trim().length === 0) {
      return;
    }

    this.saveData(this._task, 'Task');
  }
  saveData(entity: any, entityType: string) {
    const isAdding = entity.id === 0;

    entity.leadDetailId = 0;
    if (this._task.assignedto != null) {
      entity.assignedto = this._selectedUserId;
      entity.customerId = this._selectedCustomerId;
    }
    else {
      entity.assignedto = this._currentUserId;
      entity.customerId = null;
    }


    entity.createdBy = this._currentUserId;
    const activityDate = new Date(`${this._selectedDate}T${this._selectedTime}:00.000Z`);
    entity.activityDate = activityDate.toISOString();

    // console.log('saving task data', entity);

    let observable: Observable<any> | null = null;;

    if (entityType === 'Task') {
      observable = isAdding ? this._contactService.addTask(entity) :
        this._contactService.updateTask(entity);
    }
    //console.log("observable", observable);
    if (observable) {
      observable.subscribe(
        (response: any) => {
          //console.log("response", response);
          const entityName = entityType.slice(0, -5);

          if (response && response.status !== 200) {
            this._appComponent.showErrorSwal(isAdding ? 'saving' : 'updating', entityName);

          } else {
            this.fetchData(entityType);
            this._modalService.dismissAll('by: calling closeModal()');
            this._appComponent.showSuccessSwal(isAdding ? 'saved' : 'updated', entityName);
          }
          // console.log('task data saviing', this._task.dueDate)
        },
        (error: any) => {
          console.error("error saving data", error);
          // Handle errors as needed
        }
      );
    }
  }

  toggleTaskDone(task: TasksModel) {
    task.done = !task.done;

    this._task = task;

    const id = task.id;
    const status = !task.done;
    if (!this._task.done) {
      this._task.done = false;
    }
    else {
      this._task.done = true;
    }
    this._contactService.updateTaskStatus(id, status).subscribe(
      () => {
        task.done = status;
        // console.log('updating task status to done', task.done)
      },
      (error) => {
        console.error('Error updating task status:', error);
      }
    );

  }

  getCompanyUsers() {

    this._authService.getUsersOfCompany().subscribe(
      (data: any) => {
        // console.log("get contacts response", data);
        if (data && data.length > 0) {
          this._companyUsers = data;
        }
        // console.log("this._contacts", this._contacts);
      },
      error => {
        console.error("error getting contacts", error);
      }
    );
  }

  private formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = this.padZero(date.getMonth() + 1);
    const day = this.padZero(date.getDate());
    return `${year}-${month}-${day}`;
  }

  private padZero(value: number): string {
    return value.toString().padStart(2, '0');
  }

  // Inside your component
  onRowMouseEnter(row: any) {
    row.isHovered = true;
  }

  onRowMouseLeave(row: any) {
    row.isHovered = false;
  }
  onUserSelect(event: any) {
    const selectedUserId = this._task.assignedto;
    const selectedUser = this._companyUsers.find(user => user.id === selectedUserId);
    if (selectedUser) {
      this._selectedUserId = selectedUser.id;
      this._selectedCustomerId = selectedUser.customerId;
    } else {
      this._selectedUserId = null;
      this._selectedCustomerId = null;
    }
    // console.log('selected user id ', this._selectedUserId);
    // console.log('selected customer id ', this._selectedCustomerId);
  }
}
