import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserDTO, UserModel } from '../auth.models';
import { AuthService } from '../../../../services/auth.service';
import { AppComponent } from '../../../app.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  isLoading: boolean = false;

  loginForm: FormGroup = new FormGroup({});
  submitted = false;
  staticEmail: string = 'admin@techflo.com';
  staticPass: string = 'admin123';
  errorMessage: string | null = null;

  returnUrl: any;

  _user = new UserModel();
  _userDTO = new UserDTO();
  constructor(private router: Router, private route: ActivatedRoute,
    private authService: AuthService, private _appComponent: AppComponent) { }

  ngOnInit(): void {

    Object.keys(localStorage).forEach(key => {
      if (key !== 'email') {
        localStorage.removeItem(key);
      }
    });

    this.loginForm = new FormGroup({
      email: new FormControl(localStorage.getItem('email') || '', [Validators.required, Validators.email]),
      password: new FormControl('', Validators.required),
      rememberMe: new FormControl(false) // Define the 'rememberMe' form control
    });
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

    const rememberMe = localStorage.getItem('rememberMe');
    if (rememberMe) {
      this.loginForm.patchValue({ rememberMe: true });
      const email = localStorage.getItem('email');
      if (email) {
        this.loginForm.patchValue({ email });
      }
    }

  }

  login() {
  }

  onLoggedin(e: Event) {
    // console.log("isloading", this.isLoading);

    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }

    this.isLoading = true;

    e.preventDefault();
    const emailInput = document.getElementById('txtemail') as HTMLInputElement;
    const passInput = document.getElementById('txtpass') as HTMLInputElement;

    this._user.email = emailInput.value;
    this._user.password = passInput.value;

    setTimeout(() => {
      this.authService.login(this._user).subscribe({
        next: (result) => {
          // console.log("login ", result);
          if (result) {
            this._userDTO = result.user;
            localStorage.setItem('token', result.token);
            localStorage.setItem('currentUserId', this._userDTO.id.toString());
            localStorage.setItem('name', this._userDTO.username);
            localStorage.setItem('email', this._userDTO.email);
            localStorage.setItem('role', this._userDTO.role);
            localStorage.setItem('roleId', this._userDTO.roleId.toString());
            localStorage.setItem('companyId', this._userDTO.companyId);
            localStorage.setItem('customerId', this._userDTO.customerId.toString());
            localStorage.setItem('isLoggedin', 'true');

          }
          const rememberMeControl = this.loginForm.get('rememberMe');
          if (rememberMeControl && rememberMeControl.value) {
            localStorage.setItem('rememberMe', 'true');
            localStorage.setItem('email', this._userDTO.email);
            localStorage.setItem('password', this._user.password);
          } else {
            localStorage.removeItem('rememberMe');
            localStorage.removeItem('email');
            localStorage.removeItem('password');
          }

          if (Boolean(localStorage.getItem('isLoggedin'))) {
            this.isLoading = false; // Set isLoading to false when the request is complete
            // setTimeout(() => {
            //   this.isLoading = false;
            // }, 10000);
            // console.log("isloading", this.isLoading);
            this.router.navigate(['/dashboard']);
          }
        },
        error: (error: any) => {
          // Handle login error
          this.isLoading = false; // Set isLoading to false when the request is complete
          if (error.status === 401) {
            this.errorMessage = 'Unauthorized access. Please try again.';
            this._appComponent.showFailureLogin(this.errorMessage);
          } else if (error.status === 400 && error.error === 'The user is deleted.') {
            this.errorMessage = 'The account has been deleted. Please contact support.';
            this._appComponent.showFailureLogin(this.errorMessage);
          } else if (error.status === 400 && error.error === 'The user is inactive.') {
            this.errorMessage = 'The account is inactive. Please contact support.';
            this._appComponent.showFailureLogin(this.errorMessage);
          } else if (error.status === 400 && error.error === 'The user role is inactive.') {
            this.errorMessage = 'The account role is inactive. Please contact support.';
            this._appComponent.showFailureLogin(this.errorMessage);
          } else {
            this.errorMessage = 'Invalid email or password';
            this._appComponent.showFailureLogin(this.errorMessage);
          }
        },
      });
    }, 0); // Adjust the delay duration (in milliseconds) as needed

  }
}
