<div class="container">
  <div class="forms-container">
    <div class="signin-signup">
      
      <form action="#" class="sign-up-form">
        <a routerLink="/" class="sidebar-brand nobleui-logo" style="font-size: 50px;">
          Tech<span>flo</span>
        </a>
        <a class="sidebar-brand nobleui-logo" style="font-size: 20px;">
          Sign<span>up</span>
        </a>
        <div class="input-field">
          <i class="fas fa-user"></i>
          <input type="text" class="form-control" id="username" formControlName="username" placeholder="Username" autocomplete="off">
        </div>
        <div *ngIf="loginForm.get('username')?.invalid && (loginForm.get('username')?.dirty || loginForm.get('username')?.touched || submitted)">
          <div *ngIf="loginForm.get('username')?.errors?.['required']" class="error-message">Username is required</div>
        </div>

        <div class="input-field">
          <i class="fas fa-user"></i>
          <input type="email" class="form-control" id="txtemail" formControlName="email" placeholder="Email" autocomplete="off">
        </div>
        <div *ngIf="loginForm.get('email')?.invalid && (loginForm.get('email')?.dirty || loginForm.get('email')?.touched || submitted)">
          <div *ngIf="loginForm.get('email')?.errors?.['required']" class="error-message">Email is required</div>
        </div>

        <div class="input-field">
          <i class="fas fa-lock"></i>
          <input type="password" class="form-control" id="txtpass" formControlName="password" placeholder="Password">
        </div>
        <div *ngIf="loginForm.get('password')?.invalid && (loginForm.get('password')?.dirty || loginForm.get('password')?.touched || submitted)">
          <div *ngIf="loginForm.get('password')?.errors?.['required']" class="error-message">Password is required</div>
        </div>
        <input type="submit" class="btn" value="Sign up" (click)="onRegister($event)" />
        <!-- <p class="social-text">Or Sign up with social platforms</p> -->
        <!-- Social Media Icons -->
        <!-- <div class="social-media">
    <a href="#" class="social-icon">
      <i class="fab fa-facebook-f"></i>
    </a>
    <a href="#" class="social-icon">
      <i class="fab fa-twitter"></i>
    </a>
    <a href="#" class="social-icon">
      <i class="fab fa-google"></i>
    </a>
    <a href="#" class="social-icon">
      <i class="fab fa-linkedin-in"></i>
    </a>
  </div> -->
      </form>
    </div>
  </div>


  <div class="panels-container">
    <div class="panel left-panel">
      <div class="content">
        <h3>One of Us ?</h3>
        <p>
          Using a thorough approach to understand the requirements of our partners, we build tailored solutions from the ground up.
        </p>
  <a (click)="redirectToLogin()" class="btn transparent" id="sign-in-btn">
    <span class="button-text">Sign In</span>
  </a>
      </div>
    </div>

  </div>

  </div>

